<template>
  <div class="tip">
    <div class="tip-box">
      <span>
        <i class="iconfont">&#xe633;</i>
        <!-- <img class="tip_img" src="@/assets/images/info.svg" alt="" /> -->
      </span>
      <span class="tip_lab" v-show="isShow">{{ label }}：</span>
      <!-- 只有一条的 -->
      <div class="tip_text1">
        <slot name="first"></slot>
      </div>
      <!-- 有多条的
      例：1.xxxx
          2.xxxx
     -->
      <div class="tip_text2">
        <slot name="second"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: '温馨提示',
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
// 提示
.tip {
  width: 100%;
  // background-color: #e6f1ff;
  background: rgba(230, 241, 255, 0.8);
  border-radius: 0px 0px 0px 0px;
  // opacity: 0.8;
  .tip-box {
    display: flex;
    padding: 10px 20px;

    .iconfont {
      color: #026bff;
      font-size: 16px;
    }
  }
  .tip_lab {
    line-height: 22px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #4d4d4d;
    margin-left: 10px;
  }

  .tip_text1 {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #4d4d4d;
    // margin-left: 3px;
    line-height: 22px;
  }
  .tip_text2 {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #4d4d4d;
    margin-left: 3px;
    line-height: 20px;
  }
  .tip_img {
    margin-top: 2px;
  }
}
</style>
