<template>
  <!-- 类目 -->
  <div>
    <el-cascader
      ref="xmRadioCascade"
      class="cascader_box"
      popper-class="xm-radio-cascade"
      clearable
      :key="keyindex"
      :placeholder="placeholderProps"
      :separator="separator"
      :props="categoryOptions"
      :options="categoryList"
      v-model="cascaderValue"
      @change="changeFunc($event)"
      @visible-change="valchang"
    >
    </el-cascader>
  </div>
</template>

<script>
import { getAllCategoryInfo } from '@/utils/api/goods/goodsApi.js';
export default {
  name: 'SelectCategory',
  props: {
    value: {},
    checkStrictly: {
      type: Boolean,
      default: true,
    },
    cascaderFlag: '',
    placeholderProps: {
      type: String,
      default: '全部类目',
    },
    // 选项分隔符
    separator: {
      type: String,
      default: '/',
    },
    // 是否需要'全部类目'文字展示
    hasAllFont: {
      type: Boolean,
      default: false,
    },
    // 是否选择3级
    isLevel3: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // categoryList: this.$store.state.categoryData,
      keyindex: 0,
    };
  },
  // created() {
  //   this.getAllCategoryInfo();
  // },
  computed: {
    // 类目列表
    categoryList() {
      let cateArr = this.$store.state.categoryData;
      let allFont = [
        {
          name: '全部类目',
          supplier_class_id: -1,
        },
      ];
      if (this.hasAllFont) {
        return allFont.concat(cateArr);
      } else return cateArr;
    },
    cascaderValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    categoryOptions() {
      return {
        label: 'name',
        value: 'supplier_class_id',
        children: '_child',
        checkStrictly: this.checkStrictly,
      };
    },
  },
  methods: {
    //获取类目信息
    // async getAllCategoryInfo() {
    //   try {
    //     let res = await getAllCategoryInfo();
    //     if (res.code == 200) {
    //       this.categoryList = res.data;
    //       this.$store.dispatch('setCategoryInfo', res.data);
    //       return;
    //     }
    //     // else {
    //     //   this.$message({ type: 'warning', message: res.msg });
    //     // }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    valchang(val) {
      if (!val && !this.categoryList.length) {
        this.keyindex++;
      }
    },
    changeFunc(data) {
      if (!data.length) {
        this.keyindex++;
      }
      if (!this.isLevel3 || (this.isLevel3 && data?.length == 3)) {
        this.$refs.xmRadioCascade.dropDownVisible = false;
      }
      this.$emit('change', data, this.cascaderFlag);
    },
  },
};
</script>

<style lang="scss" scoped>
.cascader_box {
  >>> .el-input__inner {
    width: 255px;
  }
  >>> .el-cascader-node.in-active-path {
    color: #ff7802 !important;
  }
}
</style>
<style>
.xm-radio-cascade .in-active-path {
  background-color: #e6f1ff !important;
}
.xm-radio-cascade .is-active {
  background-color: #e6f1ff !important;
}
.xm-radio-cascade .el-cascader-node.in-active-path,
.xm-radio-cascade .el-cascader-node.is-selectable.in-checked-path,
.xm-radio-cascade .el-cascader-node.is-active {
  font-weight: normal;
}
.xm-radio-cascade .el-radio__inner {
  top: -18px;
  left: -19px;
  border-radius: 0;
  border: 0;
  width: 170px;
  height: 34px;
  background-color: transparent;
  cursor: pointer;
  box-sizing: border-box;
  position: absolute;
}
.xm-radio-cascade .el-radio__input.is-checked .el-radio__inner {
  background: transparent;
}
.xm-radio-cascade .el-radio__input.is-checked .el-radio__inner::after {
  background-color: transparent;
}
.xm-radio-cascade .el-cascader-node:hover {
  color: #026bff;
  background-color: #e6f1ff;
}
.xm-radio-cascade .popper__arrow {
  display: none;
}
.xm-radio-cascade .el-cascader-menu__wrap {
  height: 235px;
}
.xm-radio-cascade {
  height: 220px;
  overflow: hidden;
}
</style>
