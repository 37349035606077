<template>
  <div>
    <el-cascader
      :options="categoryList"
      :props="props"
      collapse-tags
      clearable
      v-model="selectedData"
      @change="inputChange($event)"
      :style="{
        width: catewidth + 'px',
      }"
    ></el-cascader>
  </div>
</template>

<script>
export default {
  props: {
    // 回显的类目数据
    value: {
      type: Array,
      default: () => [],
    },
    catewidth: {
      type: Number,
      default: 361,
    },
  },
  data() {
    return {
      categoryList: [],
      props: {
        multiple: true,
        label: 'name',
        value: 'supplier_class_id',
        children: '_child',
      },
      selectedData: this.value, // 被选中的数据
    };
  },
  watch: {
    value: {
      handler: function (newVal, oldVal) {
        this.selectedData = newVal;
      },
    },
  },
  mounted() {
    this.getAllCategoryInfo();
  },
  methods: {
    getAllCategoryInfo() {
      this.categoryList = this.$store.state.categoryData;
    },
    inputChange(val) {
      this.$emit('input', val);
    },
  },
};
</script>
