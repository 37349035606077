<template>
  <div class="xm-easy-multiple-select">
    <el-select
      clearable
      multiple
      collapse-tags
      v-model="selectedArray"
      :placeholder="placeholder"
      :popper-append-to-body="false"
      @change="handleSelect"
      @clear="handleClear"
      @visible-change="hiddenChange"
      :style="{ width: width }"
    >
      <el-checkbox
        class="all-checked"
        :indeterminate="isIndeterminate"
        v-model="checkAll"
        @change="handleCheckAllChange"
        >{{ checkAllText }}</el-checkbox
      >
      <el-checkbox-group v-model="selectedArray">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
          <el-checkbox
            style="pointer-events: none"
            :label="item.value"
            class="multiple-item"
            >{{ item.label }}
          </el-checkbox>
        </el-option>
      </el-checkbox-group>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'xmEasyMultipleSelect',
  props: {
    placeholder: {
      type: String,
      default: '请选择',
    },
    options: {
      type: Array,
      default: () => [],
    },
    width: {
      type: String,
      default: '176px',
    },
    checkAllText: {
      type: String,
      default: '全选',
    },
    // 回显筛选数据
    echoSearch: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedArray: [], // 选择的数据
      selectedArraryCopy: [], // 选择的数据 复制
      isIndeterminate: false, // 半选
      checkAll: false, // 全选
      showDropdown: false,
    };
  },
  watch: {
    echoSearch(newVal) {
      if (newVal) {
        this.selectedArray = newVal.split(',');
        this.selectedArraryCopy = this.selectedArray;
      }
    },
  },

  mounted() {},
  methods: {
    handleSelect(value) {
      const checkedCount = value?.length;
      this.checkAll = checkedCount === this.options?.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.options?.length;

      // 在input框点击清空的操作时，也将数据传出
      if (!this.showDropdown) {
        let selectedData = JSON.parse(JSON.stringify(this.selectedArray));
        this.$emit('change', selectedData);
      }
    },
    handleClear() {
      // clear事件会触发change事件，暂时不需要单独区分clear事件后续有需要可以加
      // this.$emit('clear', this.selectedArray);
    },
    handleCheckAllChange(val) {
      const data = this.options?.map((item) => {
        return item.value;
      });
      this.selectedArray = val ? data : [];
      this.isIndeterminate = false;
    },
    // 下拉框显示/隐藏 时触发
    hiddenChange(status) {
      this.showDropdown = status;
      // 下拉框收起时触发
      if (!status) {
        let isEqual = this.isValueEqual(
          this.selectedArraryCopy,
          this.selectedArray
        );
        if (!isEqual) {
          this.selectedArraryCopy = JSON.parse(
            JSON.stringify(this.selectedArray)
          );
          let selectedData = JSON.parse(JSON.stringify(this.selectedArray));

          this.$emit('change', selectedData);
        }
      }
    },
    // 清空已选
    clearSelectData() {
      this.selectedArray = []; // 选择的数据
      this.selectedArraryCopy = []; // 选择的数据 复制
      this.isIndeterminate = false; // 半选
      this.checkAll = false; // 全选
      this.showDropdown = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.xm-easy-multiple-select {
  display: inline-block;
  .el-select
    ::v-deep
    .el-select-dropdown.is-multiple
    .el-select-dropdown__item.selected::after {
    display: none;
  }

  .all-checked {
    margin-left: 16px;
    padding: 7px 0;

    ::v-deep .el-checkbox__inner {
      border-radius: 0;
    }
  }

  ::v-deep .el-select-dropdown__item {
    padding-left: 16px;
    .el-checkbox__label {
      padding-left: 4px;
    }

    .el-checkbox__inner {
      border-radius: 0;
    }
  }
  // ::v-deep
  // ::v-deep
}
</style>
