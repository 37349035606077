<template>
  <div class="expory-button-box">
    <el-dropdown trigger="click" placement="bottom-start">
      <span class="dropdown-max-title">
        导出<i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu
        slot="dropdown"
        class="dropdown-menu"
        :class="customStyleName ? customStyleName : ''"
      >
        <el-dropdown-item @click.native="handleExportPart"
          >导出选中信息</el-dropdown-item
        >
        <el-dropdown-item @click.native="handleExportAll"
          >导出全部信息</el-dropdown-item
        >
        <slot></slot>
      </el-dropdown-menu>
    </el-dropdown>
    <!-- <div class="export-btn">
      <span>导出</span><img src="@/assets/images/arrowdownline.png" alt="" />
    </div>
    <div class="export-options">
      <div class="option" @click="handleExportPart">导出选中信息</div>
      <div class="option" @click="handleExportAll">导出选中信息</div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'XmExportButton',
  props: {
    customStyleName: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleExportPart() {
      // console.log('部分导出');
      this.$emit('export-part');
    },

    handleExportAll() {
      // console.log('全部导出');
      this.$emit('export-all');
    },
  },
};
</script>

<style lang="scss" scoped>
.expory-button-box {
  .dropdown-max-title {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #808080;
    border: 1px solid #cccccc;
    padding: 9px 16px;
    margin-left: 20px;
    cursor: pointer;
    display: inline-block;
    height: 40px;

    .el-icon--right {
      font-size: 14px;
      line-height: 23px;
      margin-left: 10px;
    }
  }

  // .export-btn {
  //   width: 84px;
  //   height: 40px;
  //   background: #ffffff;
  //   border: 1px solid #cccccc;

  //   font-size: 14px;
  //   font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  //   font-weight: 400;
  //   color: #808080;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;

  //   .arrow-drop-down {
  //     color: #b3b3b3;
  //   }

  //   img {
  //     width: 14px;
  //     height: 14px;
  //     margin-left: 10px;
  //   }
  // }

  // .export-options {
  //   position: absolute;
  //   width: 124px;
  //   height: 88px;
  //   background: #ffffff;
  //   box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  //   margin-top: 2px;
  //   z-index: 2001;
  //   padding: 8px 0;
  //   opacity: 0;
  //   transition: all 0.3s;

  //   .option {
  //     text-align: center;
  //     line-height: 36px;
  //     width: 124px;
  //     height: 36px;
  //     color: #333333;
  //   }
  //   .option:hover {
  //     color: #026bff;
  //     background: rgba(2, 107, 255, 0.1);
  //     cursor: pointer;
  //   }
  // }
  // .export-options:hover {
  //   opacity: 1;
  // }
  // .export-btn:hover ~ .export-options {
  //   opacity: 1;
  // }
}
</style>
