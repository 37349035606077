/**
 * 消息工厂类
 * @author hukeyi
 * @description 根据云信的消息对象 NIMMessage 创建消息实例
 */
import {
  ChatMsg,
  TextMsg,
  ImageMsg,
  GoodsCardMsg,
  OrderCardMsg,
  QuoteMsg,
} from './chat_msg.js';
import { SysMsg, TimeSysMsg, RecallSysMsg } from './sys_msg.js';

export class MsgFactory {
  constructor(me) {
    this.me = me?.toLowerCase(); // “我” 的 id
  }
  // 获取文本消息的内容
  getTextMsgContent(msg) {
    if (msg?.text) return msg.text;
    else if (msg?.body) return msg.body;
    else return undefined;
  }
  // 获取消息的附件对象
  getMsgContent(msg) {
    if (msg?.content) return JSON.parse(msg.content);
    else if (msg?.attach) return msg.attach;
    else return null;
  }
  // 获取消息的附件对象中的数据
  getMsgContentData(msg) {
    const content = this.getMsgContent(msg);
    return content?.content ?? null;
  }
  /**
   * 获取消息的类型
   * @param { Object } msg 云信返回的消息对象或自定义生成的消息对象
   * @returns { ChatMsg | SysMsg } text | image | quote | good | order | time | recall
   */
  getMsgType(msg) {
    if (!msg) return '';
    if (msg.type === 'text') return 'text';
    if (
      msg.type === 'recallMsg' ||
      msg.type === 'recallMsgP2p' ||
      msg.type === 'deleteMsg'
    )
      return 'recall';
    if (msg.type === 'time') return 'time';
    if (msg.type === 'tip') {
      // #memo 之后可能有不同的 tip 消息，记得在这里处理消息类型
      return 'recall'; // 目前所有 tip 消息都是撤回提示消息
    }

    const content = this.getMsgContent(msg);
    return content?.type !== 'link' ? content.type : content.dataType;
  }
  /**
   * 根据消息类型，创建消息实例
   * @param { NIMMessage } msg
   * @returns { ChatMsg | SysMsg } 对应类型的实例对象
   */
  createMsg(msg) {
    if (msg instanceof ChatMsg || msg instanceof SysMsg) return msg;

    const type = this.getMsgType(msg);
    const content = this.getMsgContent(msg);
    const data = this.getMsgContentData(msg);
    const isFromMe = msg?.from === this.me;

    if (type === 'text') {
      return new TextMsg(msg, type, this.getTextMsgContent(msg), isFromMe);
    } else if (type === 'image') {
      return new ImageMsg(msg, type, data?.url, isFromMe);
    } else if (type === 'quote') {
      return new QuoteMsg(
        msg,
        type,
        data,
        this.createMsg(content?.quoteMsg),
        isFromMe
      );
    } else if (type === 'good') {
      return new GoodsCardMsg(
        msg,
        type,
        Object.assign(data, { price: content.price }),
        isFromMe
      );
    } else if (type === 'order') {
      return new OrderCardMsg(
        msg,
        type,
        Object.assign(data, { price: content.price }),
        isFromMe
      );
    } else if (type === 'time') {
      return new TimeSysMsg(msg, type, '', isFromMe);
    } else if (type === 'recall') {
      return new RecallSysMsg(msg, type, this.getTextMsgContent(msg), isFromMe);
    }
  }
}
