<template>
  <div class="search-time">
    <el-select
      v-model="timeType"
      placeholder="请选择"
      :style="{ width: width }"
      @change="changeDateType"
    >
      <el-option label="自然日" value="day"> </el-option>
      <el-option label="自然月" value="month"> </el-option> </el-select
    ><el-date-picker
      :style="{ width: inputWidth }"
      v-show="timeType === 'day'"
      v-model="dayTime"
      type="date"
      value-format="yyyy-MM-dd"
      placeholder="选择日期"
      @change="getSelectDate"
      popper-class="bgcolor"
      :picker-options="pickerOptions"
    >
    </el-date-picker
    ><el-date-picker
      :style="{ width: inputWidth }"
      v-model="monthTime"
      @change="getSelectDate"
      value-format="yyyy-MM"
      type="month"
      placeholder="选择月"
      v-show="timeType === 'month'"
      :picker-options="pickerOptions"
    ></el-date-picker>
  </div>
</template>

<script>
import dayjs from 'dayjs';
export default {
  data() {
    return {
      timeType: 'day',
      dayTime: '',
      monthTime: '',
    };
  },
  props: {
    width: {
      // 选择器的宽度
      type: String,
      default: '120px',
    },
    inputWidth: {
      // input的宽度
      type: String,
      default: '220px',
    },
    /**
     * 控制需要什么时间范围
     * 要数字要数字要数字
     * 1今天及今天之前 2今天及今天以后 3今天之前 4今天之后 不传不做限制
     */
    timeOption: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    pickerOptions() {
      return {
        disabledDate: (time) => {
          let nowDate, thenDate;
          if (this.timeType == 'day') {
            nowDate = parseInt(dayjs().format('YYYYMMDD'));
            thenDate = parseInt(dayjs(time).format('YYYYMMDD'));
          }
          if (this.timeType == 'month') {
            nowDate = parseInt(dayjs().format('YYYYMM'));
            thenDate = parseInt(dayjs(time).format('YYYYMM'));
          }
          switch (Number(this.timeOption)) {
            case 1:
              return thenDate > nowDate;
            case 2:
              return thenDate < nowDate;
            case 3:
              return thenDate >= nowDate;
            case 4:
              return thenDate <= nowDate;
            default:
              break;
          }
        },
      };
    },
  },
  methods: {
    getSelectDate(date) {
      // console.log('所选时间=>', this.timeType, date);
      this.$emit('change', this.timeType, date);
    },
    // 改变下拉选择框内容
    changeDateType() {
      this.dayTime = '';
      this.monthTime = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.search-time {
  ::v-deep .el-select .el-input.el-input--suffix .el-input__inner {
    border-right: 1px solid transparent;
  }

  ::v-deep .el-select .el-input.is-focus .el-input__inner {
    border-right: 1px solid #026bff;
  }
  ::v-deep .el-select .el-input__inner:focus {
    border-right: 1px solid #026bff !important;
  }
}
::v-deep .el-input--prefix .el-input__inner {
  text-align: center;
}
</style>
