import dayjs from 'dayjs';
import QRCode from 'qrcode';
import { filterEmptyKey } from './utils';
import { digitalFormat } from './digitalFormat';
import axios, { Axios } from 'axios';
import IMG_ERROR_PLACEHOLDER from './imgConstant';
// 记录用户筛选条件
import { getPageFilter } from '@/utils/page-filter/tool.js';

// 过滤
let mixin = {
  data() {
    return {};
  },
  filters: {
    // 图片过滤
    imgbaseurl(value) {
      if (!value) return '';
      const procotol = value.substring(0, 4);
      let prefix = process.env.VUE_APP_STATIC_HOST;
      if (procotol == 'http' || procotol == 'blob') {
        prefix = '';
      }
      return prefix + value;
    },
  },
  beforeDestroy() {},
  methods: {
    // 图片过滤
    setImgbaseurl(value) {
      if (!value) return '';
      const procotol = value.substring(0, 4);
      let prefix = process.env.VUE_APP_STATIC_HOST;
      if (procotol == 'http' || procotol == 'blob') {
        prefix = '';
      }

      return prefix + value;
    },
    /**
     * 这个方法有个限制，只有用户进行筛选，切换tabs等操作的时候才触发，刷新页面的话是不管的
     * @param {Object} options 页面的筛选条件
     */
    setPageFilter(options) {
      // 获取页面筛选条件
      let pagefilterObj = getPageFilter(options);
      // 获取页面路由路径
      let routeLink = this.$route.path;
      this.$router.push({
        path: routeLink,
        query: {
          pageid: pagefilterObj.pageId,
        },
      });
      this.$store.commit('setPageFilterCriteria', pagefilterObj);
    },
    /**
     * 获取当前所在页面缓存的筛选条件
     * @returns 对应缓存的筛选条件
     */
    getPageFilterData() {
      // 获取vuex中的数据
      let pageFiler = this.$store.state.pageFilter.pageFiler;
      let query = this.$route.query;
      if (pageFiler?.[query?.pageid]) {
        let info = pageFiler?.[query?.pageid]?.pageFilter;
        return info || null;
        // 更新开始时间
        // this.$store.commit('upPageFilterStartTime', query?.pageid);
        // 如果缓存中有对应的页面Id
      }
    },
    // 当前是否是本地环境或者 188 测试环境
    isLocalEnv() {
      return (
        process.env.VUE_APP_ENV === 'local' ||
        process.env.VUE_APP_ENV === 'dev-188' ||
        process.env.VUE_APP_ENV === 'check-193' ||
        process.env.VUE_APP_ENV === 'check-191'
      );
    },
    /**
     * 数字格式化
     * @param {Number} num 传进来的数字
     * @param {String} locale 语言环境
     * @param {Object} options Intl.NumberFormat KPI 相对应的配置(根据自己需要)
     * @returns 相对应的数字格式
     */
    digitalFormat(num = 0, locale = 'zh-CN', options = {}) {
      return digitalFormat(num, locale, options);
    },
    /**
     * 过滤掉对象中值为空的字段
     * @author hukeyi
     * @param {*} obj
     * @returns 有值的字段组成的新对象
     */
    filterEmptyKey(obj) {
      return filterEmptyKey(obj);
    },
    /**
     * 判断目标日期是否在当前日期之前
     * @param {*} targetDate //目标日期字符串(yyyy-MM-dd mm:hhh:ss)
     * @returns boolean
     */
    isTakeEffectFunc(targetDate) {
      let currentDate = new Date();
      let year = currentDate.getFullYear();
      let month = currentDate.getMonth() + 1;
      let day = currentDate.getDate();
      return (
        new Date(targetDate.split(' ')[0]).getTime() <=
        new Date(year + '-' + month + '-' + day).getTime()
      );
    },
    /**
     * 时间格式化函数
     * @param {*} time 时间戳
     * @param {*} formatStr 格式
     * @returns 按格式格式化后的字符串
     */
    formatTime: function (time, formatStr) {
      return dayjs(time).format(formatStr);
    },
    /**
     * 节流函数
     * @param Function func 节流目标函数
     * @param String delay 延迟时间
     * @returns
     */
    throttle: function (func, delay) {
      let timer;
      return function () {
        let context = this;
        let args = arguments;
        if (!timer) {
          timer = setTimeout(() => {
            timer = null;
            func.apply(context, args);
          }, delay);
        }
      };
    },
    /**
     * 防抖函数
     * @param Function func 目标函数
     * @param String wait 时间
     * @returns
     */
    _debounce: function (func, wait) {
      let timeout;
      return function () {
        const context = this;
        const args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(context, args);
        }, wait);
      };
    },

    /**
     * 从 supplier 项目偷过来的使用示例
     *
        this.jsq = setInterval(() => {
          this.handle_deadline = this.countdown(
            res.data.list[0].handle_deadline * 1000
          );
          this.refund_deadline = this.countdown(
            res.data.list[0].refund_deadline * 1000
          );
          this.return_deadline = this.countdown(
            res.data.list[0].return_deadline * 1000
          );
          this.isTime = true;
        }, 1000);
     *
     * 回到顶部
     */
    scrollToTop: function () {
      document.documentElement.scrollTop = document.body.scrollTop = 0;
    },
    /**
     * 打开新标签页
     * @param { String } path 路由路径
     * @param { Object } query 查询参数
     */
    openInNewTab(path, query = {}) {
      let routeData = this.$router.resolve({ path, query });
      window.open(routeData.href, '_blank');
    },
    /**
     * 跳转商品详情或商品审核
     * itemId：商品id
     * type：跳转审核还是详情  1：审核，2：详情
     * target：打开页面的方式，'_self' 当前窗口打开；'_blank' 新窗口打开（默认）
     */
    toGoodsPreview(itemId, type, target = '_blank') {
      let url = `/goods/productReview/${itemId}/${type}`;
      window.open(url, target);
    },

    //获取当前时间并计算时间差
    getNowTime(time) {
      if (time === '') {
        return '';
      }
      var date3 = time;
      if (date3 <= 0) {
        // clearInterval(this.jsq)
        // this.jsq = ''
        return '超时';
      } else {
        // date3 = Math.abs(date3);
        //计算出相差天数
        var days = Math.floor(date3 / (24 * 3600 * 1000));
        //计算出小时数
        var leave1 = date3 % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
        var hours = Math.floor(leave1 / (3600 * 1000));
        //计算相差分钟数
        var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
        var minutes = Math.floor(leave2 / (60 * 1000));
        //计算相差秒数
        var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
        var seconds = Math.round(leave3 / 1000);
        let surplusTime =
          days + '天' + hours + '时' + minutes + '分' + seconds + '秒';
        return surplusTime;
      }
    },
    // 倒计时 （跟上面配套使用，有问题改或说）
    // 自己定义一个setInterval 放进自己定义的变量里 进行倒计时 ，注：记得销毁计时器
    countdown(gettime) {
      // 需要传进来的时间戳  10位数就*1000
      const t1 = Date.parse(new Date());
      return gettime - t1 > 0 ? gettime - t1 : 0;
    },
    // 通过后缀名作文件判断
    matchType(fileName) {
      // 后缀获取
      var suffix = '';
      // 获取类型结果
      var result = '';
      try {
        var flieArr = fileName.split('.');
        suffix = flieArr[flieArr.length - 1]?.toLowerCase();
      } catch (err) {
        suffix = '';
      }
      // fileName无后缀返回 false
      if (!suffix) {
        result = false;
        return result;
      }
      // 图片格式
      var imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'jfif', 'webp'];
      // 进行图片匹配
      result = imglist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'image';
        return result;
      }
      // 匹配txt
      var txtlist = ['txt'];
      result = txtlist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'txt';
        return result;
      }
      // 匹配 excel
      var excelist = ['xls', 'xlsx', 'csv'];
      result = excelist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'excel';
        return result;
      }
      // 匹配 word
      var wordlist = ['doc', 'docx'];
      result = wordlist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'word';
        return result;
      }
      // 匹配 pdf
      var pdflist = ['pdf'];
      result = pdflist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'pdf';
        return result;
      }
      // 匹配 ppt
      var pptlist = ['ppt', 'pptx'];
      result = pptlist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'ppt';
        return result;
      }
      // 匹配 视频
      var videolist = ['mp4', 'm2v', 'mkv'];
      result = videolist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'video';
        return result;
      }
      // 匹配 音频
      var radiolist = ['mp3', 'wav', 'wmv'];
      result = radiolist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'radio';
        return result;
      }
      // 匹配压缩包
      var radiolist = ['zip'];
      result = radiolist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'zip';
        return result;
      }
      // apk
      var radiolist = ['apk'];
      result = radiolist.some(function (item) {
        return item == suffix;
      });
      if (result) {
        result = 'apk';
        return result;
      }
      // 其他 文件类型
      result = 'other';
      return result;
    },
    //复制文本
    copyText(text) {
      try {
        const input = document.createElement('input');

        input.style.opacity = 0;
        input.value = text;

        // 将元素插入到页面
        document.body.appendChild(input);

        // input.select 只能在元素插入到页面后, 才生效
        input.select();

        // isCopy 是否拷贝成功
        // document.execCommand 方法是已经废弃的方法
        const isCopy = document.execCommand('Copy');

        // 拷贝完成后, 不要忘了删除之前插入的元素
        document.body.removeChild(input);
        this.$message({ type: 'success', message: '复制成功!' });
        return isCopy;
      } catch (err) {
        return false;
      }
    },
    //将逗号传参风格转换成数组
    analysisComma(imgString, splitFlag = ',') {
      imgString = imgString || '';
      let arr = [];
      if (imgString.indexOf(splitFlag) != -1) {
        arr = imgString.split(splitFlag);
        return arr;
      }
      return imgString ? [imgString] : arr;
    },
    //下载文件(字符流token放在请求头中)
    downloadFile(url, fileName, type = 'get', data = {}) {
      let box = '/api' + url;
      let option = {
        url: box,
        headers: { token: localStorage.getItem('token3') },
        responseType: 'blob',
        method: type,
      };
      if (type == 'post') {
        option.method = 'post';
        option.data = data;
      }
      axios(option).then((res) => {
        const blob = new Blob([res.data]);
        const objectURL = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = objectURL;
        link.download = fileName;
        link.click();
      });
    },
    // 链接下载
    down(url, fileName) {
      if (!url) return '';
      const procotol = url.substring(0, 4);
      const prefix =
        procotol == 'http' ? url : process.env.VUE_APP_STATIC_HOST + url;
      axios({
        url: prefix + '?attname=' + fileName,
        method: 'get',
        responseType: 'blob',
      }).then((res) => {
        const filestream = res.data; // 返回的文件流
        const blob = new Blob([filestream], {
          type: res.headers['content-type'],
        });
        const a = document.createElement('a');
        const href = window.URL.createObjectURL(blob); // 创建下载连接
        a.href = href;
        a.download = decodeURI(fileName || Date.now());
        document.body.appendChild(a);
        // console.log(a)
        // window.open(a.href)
        a.click();
        document.body.removeChild(a); // 下载完移除元素
        window.URL.revokeObjectURL(href); // 释放掉blob对象
      });
    },
    /**
     * 生成二维码
     * [qrcode](https://www.npmjs.com/package/qrcode#todataurltext-options-cberror-url)
     * @parma options 二维码的配置
     */
    createQRcode(options, cb) {
      // 会返回一个base64的链接
      return QRCode.toDataURL(options.text, options, cb);
    },
    /**
     * 图片加载失败占位
     */
    handleImgError(event) {
      const img = event.target;
      // 用 base64 编码图片作为占位图片，避免出现 onerror 死循环导致递归爆栈
      img.src = IMG_ERROR_PLACEHOLDER;
    },
    /**
     * base64编码转blob
     * @param {*} code base64编码
     * @returns [Blob对象，type]
     */
    base64ToBlob(code) {
      if (!code) {
        console.error('No base64-image input. ');
        return;
      }
      let [type, content] = code.split(',');
      let raw = atob(content);
      let rawLength = raw.length;
      let uInt8Array = new Uint8Array(rawLength);
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      const blob = new Blob([uInt8Array], { type });
      return blob;
    },
    /**
     * blob转file对象
     * @param {*} blob
     * @param {*} type
     */
    blobToFile(blob) {
      let type = blob.type;
      let filename = this.getRandomFileName(this.getBlobImageType(type));
      const blobFile = new File([blob], filename, { type });
      return blobFile;
    },
    /**
     * 获取随机文件名
     * 用当前日期和时间作为文件名
     * @param {*} prefix 随机文件名的前缀
     * @param {*} type 随机文件的文件类型
     */
    getRandomFileName(type, prefix = 'xm') {
      let filename = dayjs().format('YYYYMMDDHHmmss');
      return `${prefix}-${filename}.${type}`;
    },
    /**
     * 下载blob图片文件流
     * @param {*} blob
     */
    downloadBlob(blob) {
      const objectURL = URL.createObjectURL(blob);
      const link = document.createElement('a');

      let type = this.getBlobImageType(blob.type);
      link.href = objectURL;
      link.download = this.getRandomFileName(type, 'xm-qrcode');
      link.click();
    },
    /**
     * 解析base64编码文件类型包含的文件名
     * e.g. data:image/png;base64 -> png
     * @param String type
     */
    getBlobImageType(type) {
      let temp = type.split(';')[0].split('/');
      return temp[temp.length - 1];
    },
    /**
     * 开启与目标对象的聊天
     */
    chatWith(id) {
      if (id) {
        let path = `/chat/${id.toLowerCase()}`;
        let routeData = this.$router.resolve({ path });
        window.open(routeData.href, '_blank');
      }
    },
    /**
     * @param { String } btnKey 要判断的按钮的key值
     */
    // 判断某个按钮权限状态(true表示开启)
    hasBtnPerm(btnKey) {
      const btnPerms = this.$store.state.myBtnPerms;
      if (btnPerms?.size > 0) {
        return btnPerms.has(btnKey);
      }
    },
    /**
     *
     * @param {String} text 容器内的文字
     * @param {Number} widthNumber 容器的最大宽度
     * @param {String} fontStyle 文字的样式
     * @returns {Boolean} true表示溢出
     */
    //判断容器中的内容是否溢出
    isCheckOverflow(text, widthNumber, fontStyle = '14px Microsoft YaHei') {
      const canvasNode = document.createElement('canvas');
      const context = canvasNode.getContext('2d');
      context.font = fontStyle;
      const { width } = context.measureText(text);
      return width > widthNumber;
    },
    /**
     * 获取存在七牛云上的视频的第一帧图片
     * @param { String } videoSrc 七牛云的视频链接
     */
    getVideoPoster(videoSrc) {
      if (!videoSrc) return '';
      // 如果没有拼前缀，则拼上
      if (!videoSrc.startsWith('http')) {
        videoSrc = process.env.VUE_APP_STATIC_HOST + videoSrc;
      }
      return videoSrc + '?vframe/jpg/offset/1';
    },
    // 表头样式5(12px字体，40px高)
    headerStyle5() {
      return {
        background: '#F5F5F5',
        color: '#666',
        fontWeight: '400',
        fontSize: '14px',
        height: '40px',
      };
    },
    /**
     * 比较两个值是否相等，具体支持以下类型：
     * 数组 (arrays)
     * 数组缓冲区 (array buffers)
     * 布尔值 (booleans)
     * 日期对象 (date objects)
     * 错误对象 (error objects)
     * 映射 (maps)
     * 数字 (numbers)
     * 对象 (Object objects)
     * 正则表达式 (regexes)
     * 集合 (sets)
     * 字符串 (strings)
     * 符号 (symbols)
     * 类型化数组 (typed arrays)
     * @param {} a
     * @param {} b
     * @returns {Boolean} true表示相等
     */
    isValueEqual(a, b) {
      const _ = require('lodash');
      return _.isEqual(a, b);
    },
  },
};

export default mixin;
