import { render, staticRenderFns } from "./XmLanguageTab.vue?vue&type=template&id=dbab6ad8&scoped=true"
import script from "./XmLanguageTab.vue?vue&type=script&lang=js"
export * from "./XmLanguageTab.vue?vue&type=script&lang=js"
import style0 from "./XmLanguageTab.vue?vue&type=style&index=0&id=dbab6ad8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dbab6ad8",
  null
  
)

export default component.exports