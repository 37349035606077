<template>
  <div class="xm-echarts-pie">
    <div class="content flex" v-if="!noData">
      <div
        class="pie-legend"
        :class="[pieBoxData?.length > 7 ? 'two-columns' : 'only-column']"
      >
        <div
          v-for="(item, index) in pieBoxData"
          :key="index"
          @click="clickLegend(index)"
          class="legend-list"
          :class="{ grey: !item.show }"
        >
          <div
            class="tip-color"
            :style="{ 'background-color': item.boxColor }"
          ></div>
          <div class="legend-name">{{ item.boxNames }}</div>
        </div>
      </div>

      <!-- 饼图 -->
      <div class="pie-picture">
        <div class="pie-title" v-if="chartBoxData?.pieTitle">
          {{ chartBoxData?.pieTitle }}
        </div>
        <div ref="statistEcharts" class="echarts-pie"></div>
      </div>
    </div>

    <div class="no-data" v-if="noData">暂无数据</div>
  </div>
</template>
<script>
// import * as echarts from 'echarts';
export default {
  name: 'XmEchartsPie',
  components: {},
  props: {
    /**
     * 要展示的数据
     * chartBoxData： {
     *  pieTitle: '我是一个正经的标题', // 当前图表的标题
     *  boxNums: [11, 32], // 图表的每项数据
     *  boxNames: ['1-10单','11-100单'], // 图表的每项名字
     *  boxColor: ['#ff6862','#ff7802'], // 图表的每项颜色
     * }
     */
    chartBoxData: {
      type: Object,
      default: () => {},
    },
    // 图例是否纯展示,默认否(默认会点击隐藏)
    legendOnlyShow: {
      type: Boolean,
      default: false,
    },
    // pie的宽高
    pieWidth: {
      type: String,
      default: '206px',
    },
    // pie悬浮放大 true需要(默认) false不需要
    hoverScale: {
      type: Boolean,
      default: true,
    },
    // pie的展示顺序,true顺时针(默认) false逆时针
    clockwise: {
      type: Boolean,
      default: true,
    },
    // pie内/外圆的半径
    radius: {
      type: Array,
      default: [50, 100],
    },
    // pie中心点，跟半径相关
    centerPoint: {
      type: Array,
      default: [103, 103],
    },
  },
  data() {
    return {
      pieBoxData: [], // 经过处理之后的饼图数据存储
      noData: false, // 有无数据
    };
  },
  watch: {
    // 处理传入数据
    chartBoxData: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (newVal) {
          if (newVal?.boxNums && newVal?.boxNums?.length == 0) {
            this.noData = true;
            return;
          } else {
            this.noData = false;
          }
          this.pieBoxData = JSON.parse(JSON.stringify(newVal || {}));

          this.pieBoxData = this.pieBoxData?.boxNums?.map((boxNums, index) => ({
            boxNums,
            boxNames: this.pieBoxData?.boxNames[index],
            boxColor: this.pieBoxData?.boxColor[index],
            show: true,
          }));
          this.$nextTick(() => {
            this.initEcharts(this.pieBoxData);
          });
        }
      },
    },
  },
  methods: {
    initEcharts(pieData) {
      let pieDataShow = JSON.parse(JSON.stringify(pieData));
      pieDataShow = pieDataShow?.filter((box) => box.show);
      // 避免报错：There is a chart instance already initialized on the dom
      const el = this.$refs.statistEcharts;
      let chart = echarts.getInstanceByDom(el);
      if (chart == null) {
        chart = echarts.init(el);
      }

      let options = {
        // 标题
        title: {
          show: false, // 是否展示
        },
        // 图例
        legend: {
          show: false, // 是否展示
        },
        series: {
          type: 'pie',
          data: [], // 系列中的数据内容数组
          clockwise: this.clockwise, // 饼图的扇区顺时针true,逆false
          top: 0,
          right: 50,
          center: this.centerPoint,
          radius: this.radius,
          // avoidLabelOverlap: false, // 是否启用防止标签重叠策略
          // 饼图图形上的文本标签
          label: {
            show: false,
          },
          // 高亮状态的扇区和标签样式
          emphasis: {
            scale: this.hoverScale, // 是否开启高亮后扇区的放大效果
            scaleSize: 3, // 高亮后扇区的放大尺寸
          },
          emptyCircleStyle: {
            color: '#ccc', // 无数据时占位圆的颜色
          },
        },
        // 提示框组件
        tooltip: {
          trigger: 'item', // 触发类型 item数据项图形触发
          className: 'pie-tooltip', // 指定tooltip的DOM节点的CSS类
          // position: 'top',
          padding: [6, 12], // 提示框浮层内边距
          // 提示框浮层的文本样式
          textStyle: {
            color: '#333',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontFamily: 'Microsoft YaHei', // 字体系列
            lineHeight: 20,
          },
          // 提示框浮层内容格式器，支持字符串模板和回调函数两种形式
          formatter: (params) => {
            let dataValue = params.data.value;
            let dataStr = `<div><p style="line-height: 20px; color: #333; font-size: 14px;">${params.name}：${dataValue}</p><p style="line-height: 20px; color: ${params.color}; font-size: 14px">${params.percent}%</p></div>`;
            return dataStr;
          },
        },
      };

      let seriesData = [];
      for (let i = 0; i < pieDataShow?.length; i++) {
        seriesData.push({
          name: pieDataShow[i].boxNames,
          value: pieDataShow[i].boxNums,
          itemStyle: {
            color: pieDataShow[i].boxColor,
          },
        });
      }
      options.series.data = seriesData;

      chart.setOption(options);
    },
    // 点击图例
    clickLegend(index) {
      if (this.legendOnlyShow) return; // 纯展示的图例点击无效
      this.pieBoxData[index].show = !this.pieBoxData[index].show;
      this.initEcharts(this.pieBoxData);
    },
  },
};
</script>
<style lang="scss" scoped>
.xm-echarts-pie {
  padding: 0 20px;
  background-color: #fff;

  .content {
    padding-top: 50px;
    align-items: center;

    .pie-legend {
      flex-shrink: 0;
      padding: 3px 0;
      line-height: 18px;
      color: #111;
      font-size: 12px;

      // 小于等于7条数据排列
      &.only-column {
        .legend-list .legend-name {
          max-width: 166px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      // 超过7条数据排2列
      &.two-columns {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        height: 136px;

        .legend-list {
          &:nth-child(5n) {
            margin-bottom: 0;
          }
          .legend-name {
            max-width: 63px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }

      .legend-list {
        display: flex;
        align-items: center;
        margin-right: 20px;
        margin-bottom: 10px;
        cursor: pointer;

        .tip-color {
          margin-right: 10px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
        }
      }

      .legend-list.grey {
        color: #ccc;

        .tip-color {
          background-color: #ccc !important;
        }
      }
    }

    .pie-picture {
      position: relative;
      width: auto;

      .pie-title {
        position: absolute;
        top: -49px;
        width: 100%;
        line-height: 22px;
        color: #333;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
      }
      ::v-deep .echarts-pie {
        width: v-bind(pieWidth);
        height: v-bind(pieWidth);
        .pie-tooltip {
          text-align: center;
          border: none !important;
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  .no-data {
    line-height: 100px;
    color: #808080;
  }
}
</style>
