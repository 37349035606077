/**
 * 浏览器通知，主要用于聊天的新消息通知
 * @author hukeyi
 * @description 1）电脑桌面的浏览器通知 2）浏览器的标签页标题闪烁
 */

function sendNotification(title, content, params) {
  let options = {
    body: content,
    silent: true,
    requireInteraction: false, // 不自动关闭
    tag: `admin-chat-notice-${params?.id}`, // 消息通知的 id，用于标识、合并消息通知
    data: `${process.env.VUE_APP_HOST}/chat/${params?.id}`, // 发送新消息的用户的聊天对话框的 url
  };
  let notice = new Notification(title, options);
  notice.onclick = function (event) {
    console.log('clicked notice', event);
    window.open(event.target.data);
  };
}

/**
 * 浏览器弹窗
 * @param { String } title 消息通知标题
 * @param { String } content 消息通知内容
 * @param { Object } params 消息选项
 * @returns 电脑桌面弹出弹窗
 */
export function notify(title = '平台新消息通知', content = '', params) {
  if (!('Notification' in window)) {
    // 浏览器不支持通知，静默失败
    return;
  }

  if (Notification.permission === 'granted') {
    sendNotification(title, content, params);
  } else if (Notification.permission !== 'denied') {
    Notification.requestPermission().then(function (permission) {
      if (permission === 'granted') {
        sendNotification(title, content, params);
      }
    });
  }
}

/**
 * 标签页的闪烁提醒
 * @description 当用户未停留在当前标签页时，标签页标题闪烁
 */
let flashTabTimer = null;
export function flashTab() {
  if (flashTabTimer) {
    // 只保留一个定时器
    return;
  }
  const defaultTitle = document.title;
  flashTabTimer = setInterval(function () {
    const title = document.title;
    let inChatPage = document.location?.pathname?.indexOf('chat') >= 0; // 当前是否在聊天界面
    if (inChatPage && document.hidden) {
      // 用户是否停留在当前标签，不在则切换标题
      if (title !== '【***您有新消息***】') {
        document.title = '【***您有新消息***】';
      } else {
        document.title = defaultTitle;
      }
    } else {
      // 在当前页则停止定时器，恢复默认标题
      clearInterval(flashTabTimer);
      document.title = defaultTitle;
      flashTabTimer = null;
    }
  }, 100);
}
