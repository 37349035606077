<template>
  <div class="input-box" :style="{ width: inputWidth }" :class="className">
    <div>
      <input
        type="text"
        v-model="val"
        :placeholder="placeholder"
        :input="changeInput()"
        @blur="lostBlur"
        @focus="lostFocus"
        :style="{ width: inputWidth, height: inputHeight }"
      />
    </div>
    <div
      v-if="category === 'percentage'"
      class="percentage-box"
      :style="{ top: poTop }"
    >
      %
    </div>
    <div
      v-if="category === 'num' || category === 'znum'"
      class="percentage-box"
      :style="{ top: poTop }"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    category: {
      type: String,
      default: 'text', //控制输入框类型
    },
    placeholder: {
      type: String,
      default: '', //控制输入框默认值
    },
    inputWidth: {
      type: String,
      default: '200px', //控制输入框宽度
    },
    inputHeight: {
      type: String,
      default: '40px', //控制输入框高度
    },
    className: {
      type: String,
      default: '',
    },
    poTop: {
      type: String,
      default: '12px', //百分号离顶部位置
    },
  },
  data() {
    return {
      // val: '',
    };
  },
  computed: {
    val: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    /**
     * @description 限制输入
     * text -> 常规输入框
     * num -> 只能输入正数和小数点后两位
     * percentage -> 不能超过100的正数和小数点后两位
     */
    changeInput() {
      switch (this.category) {
        case 'text':
          break;
        case 'num':
          this.val = this.reNum();
          break;
        case 'znum':
          this.val = this.zreNum();
          break;
        case 'percentage':
          if (this.reNum() > 100) {
            this.val = 100;
            break;
          }
          this.val = this.reNum();
          break;
        default:
          break;
      }
      this.$emit('input', this.val);
    },
    reNum() {
      let val = '' + this.val;
      val = val
        .replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
        .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
        .replace('.', '$#$')
        .replace(/\./g, '')
        .replace('$#$', '.')
        .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // 只能输入两个小数
      if (val.indexOf('.') < 0 && val != '') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的情况
        return (val = parseFloat(val));
      }
      if (val.indexOf('.') === 0 && val != '') {
        // 此处控制有小数点的时候，不能出现类似.15151的情况
        return (val = parseFloat(0));
      }
      return val;
    },
    zreNum() {
      let val = '' + this.val;
      val = val
        .replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
        .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
        .replace('.', '$#$')
        .replace(/\./g, '')
        .replace('$#$', '.')
        .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3') // 只能输入两个小数
        .replace(/^(0+)|[^\d]+/g, '');
      if (val.indexOf('.') < 0 && val != '') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的情况
        return (val = parseFloat(val));
      }
      if (val.indexOf('.') === 0 && val != '') {
        // 此处控制有小数点的时候，不能出现类似.15151的情况
        return (val = parseFloat(0));
      }
      return val;
    },
    lostBlur() {
      this.$emit('blur');
    },
    lostFocus() {
      this.$emit('focus');
    },
    /**
     * @description 重置输入框方法
     * 父组件通过ref调用该方法
     */
    reData(val = '') {
      this.val = val;
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  border: 1px solid #cccccc; /*清除自带的2px的边框*/
  padding: 0px 12px; /*清除自带的padding间距*/
  outline: none; /*清除input点击之后的黑色边框*/
  box-sizing: border-box; /**将边框往里缩 */
}
.input-box {
  position: relative;
}
.percentage-box {
  position: absolute;
  right: 10px;
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #4d4d4d;
}
</style>
