<template>
  <div class="input-interval" ref="inputInterval">
    <div class="top-content">
      <div>
        <el-select
          v-model="interval"
          placeholder="请选择"
          :style="{ width: selectWidth }"
          clearable
        >
          <el-option
            v-for="item in intervalKey"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="interval">
        <div>
          <el-input
            :style="{ width: inputWidth }"
            v-model="interMin"
            @input="changInterMin"
            @blur="blurInterMin"
            @focus="inputFocus"
          ></el-input>
        </div>
        <div class="line">-</div>
        <div>
          <el-input
            :style="{ width: inputWidth }"
            v-model="interMax"
            @input="changInterMax"
            @blur="blurInterMax"
            @focus="inputFocus"
          ></el-input>
        </div>
      </div>
    </div>
    <div class="operaModel" ref="operaModel">
      <div class="btn-model">
        <div class="kong-btn-clear" @click="clearData()">清空</div>
        <div class="kong-btn-confim" @click="configData()">确定</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'inputInterval',
  data() {
    return {
      interval: '',
      interMin: '',
      interMax: '',
    };
  },
  props: {
    intervalKey: {
      type: Array,
      default: function () {
        return [];
      },
    },
    selectWidth: {
      type: String,
      default: '143px',
    },
    inputWidth: {
      type: String,
      default: '55px',
    },
  },
  // watch: {
  //   interMin(val) {
  //     this.changeKey();
  //   },
  //   interMax(val) {
  //     this.changeKey();
  //   },
  // },
  mounted() {
    document.addEventListener('click', this.bodyCloseMenus);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.bodyCloseMenus);
  },
  methods: {
    changInterMin(val) {
      this.interMin = val.replace(/[^\d]/g, '');
      // if (this.interMax && this.interMin > this.interMax) {
      //   this.$message.warning('请输入正确的区间');
      //   this.interMin = '';
      // }
    },
    changInterMax(val) {
      this.interMax = val.replace(/[^\d]/g, '');
      // if (this.interMin && this.interMin > this.interMax) {
      //   this.$message.warning('请输入正确的区间');
      //   this.interMax = '';
      // }
    },
    getInterData() {
      this.$emit('getInterData', this.interval, this.interMin, this.interMax);
    },
    blurInterMin(val) {
      // this.$refs.operaModel.style.display = 'none';
      if (this.interMax && Number(this.interMin) > Number(this.interMax)) {
        this.$message.warning('请输入正确的区间');
        this.interMin = '';
      }
    },
    blurInterMax(val) {
      // this.$refs.operaModel.style.display = 'none';
      if (this.interMin && Number(this.interMin) > Number(this.interMax)) {
        this.$message.warning('请输入正确的区间');
        this.interMax = '';
      }
    },
    inputFocus() {
      this.$refs.operaModel.style.display = 'block';
    },
    clearData() {
      this.interval = '';
      this.interMin = '';
      this.interMax = '';
      this.$refs.operaModel.style.display = 'none';
    },
    configData() {
      this.getInterData();
      this.$refs.operaModel.style.display = 'none';
    },
    bodyCloseMenus(e) {
      if (
        this.$refs.inputInterval &&
        !this.$refs.inputInterval.contains(e.target)
      ) {
        this.$refs.operaModel.style.display = 'none';
      }
    },
    // 搜索条件回显
    echoSearchData(key = '', min = '', max = '') {
      this.interval = key; //下拉选项
      this.interMin = min; //最小值
      this.interMax = max; //最大值
    },
  },
};
</script>

<style lang="scss" scoped>
.input-interval {
  position: relative;
  z-index: 2;

  .top-content {
    display: flex;
    justify-content: flex-start;
    &::v-deep .el-input__inner {
      height: 40px;
    }
    .interval {
      display: flex;
      justify-content: flex-start;
      border: 1px solid #cccccc;
      border-left-color: transparent;
      height: 40px;
      overflow: hidden;
      padding: 0px 20px;
      .line {
        line-height: 40px;
        width: 21px;
        text-align: center;
      }
      &::v-deep .el-input__inner {
        border: none;
      }
    }
  }

  .operaModel {
    position: absolute;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    background: #ffffff;
    width: 100%;
    top: 0px;
    left: 0px;
    z-index: -1;
    display: none;
    .btn-model {
      margin-top: 80px;
      box-sizing: border-box;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      .kong-btn-confim {
        width: 48px;
        height: 32px;
        background: #026bff;
        border-radius: 4px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        line-height: 32px;
        cursor: pointer;
      }
      .kong-btn-clear {
        width: 48px;
        height: 32px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #808080;
        line-height: 32px;
        text-align: left;
        cursor: pointer;
      }
    }
  }
}
</style>
