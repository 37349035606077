<template>
  <div class="xm-searc-interval">
    <div
      class="select"
      :style="{ width: selectWidth + 'px' }"
      v-if="leftType === 'select'"
    >
      <el-select
        clearable
        :placeholder="placeholderSelect"
        v-model="searchValue"
        @change="changeVal"
        @clear="clearVal('send')"
      >
        <el-option
          v-for="item in searchList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div
      class="label"
      :style="{ width: labelWidth + 'px' }"
      v-else-if="leftType === 'label'"
    >
      {{ labelText }}
    </div>
    <div
      class="input-group"
      :class="{ 'focus-border': focusInput, 'has-symbol': isPercent }"
      :style="{ width: inputWidth + 'px' }"
    >
      <div class="from">
        <el-input
          ref="input-from"
          v-model="inputValueFrom"
          :placeholder="placeholderInput"
          @focus="borderActive"
          @blur="borderBlur"
          @input="handleInputChangeFrom"
          @keyup.enter.native="sureSearch"
        >
          <span v-if="isPercent" class="symbol" slot="suffix">%</span>
        </el-input>
      </div>
      <div class="center">
        <span>-</span>
      </div>
      <div class="to">
        <el-input
          ref="input-to"
          v-model="inputValueTo"
          :placeholder="placeholderInput"
          @focus="borderActive"
          @blur="borderBlur"
          @input="handleInputChangeTo"
          @keyup.enter.native="sureSearch"
        >
          <span v-if="isPercent" class="symbol" slot="suffix">%</span>
        </el-input>
      </div>
    </div>
    <slot name="unit"></slot>
    <div class="button" @click="sureSearch" v-if="showSearchBtn">
      <img src="@/assets/images/search.svg" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'XmSearchInterval',
  props: {
    // 左侧的类型：select 选择器；label 标签文本
    leftType: {
      type: String,
      default: 'select',
    },
    // 标签宽度
    labelWidth: {
      type: [String, Number],
      default: '',
    },
    // 标签内容
    labelText: {
      type: String,
      default: '',
    },
    // 下拉框列表
    searchList: {
      type: Array,
      default: () => [],
    },
    // 下拉框列表宽度
    selectWidth: {
      type: [Number, String],
      default: 140,
    },
    // 输入框宽度(不包括搜索放大镜)
    inputWidth: {
      type: [Number, String],
      default: 224,
    },
    // 选择器的默认提示
    placeholderSelect: {
      type: String,
      default: '请选择',
    },
    // 输入框的默认提示
    placeholderInput: {
      type: String,
      default: '请输入',
    },
    // 可输入最小值 数字
    minNumber: {
      type: Number,
      default: 0,
    },
    // 可输入最大值 数字
    maxNumber: {
      type: Number,
      default: 100,
    },
    // 是否是百分比
    isPercent: {
      type: Boolean,
      default: false,
    },
    // 是否展示搜索图标
    showSearchBtn: {
      type: Boolean,
      default: true,
    },
    // 当输入框失焦，是否更新表单数据事件
    updateWhenBlur: {
      type: Boolean,
      default: false,
    },
    // 是否可以输入小数
    isFraction: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {
      focusInput: false, // 是否聚焦
      searchValue: '', // 下拉筛选的值
      inputValueFrom: '', // 小值
      inputValueTo: '', // 大值
    };
  },
  methods: {
    // 输入框聚焦
    borderActive() {
      this.focusInput = true;
    },
    // 输入框失焦
    borderBlur() {
      this.focusInput = false;
      if (this.updateWhenBlur) {
        this.updateFormValue();
      }
    },
    // 更新表单数据
    updateFormValue() {
      // 小值不符合规范，置空
      if (this.inputValueFrom == '' || this.inputValueFrom < this.minNumber) {
        this.inputValueFrom = '';
      }
      // 大值不符合规范，置空
      if (this.inputValueTo == '' || this.inputValueTo > this.maxNumber) {
        this.inputValueTo = '';
      }
      // 前大于后，调换
      if (
        this.inputValueFrom &&
        this.inputValueTo &&
        this.inputValueFrom > this.inputValueTo
      ) {
        this.inputValueTo = this.inputValueFrom;
      }
      if (!this.searchValue || this.searchValue == '') {
        this.searchValue = '1';
      }
      this.$emit(
        'update',
        this.searchValue?.toString(),
        this.inputValueFrom?.toString(),
        this.inputValueTo?.toString()
      );
    },
    // 输入小值
    handleInputChangeFrom(val) {
      if (this.isFraction) {
        this.inputValueFrom = val?.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1');
      } else {
        this.inputValueFrom = val?.replace(/[^\d]/g, '');
      }
      if (this.inputValueFrom > this.maxNumber) {
        this.inputValueFrom = this.maxNumber;
      }
      if (
        this.inputValueFrom &&
        this.inputValueFrom != '' &&
        this.inputValueFrom?.toString()?.indexOf('.') < 0
      ) {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的情况
        this.inputValueFrom = parseFloat(this.inputValueFrom);
      }
    },
    // 输入大值
    handleInputChangeTo(val) {
      if (this.isFraction) {
        this.inputValueTo = val?.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1');
      } else {
        this.inputValueTo = val?.replace(/[^\d]/g, '');
      }
      if (this.inputValueTo > this.maxNumber) {
        this.inputValueTo = this.maxNumber;
      }
      if (
        this.inputValueTo &&
        this.inputValueTo != '' &&
        this.inputValueTo?.toString()?.indexOf('.') < 0
      ) {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的情况
        this.inputValueTo = parseFloat(this.inputValueTo);
      }
    },
    // 确认搜索
    sureSearch() {
      if (this.inputValueFrom == '' && this.inputValueTo == '') {
        this.$emit(
          'search',
          this.searchValue?.toString(),
          this.inputValueFrom?.toString(),
          this.inputValueTo?.toString()
        );
        return;
      }
      // 前
      if (this.inputValueFrom == '' || this.inputValueFrom < this.minNumber) {
        this.inputValueFrom = this.minNumber;
      }
      // 后
      if (this.inputValueTo == '' || this.inputValueTo > this.maxNumber) {
        this.inputValueTo = isFinite(this.maxNumber) ? this.maxNumber : '';
      }
      // 前大于后
      if (
        this.inputValueFrom &&
        this.inputValueTo &&
        this.inputValueFrom > this.inputValueTo
      ) {
        this.inputValueTo = this.inputValueFrom;
      }
      if (!this.searchValue || this.searchValue == '') {
        this.searchValue = '1';
      }

      this.$emit(
        'search',
        this.searchValue?.toString(),
        this.inputValueFrom?.toString(),
        this.inputValueTo?.toString()
      );
    },
    // 改变下拉框的值
    changeVal(val) {
      this.inputValueFrom = '';
      this.inputValueTo = '';
      // 选择下拉框的关键字 key
      this.$emit('select-prop', val);
    },
    // 清空所有值
    clearVal(type) {
      this.searchValue = '';
      this.inputValueFrom = '';
      this.inputValueTo = '';
      if (type && type == 'send') {
        this.$emit(
          'search',
          this.searchValue?.toString(),
          this.inputValueFrom?.toString(),
          this.inputValueTo?.toString()
        );
      }
    },
    // 输入框回显
    echoData(key = '', minVal = '', maxVal = '') {
      this.searchValue = key;
      this.inputValueFrom = minVal; // 小值
      this.inputValueTo = maxVal; // 大值
    },
  },
};
</script>
<style lang="scss" scoped>
.xm-searc-interval {
  display: flex;
  align-items: center;

  .select {
    margin-right: -1px;

    &:focus-within {
      z-index: 1;
    }
  }

  .label {
    margin-right: 10px;
  }

  ::v-deep .input-group {
    margin-right: -1px;
    display: flex;
    align-items: center;

    &:focus-within {
      z-index: 1;
    }

    &.focus-border {
      .el-input__inner,
      .center {
        border-color: #026bff !important;
      }
    }

    &.has-symbol {
      .el-input__inner {
        padding: 0 16px !important;
      }

      .el-input__suffix {
        right: 12px;
        line-height: 40px;
      }
    }

    .el-input__inner {
      padding: 0 16px;
      color: #808080;
      text-align: center;
    }

    .from {
      .el-input__inner {
        padding-right: 10px;
        border-right: none;
      }
    }

    .center {
      height: 40px;
      line-height: 38px;
      color: #808080;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .to {
      .el-input__inner {
        padding-left: 10px;
        border-left: none;
      }
    }
  }

  .button {
    position: relative;
    width: 48px;
    height: 40px;
    border: 1px solid #ccc;
    cursor: pointer;

    &:hover {
      border: 1px solid #026bff;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 16px;
      height: 16px;
    }
  }
}
</style>
