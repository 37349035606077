<template>
  <div class="multiple-choice" :style="{ width: width }">
    <!-- <el-popover
      placement="bottom-start"
      trigger="hover"
      :disabled="selectData.length <= 2 || selectShow"
      :open-delay="300"
      :popper-append-to-body="false"
    > -->
    <el-select
      slot="reference"
      clearable
      v-model="selectData"
      placeholder="请选择"
      multiple
      @change="getSelect"
      @visible-change="changeSelectShow"
      popper-class="xm-multiple-choice"
      :style="{ width: width }"
      @remove-tag="closeFunc"
      @clear="closeFunc"
    >
      <div
        class="search-input"
        v-if="isSearch"
        :style="{ width: optionWidth ? optionWidth : width }"
      >
        <el-input
          placeholder="搜索负责人"
          v-model="searchTarget"
          autofocus
          ref="inp"
          clearable
        >
          <img width="16px" height="16px" slot="prefix" :src="searchIcon" />
        </el-input>
      </div>
      <div
        class="option-model"
        :style="{ width: optionWidth ? optionWidth : width }"
      >
        <el-option style="display: none" value=""></el-option>
        <el-checkbox-group v-model="selectData">
          <div v-for="item in searchOption()" :key="item.value">
            <el-option :label="item.label" :value="item.value">
              <el-checkbox :label="item.value" @change="checkOption(item)">
                {{ item.label }}
              </el-checkbox>
            </el-option>
          </div>
        </el-checkbox-group>
      </div>
      <div
        class="label-box"
        :style="{ width: optionWidth ? optionWidth : width }"
      >
        <div class="bottom-model">
          <div class="bottom-label">已选择负责人:</div>
          <div
            class="bottom-value"
            v-for="(item, index) in selectData"
            :key="item"
          >
            {{ getLabel(item) }}
            <span class="hand" @click="deselect(index)">×</span>
          </div>
        </div>
      </div>
    </el-select>
    <div class="tags" v-show="selectData.length !== 0 && selectShow">
      <div class="arrow"></div>
      <div class="tags-item" v-for="(item, index) in selectData" :key="item">
        <span>{{ getLabel(item) }}</span>
        <span
          ><i
            class="el-tag__close el-icon-close"
            @click="deselect(index, true)"
          ></i
        ></span>
      </div>
    </div>

    <!-- </el-popover> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchIcon: require('@/assets/images/sousuo.svg'), // 搜索图标
      selectData: [], //
      searchTarget: '',
      selectShow: true,
      selectWidth: '1000px',
    };
  },
  props: {
    // 选项data
    optionsData: {
      type: Array,
      default: () => [],
    },
    // 是否有搜索功能
    isSearch: {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: '252px',
    },
    optionWidth: {
      type: String,
      default: '',
    },
    echoSelectData: {
      type: Array,
      default: () => [],
    },
    keyName: {
      type: String,
      default: 'keyName',
    },
    // 控制是否能够单选
    isRadio: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    echoSelectData: {
      handler(newValue) {
        this.selectData = newValue.filter((item) => item);
      },
      immediate: true,
    },
  },
  methods: {
    //根据value值获取对应label值
    getLabel(value) {
      let arr = this.optionsData.filter((item) => item.value === value);
      if (arr.length) {
        return arr[0].label;
      }
      return '';
    },
    //获取完整的value,label对象
    getObj() {
      return this.optionsData.reduce((box, item) => {
        if (this.selectData.some((itemTwo) => itemTwo === item.value)) {
          box.push({ label: item.label, value: item.value });
        }
        return box;
      }, []);
    },
    searchOption() {
      if (this.searchTarget) {
        let filterarr = [];
        filterarr = this.optionsData.filter((res) => {
          // console.log(res.label.indexOf(this.searchTarget));
          return res.label.indexOf(this.searchTarget) > -1;
        });
        // console.log('搜索结果=>', filterarr);
        return filterarr;
      } else {
        return this.optionsData;
      }
    },
    //取消选择
    deselect(index, flag) {
      this.selectData.splice(index, 1);
      if (flag) {
        this.closeFunc();
      }
    },
    //点击复选框也能选中
    checkOption(item) {
      this.searchTarget = '';
      let i = this.selectData.indexOf(item.value);
      // 是否需要单选
      if (this.isRadio) {
        this.selectData = [];
      }
      if (i === -1) {
        this.selectData.push(item.value);
      } else {
        this.selectData.splice(i, 1);
      }
      this.$emit('change', this.getObj());
    },
    getSelect(data) {
      this.searchTarget = '';
      if (this.isRadio && this.selectData?.length > 1) {
        this.selectData.splice(0, 1);
      }
      this.$emit('change', this.getObj());
    },
    changeSelectShow(sign) {
      if (!sign) this.closeFunc();
      this.selectShow = !sign;
    },
    closeFunc() {
      this.$emit('closeEvent', this.getObj());
    },
  },
};
</script>

<style lang="scss" scoped>
.multiple-choice {
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  // margin-bottom: 10px;
  .ellipsis {
    position: absolute;
    top: 12px;
    right: 35px;
    color: #808080;
  }
}
.multiple-choice:hover .tags {
  // display: block;
  visibility: visible;
  opacity: 1;
}

.tabs-box {
  width: fit-content;
  // margin-top: 10px;
  background: #ffffff;
  height: 40px;
  padding: 8px 10px;
}
.tags {
  font-size: 12px;
  font-weight: 400;
  width: fit-content;
  margin-top: 10px;
  background: #ffffff;
  // height: 40px;
  white-space: nowrap;
  padding: 8px 10px;
  // display: none;
  visibility: hidden;
  opacity: 0;
  transition: 0.25s ease-in;
  position: absolute;
  z-index: 1000;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

  .arrow {
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-bottom: 6px solid #ffffff;
    // background: black;
    position: absolute;
    top: -12px;
    left: 16px;
  }

  .tags-item {
    display: inline-block;
    padding: 2px 10px;
    background: #f4f4f5;
    margin-right: 10px;
    color: #909399;
    i {
      text-align: center;
      position: relative;
      cursor: pointer;
      font-size: 12px;
      vertical-align: middle;
      top: -1px;
      right: -5px;
    }

    &:last-of-type {
      margin-right: 0px;
    }
  }
}
.multiple-choice ::v-deep .el-select__tags {
  flex-wrap: nowrap;
  overflow: hidden;
}
</style>
<style lang="scss">
.xm-multiple-choice {
  min-width: 0 !important;
  .el-select-dropdown__item.selected {
    background: #ffffff !important;
  }
  .el-select-dropdown__list {
    padding: 0;
  }
  .popper__arrow {
    display: none;
  }
  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
  .el-scrollbar {
    height: fit-content;
  }
  .search-input {
    padding: 10px;
  }

  .search-input .el-input__inner {
    background: #f7f7f7;
    border-color: #e6e6e6;
  }

  .search-input .el-input__prefix {
    top: 12px;
    left: 13px;
  }

  .option-model {
    height: 263px;
    overflow: auto;
  }

  .label-box {
    max-height: 95px;
    overflow: auto;
    min-height: 86px;

    &::-webkit-scrollbar {
      width: 0 !important;
      height: 0;
    }
  }
  .bottom-model {
    padding-top: 14px;
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 17px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    border-top: 1px solid #e6e6e6;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 95px;
    margin-right: -10px;
    min-height: 50px;

    &::-webkit-scrollbar {
      width: 0 !important;
      height: 0;
    }
  }

  .bottom-label {
    font-size: 12px;
    font-weight: 400;
    color: #4d4d4d;
    margin-right: 10px;
    line-height: 24px;
  }

  .bottom-value {
    background: #fff1e5;
    margin-right: 10px;
    padding: 0px 6px 0 10px;
    line-height: 24px;
    font-size: 12px;
    font-weight: 400;
    color: #026bff;
    margin-bottom: 10px;
    background: #e6f1ff;
  }
  .hand {
    cursor: pointer;
  }
  .el-select-dropdown__wrap {
    max-height: 1000px;
  }
}
</style>
