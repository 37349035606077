<template>
  <div class="cascader-class" :style="{ '--cascaderWidth': cascaderWidth }">
    <el-cascader
      :options="getCascaderData"
      :props="cascaderPropsObj"
      clearable
      popper-class="xm-multiple-disabled-cascade"
      @remove-tag="removeTagFunc"
      @change="changeFunc"
      v-model="selectedData"
      ref="xmMultipleSisabledCascade"
      :key="cascaderKey"
      :show-all-levels="false"
    ></el-cascader>
  </div>
</template>

<script>
export default {
  props: {
    //已禁用数组
    isDisabledArr: {
      type: Array,
      default: () => [],
    },
    //设置级联选择的宽度
    cascaderWidth: {
      type: String,
      default: '500px',
    },
    //标识
    flag: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      categoryList: [], //类目数组
      selectedData: [], //选中的类目
      cascaderPropsObj: {
        //类目配置对象
        multiple: true, //多选
        label: 'name', //label值对应字段
        value: 'supplier_class_id', //value值对应字段
        children: '_child', //子集值对应字段
        disabled: 'isDisabled', //禁用值对应字段
      },
      cascaderKey: '', //随机key,避免不刷新问题
    };
  },
  computed: {
    //禁用掉已选的类目信息
    getCascaderData() {
      this.initDataFunc();
      // 对已分配的类目进行处理
      this.isDisabledArr?.forEach((item) => {
        this.isDisabledFunc(item);
      });
      return this.categoryList;
    },
  },
  mounted() {
    //获取所有的类目信息
    this.categoryList = this.$store.state.categoryData.slice();
    const options = {
      isDisabled: false,
      level: 1,
      name: '全部类目',
      pid: 0,
      supplier_class_id: -1,
    };
    // this.categoryList.unshift(options);
  },
  methods: {
    refreshFunc() {
      this.cascaderKey = Math.random();
    },
    //改变回调
    changeFunc(data) {
      let selectArr = this.$refs.xmMultipleSisabledCascade.getCheckedNodes();
      let selectFirstArr = [];
      let selectSecondArr = [];
      let selectThirdArr = [];
      selectArr?.forEach((item) => {
        if (item.path.length <= 2) {
          if (item.path.length == 1) {
            if (
              this.isChildrenAllSelected(item.path[0], selectArr, 2).length ===
              item.children.length
            ) {
              selectFirstArr.push(item.path);
            }
          } else if (item.path.length == 2) {
            let flag = selectFirstArr.some((itemTwo) => {
              return itemTwo.indexOf(item.path[0]) != -1;
            });

            if (
              this.isChildrenAllSelected(item.path[1], selectArr, 3).length ===
                item.children.length &&
              !flag
            ) {
              selectSecondArr.push(item.path);
            }
          }
        } else {
          let flag = selectSecondArr.some(
            (itemTwo) => itemTwo[1] == item.path[1]
          );
          let flagTwo = selectFirstArr.some(
            (itemTwo) => itemTwo[0] === item.path[0]
          );
          if (!flag && !flagTwo) {
            selectThirdArr.push(item.path);
          }
        }
      });
      this.$emit(
        'getFunc',
        [...selectFirstArr, ...selectSecondArr, ...selectThirdArr],
        this.flag
      );
    },
    //判断子类是否全选
    isChildrenAllSelected(id, selectArr, length) {
      return selectArr.filter(
        (item) => item.path.indexOf(id) != -1 && item.path.length === length
      );
    },
    //刪除选中触发回调
    removeTagFunc(data) {
      this.$nextTick(() => {
        this.$emit('getFunc', data.slice(), this.flag);
      });
    },
    //依次禁用掉已选类目
    isDisabledFunc(arr) {
      // 只要有被选的就禁用全部类目
      if (arr && arr.length > 0) {
        this.categoryList?.some((item) => {
          if (item.name == '全部类目') {
            item.isDisabled = true;
          }
        });
      }
      if (arr?.length === 1) {
        // 选了一级的情况
        this.disabledFirst(arr);
      } else if (arr?.length == 2) {
        // 已经选了二级的情况
        this.disabledSecond(arr);
      } else if (arr?.length == 3) {
        this.disabledThird(arr);
      }
      // 解决视图不更新情况
      this.refreshFunc();
    },
    //禁用一级
    disabledFirst(arr) {
      this.categoryList?.some((item) => {
        if (Number(item?.supplier_class_id) === Number(arr?.[0])) {
          item.isDisabled = true;
          return true;
        }
      });
    },
    //禁用二级下面所有的三级
    disabledSecond(arr) {
      this.categoryList?.some((item) => {
        // 找到指定的一级类目
        if (Number(item?.supplier_class_id) === Number(arr?.[0])) {
          item?._child.some((itemTwo) => {
            if (Number(itemTwo?.supplier_class_id) === Number(arr?.[1])) {
              itemTwo.isDisabled = true;
              return true;
            }
          });
          // 得到指定的一级类目子级被选情况
          this.checkParent(item);
          return true;
        }
      });
    },
    //禁用指定二级下的三级
    disabledThird(arr) {
      this.categoryList.some((item) => {
        // 找到指定的一级类目
        if (Number(item.supplier_class_id) === Number(arr[0])) {
          item._child.some((itemTwo) => {
            // 找到指定二级类目
            if (Number(itemTwo.supplier_class_id) === Number(arr[1])) {
              itemTwo._child.some((itemThree) => {
                // 找到相对应的一级类目
                if (Number(itemThree.supplier_class_id) === Number(arr[2])) {
                  itemThree.isDisabled = true;
                  return true;
                }
              });
              this.checkParent(itemTwo);
              return true;
            }
          });
          this.checkParent(item);
          return true;
        }
      });
    },
    /**
     * 检查父级类目子级被选情况，如果子级已经全被选了 父级应该被禁用
     * @param {Object} item 检查当前级别的情况
     */
    checkParent(item) {
      item.statistics = item?._child.filter(
        (itemTwo) => itemTwo.isDisabled == true
      )?.length;
      // 如果子级已经全被选了 父级应该被禁用
      if (item.statistics === item?._child.length) {
        item.isDisabled = true;
      }
    },
    //初始化数据
    initDataFunc() {
      this.categoryList.some((item) => {
        // 初始化统计选中的子级
        item.statistics = 0;
        // 初始化选中状态
        item.isDisabled = false;
        item?._child?.forEach((itemTwo) => {
          // 初始化统计选中的子级
          itemTwo.statistics = 0;
          // 初始化选中状态
          itemTwo.isDisabled = false;
          itemTwo?._child?.forEach((itemThree) => {
            // 初始化选中状态
            itemThree.isDisabled = false;
          });
        });
      });
    },
  },
};
</script>
<style lang="scss">
.xm-multiple-disabled-cascade {
  //设置面板高度(含横向滚动条)
  .el-scrollbar__wrap {
    height: 235px;
  }
  //设置面板高度
  .el-cascader-menu {
    height: 220px;
  }
}
</style>
<style scoped lang="scss">
.cascader-class {
  ::v-deep .el-cascader {
    height: 40px;
    .el-cascader__tags {
      flex-wrap: wrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 40px;
      .el-tag {
        margin: 8px 0;
        margin-left: 10px;
        &:first-of-type {
          margin-left: 12px;
        }
      }
    }
    .el-input__inner {
      width: var(--cascaderWidth);
      height: 40px !important;
    }
  }
}
</style>
