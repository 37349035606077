<template>
  <xm-dialog
    title="可售区域"
    v-if="isShow"
    width="680px"
    @close-dialog="closeFunc"
  >
    <div class="sale-area-dialog-body">
      <el-tabs v-model="addressActiveName" >
        <el-tab-pane
          :label="item.cname"
          :name="index+''"
          v-for="(item, index) in saleable_area_data"
          :key="item.cname"
        >
          <div class="saleableAreaBody">
            <span>{{item.info}}</span>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </xm-dialog>
</template>

<script>
export default {
  data() {
    return {
      addressActiveName: '0',
    };
  },
  props: {
    saleable_area_data: {
      type: Array,
      default: () => [],
    },
    isShow:{
      type:Boolean,
      default:false
    }
  },
  methods:{
    closeFunc(){
      this.$emit('closeEvent');
    }
  }
};
</script>

<style lang="scss" scoped>
.sale-area-dialog-body {
    padding: 20px 20px 30px 20px;
    ::v-deep .el-tabs__item {
      padding-left: 16px!important;
      padding-right: 16px!important;

      &.is-top.is-active {
        // 直接用下边界做高亮标签页
        border-bottom: 1px solid #026BFF;
      }
    }

    ::v-deep .el-tabs__active-bar {
      width: 0px !important; // 注释掉 element 原有的高亮标签页底部边界
    }

    ::v-deep .el-tabs__nav-wrap::after {
      background: none;
    }

    .saleableAreaBody span {
      font-size: 12px;
      color: #333;
    }
  }
</style>
