import request from '../request';

// 登录次数监控
export function loginTimes(data) {
  return request({
    url: '/Login/loginTimes',
    method: 'get',
    params: data,
  });
}

// 验证码登录
export function codeLogin(data) {
  return request({
    url: '/login/sign',
    method: 'post',
    data: data,
  });
}

// 密码登录
export function pswLogin(data) {
  return request({
    url: '/login/pswLogin',
    method: 'post',
    data: data,
  });
}

// 退出登录
export function outLogin(data) {
  return request({
    url: '/login/logout',
    method: 'post',
    data: data,
  });
}

/**
 * 获取用户的权限
 * @param {*} data
 * @desciption 这里做了特殊处理。可以直接在接口传参内设置关闭该接口的loading动画
 * 主要是为了聊天界面，删掉聊天界面多余的 loading 动画
 */
export function getLoginAdminPower(data) {
  const config = {
    url: '/Menu/getLoginAdminPower',
    method: 'get',
    params: data,
  };
  if (data.closeLoading) {
    config.controller = { closeLoading: data.closeLoading };
    delete data.closeLoading;
  }
  return request(config);
}

// 获取短信验证码
export function getCode(data) {
  return request({
    url: '/Sms/sendSmsCode',
    method: 'get',
    params: data,
  });
}

// 验证重置密码的手机号
export function verifyEditPswCode(data) {
  return request({
    url: '/Login/rechargePasswordCode',
    method: 'post',
    data: data,
  });
}

// 提交重置的密码
export function rechargePassword(data) {
  return request({
    url: '/Login/rechargePassword',
    method: 'post',
    data: data,
  });
}
