<template>
  <div class="tips-info">
    <div class="tips-info-top">
      <span>
        {{ ruleName }}
        <el-popover
          v-if="isPopover"
          placement="bottom"
          :width="popoverWidth"
          trigger="hover"
          :append-to-body="false"
        >
          <div>{{ tipsInfo }}</div>
          <img src="@/assets/images/tip.svg" slot="reference" />
        </el-popover>
      </span>
      <span>操作</span>
    </div>
    <div class="tips-info-bottom">
      <div v-if="!isEdit" v-html="textareaValue" class="textareaDiv"></div>
      <div v-if="isEdit">
        <el-input type="textarea" v-model="textareaValue" autosize> </el-input>
      </div>
      <div>
        <span @click="editFunc" v-if="!isEdit">编辑</span>
        <span v-if="isEdit" @click="saveFunc">保存</span>
        <span class="cancel" v-if="isEdit" @click="cancelFunc">取消</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  watch: {
    textareaValueProps: {
      handler(newValue) {
        this.textareaValue = newValue;
      },
      immediate: true,
    },
  },
  props: {
    ruleName: {
      //自定义设置名称
      type: String,
      default: '默认设置名称',
    },
    textareaValueProps: {
      //传递默认数据
      type: String,
      default: '',
    },
    isPopover: {
      //是否有提示信息
      type: Boolean,
      default: false,
    },
    tipsInfo: {
      //提示内容
      type: String,
      default: '',
    },
    popoverWidth: {
      type: String,
      default: '200px',
    },
    flagType: {
      //区分标识
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isEdit: false,
      textareaValue: '', //记录文本域中的内容
    };
  },
  methods: {
    cancelFunc() {
      this.isEdit = false;
      this.textareaValue = this.textareaValueProps;
    },
    editFunc() {
      this.isEdit = true;
    },
    saveFunc() {
      this.isEdit = false;
      this.$emit('getDataEven', this.textareaValue, this.flagType); //将数据内容传递出去
    },
  },
};
</script>
<style scoped lang="scss">
.tips-info {
  padding: 20px;
  background-color: white;
  font-size: 14px;
  color: #666;
  & > div {
    display: flex;
    align-items: center;
    & > div:first-child,
    & > span:first-child {
      width: 1174px;
      padding: 8px 16px;
    }
    & > div:last-child,
    & > span:last-child {
      padding: 8px 16px;
    }
  }
  .tips-info-top {
    height: 40px;
    background: #f5f5f5;
    ::v-deep .el-popover {
      padding: 6px 12px;
      color: #666;
    }
    ::v-deep .el-popover__reference-wrapper {
      display: inline-block;
      height: 16px;
      margin-bottom: 2px;
    }
  }
  .tips-info-bottom {
    .textareaDiv {
      white-space: pre-wrap;
    }
    & > div {
      display: flex;
      &:last-child {
        align-items: center;
        span {
          cursor: pointer;
          color: #026bff;
        }
        .cancel {
          margin-left: 20px;
        }
      }
      &:first-child {
        ::v-deep .el-textarea__inner {
          resize: none;
          color: #333;
          padding: 9px 16px;
          &:focus {
            border-color: #ccc;
          }
        }
        flex-direction: column;
        color: #333;
        line-height: 22px;
      }
    }
    border-bottom: 1px solid #ccc;
  }
}
</style>
