/****   request.js   ****/
// 导入axios
import Vue from 'vue';
import axios from 'axios';
import qs from 'qs';
// 使用element-ui Message做消息提醒
import { Message, Loading } from 'element-ui';
let messageInstance = null;
const resetMessage = (options) => {
  if (messageInstance) {
    messageInstance.close();
  }
  messageInstance = Message(options);
};
['error', 'success', 'info', 'warning'].forEach((type) => {
  resetMessage[type] = (options) => {
    if (typeof options === 'string') {
      options = {
        message: options,
      };
    }
    options.type = type;
    return resetMessage(options);
  };
});
import router from '@/router/index';
import { getBaseURL } from '@/utils/api_modules.js';

//1. 创建新的axios实例，
const service = axios.create({
  // 超时时间 单位是ms，这里设置了3s的超时时间
  timeout: 2 * 1000 * 60,
  // withCredentials: true
});

//loading对象

let loading;
// 总开关
let closeLoading = false;
//当前正在请求的数量

let needLoadingRequestCount = 0;

// 显示loading
function showLoading(target, closeLoading = false) {
  // 后面这个判断很重要，因为关闭时加了抖动，此时loading对象可能还存在，
  // 但needLoadingRequestCount已经变成0.避免这种情况下会重新创建个loading
  if (!closeLoading) {
    if (needLoadingRequestCount === 0 && !loading) {
      loading = Loading.service({
        lock: true,
        // text: 'Loading...',
        background: 'rgba(255, 255, 255, 0.5)',
        target: target || 'body',
      });
    }
    needLoadingRequestCount++;
  }
}
//防抖：将 300ms 间隔内的关闭 loading 便合并为一次。防止连续请求时， loading闪烁的问题。
let toHideLoading = _.debounce(() => {
  if (loading) {
    loading.close();
    loading = null;
  }
}, 300);
//隐藏loading
function hideLoading() {
  needLoadingRequestCount--;
  needLoadingRequestCount = Math.max(needLoadingRequestCount, 0); //做个保护
  if (needLoadingRequestCount === 0) {
    //关闭loading
    toHideLoading();
  }
}
// 2.请求拦截器
service.interceptors.request.use(
  (config) => {
    config.metadata = { startTime: new Date() }; //记录请求开始时间,以便算出响应时间
    config.baseURL = getBaseURL(config.apiModule);
    const token = localStorage.getItem('token3'); //这里取token之前，你肯定需要先拿到token,存一下
    if (config.method == 'post') {
      config.headers = {
        'Content-Type':
          config.dataType === 'json'
            ? 'application/json'
            : 'application/x-www-form-urlencoded', //配置请求头
        token: token,
      };
      // 是否隐藏警告弹窗
      if (config.data?.MUTE_WARNING) {
        config.headers.mute_msg_warning = config.data.MUTE_WARNING;
        delete config.data.MUTE_WARNING;
      }
      if (config.dataType === 'form-data') {
        config.data = config.data;
      } else if (config.dataType === 'json') {
        config.data = JSON.stringify(config.data);
      } else {
        config.data = qs.stringify(config.data);
      }
    } else {
      config.data = JSON.stringify(config.data); //数据转化,也可以使用qs转换
      config.headers = {
        'Content-Type': 'application/json; charset=utf-8', //配置请求头
        token: token,
      };
      // 是否隐藏警告弹窗
      if (config.params?.MUTE_WARNING) {
        config.headers.mute_msg_warning = config.params.MUTE_WARNING;
        delete config.params.MUTE_WARNING;
      }
      config.params = config.params || {};
    }

    //判断当前请求是否设置了不显示Loading
    if (!closeLoading) {
      // 如果有控制器
      if (config.controller && config.controller.closeLoading) {
        showLoading(
          config.headers.loadingTarget,
          config.controller.closeLoading
        );
        return config;
      }
      // 如果没控制器
      showLoading(config.headers.loadingTarget);
      return config;
    }
    return config;
  },
  (error) => {
    //判断当前请求是否设置了不显示Loading
    Promise.reject(error);
  }
);

/**
 * 判断当前是否为登录状态
 * @returns Boolean
 */
function isLoginStatus() {
  let token = localStorage.getItem('token3');
  return !!token;
}
/**
 * 执行登出操作
 */
function logout() {
  localStorage.removeItem('token3');
  localStorage.removeItem('myAuths');
  localStorage.removeItem('admin_id');
  localStorage.removeItem('real_name');
  localStorage.removeItem('im_token');

  // 销毁 nim 实例
  if (Vue.prototype.$nim) {
    Vue.prototype.$nim.destroy();
    Vue.prototype.$nim = null;
  }

  // window.location.assign('/login');
  router.push('/login');
}

// 3.响应拦截器
service.interceptors.response.use(
  (response) => {
    let isLogin = isLoginStatus();
    let resCode = response && response.data ? response.data.code : '';

    if (isLogin) {
      if (resCode == -100 || resCode == -102) {
        alert('登录状态失效，请重新登录');
        logout();
      } else if (resCode == -101) {
        alert('账号在其他设备登录，请重新登录');
        logout();
      }
    }
    //接收到响应数据并成功后的一些共有的处理，关闭loading等
    //不管成不成功关就完事儿了
    hideLoading();
    let muteWarning = response?.config?.headers?.mute_msg_warning;
    if (resCode != 200) {
      let popWarning =
        resCode != -100 &&
        resCode != -101 &&
        resCode != -102 &&
        response?.data?.msg &&
        muteWarning != '1';
      if (popWarning) {
        // 接口返回异常错误码（!= 0）时，统一在这里报出警告
        resetMessage.warning(response.data.msg);
      }
    }
    return response.data;
  },
  (error) => {
    hideLoading();

    let isTimeout = false;
    let errorMessage = '';
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          errorMessage = '错误请求';
          break;
        case 401:
          errorMessage = '未授权，请重新登录';
          break;
        case 403:
          errorMessage = '拒绝访问';
          break;
        case 404:
          errorMessage = '请求错误,未找到该资源';
          break;
        case 405:
          errorMessage = '请求方法未允许';
          break;
        case 408:
          errorMessage = '请求超时';
          break;
        case 500:
          errorMessage = '服务器端出错';
          break;
        case 501:
          errorMessage = '网络未实现';
          break;
        case 502:
          errorMessage = '网络错误';
          break;
        case 503:
          errorMessage = '服务不可用';
          break;
        case 504:
          errorMessage = '网络超时';
          break;
        case 505:
          errorMessage = 'http版本不支持该请求';
          break;
        default:
          errorMessage = `连接错误${error.response.status}`;
      }
    } else {
      errorMessage = '连接服务器失败';
      if (JSON.stringify(error).includes('timeout')) {
        isTimeout = true;
        errorMessage = '连接超时，请刷新当前页面或检查您的网络';
      }
    }
    if (errorMessage) {
      resetMessage.error(errorMessage);
    }

    return Promise.reject(error?.response ? error.response : error);
  }
);
//4.导入文件
export default service;
