import Toast from '../index.vue';
let NewToast = {
  install: function (Vue) {
    //创建vue插件
    let newToast = Vue.extend(Toast); //创建vue构造器
    let toast = new newToast(); //创建实例
    document.body.appendChild(toast.$mount().$el); //挂载
    Vue.prototype.$Toast = function (text) {
      toast.isshow = true; // 传入props
      toast.text = text; // 传入props
    };
  },
};
export { NewToast };
