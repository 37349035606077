<template>
  <!-- 可产看大图、下载的图片组件 -->
  <div class="xm-preview-download-img">
    <img class="preview-img" :src="imgUrl" alt="" />
    <div class="icon-box">
      <i class="left-icon iconfont" @click="previewImg">&#xe679;</i>
      <i class="iconfont" @click="downloadImg">&#xe67a;</i>
    </div>
    <div class="mask"></div>

    <!-- 预览图片 -->
    <XmPreviewimg
      :showurl="imgUrl"
      @closepreimg="showPreviewimg = false"
      v-if="showPreviewimg"
    ></XmPreviewimg>
  </div>
</template>

<script>
export default {
  name: 'XmPreviewDownloadImg',
  props: {
    imgUrl: {
      type: String,
      default: '',
    },
    /**
     * 控制预览大图是否需要在组件内触发
     * 组件在弹窗内使用时预览图片宽度会被弹窗限制，因此如果在弹窗内使用组件，就不要用组件内的预览图片
     */
    isPreview: {
      type: Boolean,
      default: true,
    },
    /**
     * 下载的图片名字前缀  会自动拼上时间和图片后缀名
     * 如：图片_20240706095632.png
     */
    imagePrefix: {
      type: String,
      default: '图片_',
    },
  },
  data() {
    return {
      showPreviewimg: false,
    };
  },
  methods: {
    previewImg() {
      if (!this.imgUrl) return;
      if (this.isPreview) {
        this.showPreviewimg = true;
      } else {
        this.$emit('view-img');
      }
    },
    downloadImg() {
      if (!this.imgUrl) return;
      let imgSuffix =
        '.' + this.imgUrl?.split('.')[this.imgUrl?.split('.').length - 1];
      let time = this.formatTime(new Date().getTime(), 'YYYYMMDDHHmmss');

      // 图片名称
      let imageName = this.imagePrefix + time + imgSuffix;
      this.down(this.imgUrl, imageName);
    },
  },
};
</script>

<style lang="scss" scoped>
.xm-preview-download-img {
  width: 100px;
  height: 100px;
  position: relative;

  .preview-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:hover .mask,
  &:hover .icon-box {
    display: block;
  }

  .mask {
    background: rgba(17, 17, 17, 0.5);
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    display: none;
  }

  .icon-box {
    display: none;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    color: #fff;
    width: 100%;

    .iconfont {
      cursor: pointer;
      font-size: 24px;
    }
  }
  .left-icon {
    margin-right: 10px;
  }
}
</style>
