<template>
  <div class="card-tabs" :class="{ 'no-padding': tabStyle == '2' }">
    <div
      v-for="item in tabs"
      :key="item.key"
      class="card-tab-item"
      :class="{ active: activeName === item.key }"
      @click="handleClick(item)"
    >
      {{ item.name }}<span v-if="item?.num >= 0">({{ item.num }})</span>
    </div>
  </div>
</template>

<script>
/**
 * @author hukeyi
 * @example 示例见：src\pages\capital\capital-audit\index.vue
*  <xm-card-tabs
    v-model="activeBlock"
    :tabs="tabs"
    ></xm-card-tabs>
 */
export default {
  name: 'CardTabs',
  model: {
    prop: 'activeName',
    event: 'tab-click',
  },
  props: {
    activeName: {
      type: String,
      default: '',
    },
    tabs: {
      type: Array,
      default: () => [
        // {
        //   name: '充值记录',
        //   key: 'recharge',
        // },
        // {
        //   name: '保证金扣除记录',
        //   key: 'reduce',
        // },
      ],
    },
    // 标签样式
    /**
     * 标签样式
     * 1 卡片切换样式(有内边距) 2 外盒子没有内边距
     */
    tabStyle: {
      type: String,
      default: '1',
    },
  },
  methods: {
    handleClick(item) {
      this.$emit('tab-click', item.key);
    },
  },
};
</script>

<style lang="scss" scoped>
.card-tabs {
  display: flex;
  align-items: center;

  width: fit-content;
  padding: 2px 3px;
  background-color: #f7f7f7;
  &.no-padding {
    padding: 0;
    .card-tab-item {
      height: 40px;
      padding: 9px 20px;
    }
  }

  /*选中文字时避免出现蓝色背景*/
  * {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .card-tab-item {
    height: 36px;
    padding: 6px 20px;

    background-color: #f7f7f7;
    color: #666;
    font-size: 16px;

    cursor: pointer;

    &.active {
      background-color: #fff;
      color: #026bff;
    }
  }
}
</style>
