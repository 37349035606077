// 首先每次的筛选条件都是一个新的唯一值
function getUniqueMarker() {
  let str = Date.now() + Math.random().toString(36).substr(2, 9);
  let arr = str.split(''); //将字符串转换为字符数组
  for (var i = arr.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1)); //生成随机索引
    [arr[i], arr[j]] = [arr[j], arr[i]]; //交换位置
  }
  return arr.join(''); //将字符数组重新连接为字符串并返回结果
}

// 组装需要的数据格式
export function getPageFilter(formData) {
  const options = {
    starttime: Date.now(), //开始时间
    pageFilter: formData, //页面请求列表的请求参数
    pageId: getUniqueMarker(), //页面唯一值
  };
  return options;
}
