import request from '../request';

// 商品列表
export function getuseInfo(data) {
  return request({
    url: '/Item/getItemList',
    method: 'get',
    params: data,
  });
}

//
export function aaaa(data) {
  return request({
    url: '/tool/getSmsCodeInfo',
    method: 'get',
    params: data,
  });
}

// 商品分类统计
export function countCategorySupplier(data) {
  return request({
    url: 'CategoryInfo/countCategorySupplier',
    method: 'get',
    params: data,
  });
}
// 商品分类统计
export function findCategoryGoodsList(data) {
  return request({
    url: 'CategoryInfo/findCategoryGoodsList',
    method: 'get',
    params: data,
  });
}
//获取顶级分类列表
export function getCategoryList(data) {
  return request({
    url: '/CategoryInfo/getCategoryList',
    method: 'get',
    params: data,
  });
}
//获取中间层(二级)分类列表
export function getMiddleCategoryList(data) {
  return request({
    url: '/CategoryInfo/getMiddleCategoryList',
    method: 'get',
    params: data,
  });
}
//获取最底层分类列表
export function getLeafCategoryList(data) {
  return request({
    url: '/CategoryInfo/getLeafCategoryList',
    method: 'get',
    params: data,
  });
}
//新建分类
export function addCategory(data) {
  return request({
    url: '/CategoryInfo/addCategory',
    method: 'post',
    data: data,
  });
}
//新增子分类
export function addSubCategory(data) {
  return request({
    url: '/CategoryInfo/addSubCategory',
    method: 'post',
    data: data,
  });
}
//修改分类
export function editCategory(data) {
  return request({
    url: '/CategoryInfo/editCategory',
    method: 'post',
    data: data,
  });
}
//删除分类
export function deleteCategory(data) {
  return request({
    url: '/CategoryInfo/deleteCategory',
    method: 'post',
    data: data,
  });
}
//编辑新增属性
export function addEditAttribute(data) {
  return request({
    url: '/CategoryInfo/addEditAttribute',
    method: 'post',
    data: data,
  });
}
//删除属性
export function deleteAttribute(data) {
  return request({
    url: '/CategoryInfo/deleteAttribute',
    method: 'post',
    data: data,
  });
}
//获取分类详情
export function getCategoryInfo(data) {
  return request({
    url: '/CategoryInfo/getCategoryInfo',
    method: 'get',
    params: data,
  });
}
//获取分类(最后一级)的属性列表
export function getAttributeList(data) {
  return request({
    url: '/CategoryInfo/getAttributeList',
    method: 'get',
    params: data,
  });
}
// 转移类目下的商品
export function transferCategoryGoods(data) {
  return request({
    url: '/CategoryInfo/transferCategoryGoods',
    method: 'get',
    params: data,
  });
}
// 展示有商品的类目
export function viewCategoryProducts(data) {
  return request({
    url: '/CategoryInfo/viewCategoryProducts',
    method: 'get',
    params: data,
  });
}
//获取商品分组列表
export function getGroupingList(data) {
  return request({
    url: '/ItemGroup/getGroupList',
    method: 'get',
    params: data,
  });
}
//商品分组 新建分组
export function addGroup(data) {
  return request({
    url: '/ItemGroup/addGroup',
    method: 'post',
    data: data,
  });
}
//商品分组 编辑分组
export function editGroup(data) {
  return request({
    url: '/ItemGroup/editGroup',
    method: 'post',
    data: data,
  });
}
//商品分组 删除分组
export function delGroup(data) {
  return request({
    url: '/ItemGroup/delGroup',
    method: 'post',
    data: data,
  });
}
//获取商品需求管理 现有商品列表
export function getExistGoodsList(data) {
  return request({
    url: '/itemNeedHave/getList',
    method: 'get',
    params: data,
  });
}
//商品审核
export function itemExamine(data) {
  return request({
    url: '/Item/itemExamine',
    method: 'post',
    data: data,
  });
}
//商品详情
export function getItemInfo(data) {
  return request({
    url: '/item/getItemInfo',
    method: 'get',
    params: data,
  });
}
// 商品详情页面，查看历史补贴记录
export function getSubsidyRecordsList(data) {
  return request({
    url: '/item/getSkuSubsidyRecord',
    method: 'get',
    params: data,
  });
}
//获取商品需求管理 现有商品 查看详情
export function getExistDetail(data) {
  return request({
    url: '/ItemNeedHave/getInfoById',
    method: 'get',
    params: data,
  });
}
// 商品需求管理 现有商品 审核通过
export function approve(data) {
  return request({
    url: '/ItemNeedHave/examine',
    method: 'post',
    data: data,
  });
}
// 商品需求管理 现有商品 驳回
export function rejectApply(data) {
  return request({
    url: '/ItemNeedHave/reject',
    method: 'post',
    data: data,
  });
}
// 商品需求管理 现有商品 处理需求
export function processing(data) {
  return request({
    url: '/itemNeedHave/handle',
    method: 'post',
    data: data,
  });
}
//获取商品需求管理 新品开发列表
export function getNewGoodsList(data) {
  return request({
    url: '/ItemNeedNew/getList',
    method: 'get',
    params: data,
  });
}

//获取单位
export function getUnitList(data) {
  return request({
    url: '/CategoryInfo/getUnitList',
    method: 'get',
    params: data,
  });
}
//获取商品需求管理 新品开发 查看详情
export function getNewDetail(data) {
  return request({
    url: '/ItemNeedNew/getInfoById',
    method: 'get',
    params: data,
  });
}
// 商品需求管理 新品开发 确认开发或驳回
export function examine(data) {
  return request({
    url: '/ItemNeedNew/edit',
    method: 'post',
    data: data,
  });
}
//获取类目信息
export function getAllCategoryInfo(data) {
  return request({
    url: '/CategoryInfo/getAllCategoryInfo',
    method: 'get',
    params: data,
  });
}
//获取使用中的品牌信息
export function getAllCategory(data) {
  return request({
    url: '/SyscategoryBrand/getAll',
    method: 'get',
    params: data,
  });
}
// 商品需求管理 新品开发 处理需求(成功或失败)
export function developNewGoods(data) {
  return request({
    url: '/ItemNeedNew/developItemNeedNew',
    method: 'post',
    data: data,
  });
}
//获取此商品分销商订单数
export function getUserOrderDetail(data) {
  return request({
    url: '/itemCollection/getUserOrderDetail',
    method: 'get',
    params: data,
  });
}
//获取此商品分销商收藏数
export function getUserCollectionTotalDetail(data) {
  return request({
    url: '/itemCollection/getUserCollectionTotalDetail',
    method: 'get',
    params: data,
  });
}
//获取收藏商品列表数据
export function getItemCollectionList(data) {
  return request({
    url: '/itemCollection/getItemCollectionList',
    method: 'get',
    params: data,
  });
}
//获取商品分组列表
export function getAllGroup(data) {
  return request({
    url: '/ItemGroup/getAllGroup',
    method: 'get',
    params: data,
  });
}
//添加编辑商品分组
export function itemGroupRelationSave(data) {
  return request({
    url: '/item/itemGroupRelationSave',
    method: 'post',
    data: data,
  });
}
//删除收藏商品
export function itemDelete(data) {
  return request({
    url: '/itemCollection/itemDelete',
    method: 'post',
    data: data,
  });
}
//查看导出历史(收藏商品)
export function getItemCollectionHistoryList(data) {
  return request({
    url: '/itemCollection/getItemCollectionHistoryList',
    method: 'get',
    params: data,
  });
}
//获取商品的可售区域
export function itemSaleableArea(data) {
  return request({
    url: '/item/itemSaleableArea',
    method: 'get',
    params: data,
  });
}
//获取商品的可售区域
export function downloadItemCollectionExcel(data) {
  return request({
    url: '/itemCollection/downloadItemCollectionExcel',
    method: 'get',
    params: data,
  });
}

// 导航
export function menuList(data) {
  return request({
    url: '/Menu/getLoginAdminPower',
    method: 'get',
    params: data,
  });
}

// 获取商品物流信息(商品详情页展示用，sku维度)
export function skuLogisticsInfo(data) {
  return request({
    url: '/Logistics/skuLogisticsInfo',
    method: 'get',
    params: data,
  });
}

// 保存商品分类ccc认证和化妆品是否必填
export function saveGoodsdDsposition(data) {
  return request({
    url: '/CategoryInfo/editData',
    method: 'post',
    data: data,
  });
}

// 获取关键词排名
export function getSearchRankList(data) {
  return request({
    url: '/item/searchRankList',
    method: 'get',
    params: data,
  });
}
