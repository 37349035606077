<!-- 弹框分页 -->
<template>
  <div class="xm-pagination-d" v-if="isXmPaged">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPages"
      :page-size="currentPagesize"
      layout="prev, pager, next, jumper"
      :total="currentTotal"
      class="pagination-style"
      :pager-count="5"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentPages: this.pages,
      currentPagesize: this.pagesize,
      currentTotal: this.total,
    };
  },
  props: {
    pages: {
      type: Number,
      default: 1,
    },
    pagesize: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    total(n) {
      this.currentTotal = n;
      console.log(123);
    },
    pages(n, o) {
      if (n) {
        this.currentPages = n;
      }
    },
    pagesize(n) {
      this.currentPagesize = n;
    },
  },
  computed: {
    isXmPaged() {
      if (this.total > 0) {
        return true;
      }
      return false;
    },
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.$emit('go-pages', val);
    },
  },
};
</script>
<style lang="scss" scoped>
.xm-pagination-d {
  ::v-deep .el-pagination__editor.el-input .el-input__inner {
    height: 32px;
    border-radius: 0;
  }
  ::v-deep .el-pagination {
    padding: 0;
    height: 32px;
    button {
      padding: 0;
      min-width: 32px;
      height: 32px;
    }
    .el-pager li {
      padding: 0;
      min-width: 32px;
      height: 32px;
      line-height: 32px;
    }
    .el-pagination__jump {
      margin-left: 16px;
      .el-pagination__editor.el-input {
        margin: 0 8px;
        width: 56px;
      }
    }
  }
}
</style>
