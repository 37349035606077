<template>
  <div>
    <el-select
      :value="value"
      v-model="childValue"
      placeholder="请选择"
      @change="onChange"
    >
      <el-option
        v-for="item in optionArr"
        :key="getValue(item)"
        :label="getLabel(item)"
        :value="getValue(item)"
      >
      </el-option>
    </el-select>
    <!-- <div>
      <slot :user="options"></slot>
    </div> -->
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    // props是父组件传过来的值
    // parentValue: null,
    value: '',
    url: null,
    optionArr: {
      type: Array,
      default: () => [],
    },
    selectLabel: {
      type: String,
      default: '',
    },
    selectVAlue: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      childValue: this.value,
    };
  },
  methods: {
    getValue(obj) {
      return obj[this.selectVAlue];
    },
    getLabel(obj) {
      return obj[this.selectLabel];
    },
    onChange() {},
    clearData() {
      let flag = false;
      if (this.optionArr.lenght > 0 && this.value != '') {
        this.optionArr.forEacg((item) => {
          if (item[this.selectVAlue] == this.value) {
            flag = true;
          }
        });
      }
      return flag;
    },
  },
  mounted() {},
  watch: {
    value: {
      handler(val) {
        this.childValue = val;
      },
      immediate: true,
    },
    childValue: {
      handler(val) {
        let flag = false;
        this.optionArr.forEach((item) => {
          if (item[this.selectVAlue] == val) {
            flag = true;
          }
        });
        if (flag) {
          this.$emit('input', val); // 实现父子组件间的值传递 this.$emit(事件,值)  （这里的input：v-model是一个语法糖，等于:value+@input）
        } else {
          this.childValue = '';
          this.$emit('input', ''); // 实现父子组件间的值传递 this.$emit(事件,值)  （这里的input：v-model是一个语法糖，等于:value+@input）
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang='scss'>
</style>

