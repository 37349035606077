/**
 * chat 模块工具函数
 */

import { MsgFactory } from './msg_factory.js';

export const CUSTOMER_SERVICE = 'xmcustomerservice';

/**
 * 对传入id做正则化，一定是字符串且字母小写
 * @param {string} id
 * @return 字符串且小写的id，如果不是字符串或者id为空，返回空字符串
 */
export function getValidId(id) {
  if (!id) return '';
  if (typeof id !== 'string') id = '' + id;

  return id.toLowerCase();
}

// 对会话进行排序
// 排序依据：
// 1. 是否为置顶会话
// 2. 根据会话的 updateTime 排序
function sortSession(sessionA, sessionB) {
  if (sessionA.isTop && !sessionB.isTop) {
    return -1;
  } else if (!sessionA.isTop && sessionB.isTop) {
    return 1;
  } else {
    return sessionB.updateTime - sessionA.updateTime;
  }
}

// 对会话排序的函数
export function getOrderedSessions(sessions) {
  return Object.keys(sessions)
    .map((id) => sessions[id])
    .sort(sortSession);
}

/**
 * 搜索目标对象中符合条件的列表项
 *
 * @param {*} list 被搜索的目标列表
 * @param {*} targetKey 待搜索的键
 * @param {*} targetVal 待搜索的值
 * @returns
 */
export function fuzzySearchObject(obj, targetKey, targetVal) {
  let res = [];
  if (obj && JSON.stringify(obj) != '{}' && targetKey && targetVal) {
    let str = `\S*${targetVal}\S*`;
    let reg = new RegExp(str, 'i');
    for (const item of Object.values(obj)) {
      if (reg.test(item.nick)) {
        res.push(item);
      }
    }
  }
  return res;
}

/**
 * 生成替换的撤回消息的提示语
 * @param {*} msg 被撤回的消息
 * @param { String } 当前登录的账号
 * @param { String } 撤回通知的 idServer
 */
export function generateRecallSysMsg(msg, currAccount, idServer = '') {
  let recallNick = msg?.from === currAccount ? '你' : msg.fromNick + ' ';
  const hintMsg = {
    text: `${recallNick}撤回了一条消息`,
    originalMsg: msg,
    type: 'recallMsg', // 撤回的消息
    from: msg.from,
    time: msg.time,
    idClient: 'recallMsg-' + msg.time,
    idServer: idServer, // 新的撤回消息的 idServer 会为空
  };
  let factory = new MsgFactory(currAccount);
  return factory.createMsg(hintMsg);
}

/**
 * 获取自定义时间戳消息对象
 *
 * @param {*} time 时间戳
 * @returns
 */
export function generateTimeSysMsg(time, myId) {
  const timeMsg = {
    idClient: 'time-' + time,
    type: 'time',
    from: 'sys-custom-time',
    status: 'success',
    time: time,
  };
  let factory = new MsgFactory(myId);
  return factory.createMsg(timeMsg);
}

/**
 * 更新消息列表中的时间戳
 * @param {*} msgList
 */
export function updateMsgsTimestamps(msgList) {
  for (let i = 0; i < msgList.length; i++) {
    let msg = msgList[i];
    // 时间戳消息且当前时间戳消息需要更新
    if (msg.type === 'time' && msg.needUpdate) {
      msg.update();
    }
  }
}

/**
 * 初始化消息列表
 * @param msgs
 */
export function initMessageList(msgs, myId) {
  msgs.sort((a, b) => a.time - b.time);
  let msgArr = msgs;
  let msgList = [];
  let lastTimeMsgStamp = 0; // 上一条时间戳消息的时间
  for (let i = 0; i < msgArr?.length; i++) {
    // 两条消息超过 2 分钟，插入一条自定义时间消息msgList
    // 或者距离上一个时间戳消息 2 分钟，则插入一条自定义时间消息
    // 或者是第一条消息或最旧一条消息
    if (
      i === 0 ||
      msgArr[i].time - msgArr[i - 1].time > 2 * 60 * 1000 ||
      msgArr[i].time - lastTimeMsgStamp > 2 * 60 * 1000
    ) {
      // 插入时间文本
      let timeMsg = generateTimeSysMsg(msgArr[i].time, myId);
      msgList.push(timeMsg);
      lastTimeMsgStamp = msgArr[i].time;
    }
    //插入消息
    msgList.push(msgArr[i]);
  }
  return msgList;
}

/**
 * 插入旧的历史聊天消息
 * @param {*} list

 * @param { Boolean } insertTimeMsg 是否需要插入时间戳消息
 * @param { Boolean } checkIfRepeat 是否需要检查重复时间戳（一头一尾才可能有重复时间戳）
 */
export function insertHistoryMessage(
  msgList,
  msg,
  myId,
  insertTimeMsg = false,
  checkIfRepeat = false
) {
  if (msgList?.length > 0 && msg.time > msgList[0].time) return;
  msgList.unshift(msg);
  if (insertTimeMsg) {
    let timeMsg = generateTimeSysMsg(msg.time, myId);
    if (checkIfRepeat) {
      let lastTimeMsgIdx = msgList.findIndex((x) => x.type === 'time');
      let lastTimeLabel = msgList[lastTimeMsgIdx].data,
        currTimeLabel = timeMsg.data;

      if (lastTimeLabel === currTimeLabel) {
        // 删掉上一个消息时间戳
        msgList.splice(lastTimeMsgIdx, 1);
      }
    }
    msgList.unshift(timeMsg);
  }
}

/**
 * 插入新的聊天消息
 * @param {*} list
 * @param {*} msg
 */
export function insertNewMessage(msgList, msg, myId) {
  if (!msg) return;
  if (
    msgList?.length > 0 &&
    (msgList[msgList.length - 1].idClient === msg.idClient ||
      msgList[msgList.length - 1].time > msg.time)
  ) {
    // 插入消息去重 + 检查 msg 的时间是否新于 msgList 最新一条消息
    return;
  }
  // 检查待插入新消息与历史记录最新消息之间的时间间隔
  // 如果大于 2 分钟，则先插入一条时间戳消息
  if (
    msgList.length === 0 ||
    msg.time - msgList[msgList.length - 1].time > 2 * 60 * 1000
  ) {
    let timeMsg = generateTimeSysMsg(msg.time, myId);
    msgList.push(timeMsg);
  }
  msgList.push(msg);
}

/**
 * 用新消息对象替换掉旧消息对象
 * @param { Array } msgList
 * @param { Object } oldMsg
 * @param { Object } newMsg
 */
export function replaceOldMessage(msgList, oldMsg, newMsg) {
  if (!oldMsg || !newMsg) return;
  let targetId = oldMsg?.idClient;

  if (msgList?.length > 0) {
    let targetIndex = msgList.findIndex((x) => x.idClient === targetId);
    if (targetIndex >= 0) {
      msgList.splice(targetIndex, 1, newMsg);
    }
  }
}

// 需要保留的 NimMsg 消息字段
const validNimKeys = [
  'content',
  'from',
  'fromNick',
  'idClient',
  'idServer',
  'scene',
  'sessionId',
  'text',
  'time',
  'to',
  'type',
];
// 只保留有用的消息字段（为了避免 sendMsg 超出 custom 的长度限制）
export function filterNimMsg(msg, keepContent = true) {
  const validMsg = {};
  for (let key of validNimKeys) {
    if (key === 'content' && !keepContent) continue;
    if (msg[key]) {
      validMsg[key] = msg[key];
    }
  }
  return validMsg;
}
