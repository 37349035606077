<template>
  <div class="goods-max">
    <div
      class="goods-img"
      :class="{ pointer: pageType != '-1' }"
      @click="toGoodsDetail"
    >
      <img :src="src | imgbaseurl" alt="" @error="handleImgError" />
    </div>
    <div class="goods-box">
      <div
        class="goods-title"
        :class="{ pointer: pageType != '-1' }"
        :title="title"
        @click="toGoodsDetail"
      >
        {{ title }}
      </div>
      <div class="goods-spec">
        <div class="goods-lab">商品规格：</div>
        <div class="goods-text" :title="spc">
          {{ spc }}
        </div>
      </div>
      <div class="goods-id">
        <div class="goods-lab">商品ID：</div>
        <div class="goods-text">{{ item_id }}</div>
        <div class="iconfont copy" v-if="copyId" @click="copyText(item_id)">
          &#xe602;
        </div>
      </div>
      <div class="goods-label">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    // 标题
    title: {
      type: String,
      default: '',
    },
    // 规格
    spc: {
      type: String,
      default: '',
    },
    // 商品ID
    item_id: {
      type: String,
      default: '',
    },
    // 图片
    src: {
      type: String,
      default: '',
    },
    pageType: {
      type: String,
      default: '2',
    },
    // 是否可以复制id
    copyId: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
  methods: {
    toGoodsDetail() {
      if (this.pageType == '-1') return;
      this.toGoodsPreview(this.item_id, this.pageType);
    },
  },
};
</script>
<style lang="scss" scoped>
/* 商品信息 */
.goods-max {
  display: flex;

  .goods-lab {
    flex-shrink: 0;
  }
}
.goods-max ::v-deep .el-checkbox__input {
  top: -5px;
}
.goods-img img {
  width: 86px;
  height: 86px;
  object-fit: cover;
}
.goods-box {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // 解决商品信息不对齐问题
  height: 86px;

  .goods-title,
  .goods-spec,
  .goods-id {
    line-height: 22px;
  }

  .goods-label {
    line-height: 20px;
  }
}
.pointer {
  cursor: pointer;
}
.goods-box .goods-title {
  width: 189px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.goods-box .goods-spec {
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  display: flex;
  .goods-text {
    width: 126px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.goods-box .goods-id {
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  display: flex;
  .copy {
    margin-left: 4px;
    color: #026bff;
    font-size: 14px;
    cursor: pointer;
  }
}
.margin-top10 {
  margin-top: 10px;
}
.goods-box .goods-label {
  display: flex;
}
.goods-box .goods-label .label-style {
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 1px solid #026bff;
  padding: 0px 4px;
  box-sizing: border-box;
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #026bff;
  margin-right: 10px;
  height: 20px;
  line-height: 18px;
}
</style>
