import request from '../request';
// 获取指定洲下的国家
export function getCountries(data) {
  return request({
    url: '/Address/getCountries',
    method: 'get',
    params: data,
  });
}
// 获取指定国家下的省/洲
export function getStates(data) {
  return request({
    url: '/Address/getStates',
    method: 'get',
    params: data,
  });
}
// 获取指定省下的市
export function getCity(data) {
  return request({
    url: '/Address/getCity',
    method: 'get',
    params: data,
  });
}
// 获取洲
export function getContinents(data) {
  return request({
    url: '/Address/getContinents',
    method: 'get',
    params: data,
  });
}
// 获取区
export function getRegions(data) {
  return request({
    url: '/Address/getRegions',
    method: 'get',
    params: data,
  });
}

// 获取选中国家省市
export function getAddressList(data) {
  return request({
    url: '/Address/getAddressList',
    method: 'get',
    params: data,
  });
}
