/**
 * 自定义指令全局注册
 */

import Vue from 'vue';
import store from '@/store/index.js';
import request from '@/utils/api/request.js';

/**
 * 点击目标元素以外的其他元素，则执行绑定的函数
 *
 * 使用实例：views/chat/components/EmojiPicker.vue
 * 其父组件为 views/chat/messageWindow.vue
 */
Vue.directive('click-outside', {
  bind: function (element, binding, vnode) {
    // 绑定了 v-click-outside 的元素，当点击发生在该元素以外的任意地方时，执行绑定函数
    element.clickOutsideEvent = function (event) {
      if (!(element === event.target || element.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', element.clickOutsideEvent);
  },
  unbind: function (element) {
    document.body.removeEventListener('click', element.clickOutsideEvent);
  },
});

// 按钮权限控制
Vue.directive('permission', {
  inserted: function (el, binding) {
    const action = binding.value.action;
    if (!action) {
      //针对下拉菜单有些项并不属于权限范畴(传空即可)
      return;
    }
    const btnPerms = store.state.myBtnPerms;
    if (btnPerms?.size > 0) {
      if (!btnPerms.has(action)) {
        // 不具备权限
        const type = binding.value.type;
        if (type === 'disabled') {
          el.disabled = true;
          el.classList?.add('is-disabled');
        } else if (type === 'hide') {
          // #memo 临时方案，暂时用 css 控制按钮显隐，避免出现页面卡住的问题
          if (el?.style) {
            el.style.display = 'none';
          }
        } else {
          el.parentNode?.removeChild(el);
        }
      }
    }
  },
});

// 获取剪切板中的内容
// #todo 要节流
Vue.directive('paste-image', {
  bind: function (el, binding, vnode) {
    el.addEventListener('paste', async function (event) {
      let item = event?.clipboardData?.items?.[0];
      let pasteContent = event.clipboardData?.getData(item.type) || '';

      // 判断是否是图片（File or html 的 <img>）
      // 获取图片：1）获取 File 对象；2）获取文件路径（网络地址 or 本地磁盘地址）
      let imgUrl = '';
      if (item.type?.indexOf('image') >= 0) {
        let blob = item.getAsFile();
        let isImage = !!blob;
        if (!isImage) return;
        let formData = new FormData();
        formData.append('file', blob);
        formData.append('is_not_material', 1);

        let res = await request({
          url: '/Material/addMaterial',
          method: 'post',
          dataType: 'form-data',
          data: formData,
        });
        imgUrl = process.env.VUE_APP_STATIC_HOST + res?.data?.link || '';
      } else if (item.type?.indexOf('html') >= 0) {
        const imgReg = /<img.*?(?:>|\/>)/gi; // 匹配图片中的 <img>
        const srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i; // 匹配图片中的src
        const imgArr = pasteContent.match(imgReg) || []; // 筛选出所有的 <img>
        if (imgArr.length === 0) return;
        const imgSrcArr = [];

        for (let i = 0; i < imgArr.length; i++) {
          const imgSrc = imgArr[i].match(srcReg);
          imgSrcArr.push(imgSrc[1]);
        }
        imgUrl = imgSrcArr[0];
      }

      binding.value(imgUrl);
    });
  },
});
// 点击空白处的事件
Vue.directive('clickoutside', {
  bind(el, binding, vnode) {
    function documentHandler(e) {
      // 如果是元素本体则返回
      if (el.contains(e.target)) return false;
      if (binding.expression) {
        // 有绑定函数，则执行
        binding.value(e);
      }
    }
    el.__vueClickOutside__ = documentHandler;
    document.addEventListener('click', documentHandler);
  },
  update() {},
  unbind(el, binding) {
    document.removeEventListener('click', el.__vueClickOutside__);
    delete el.__vueClickOutside__;
  },
});
