<template>
  <div class="xm-breadcrumb">
    <el-breadcrumb separator=">" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item
        v-for="(item, index) in menus"
        :key="index"
        :to="{ path: item.url }"
        >{{ item.label }}</el-breadcrumb-item
      >
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  name: 'XmBreadcrumb',
  props: {
    // 展示的路由菜单
    // 如果需要点击跳转链接效果,就要传 url,否则不传
    menus: {
      type: Array,
      default: () => [
        // {
        //   label: '注销&退店',
        //   url: '/custom/logOff',
        // },
        // {
        //   label: '供应商退店审核',
        //   url: '',
        // },
      ],
    },
  },
};
</script>

<style lang="scss" scoped>
.xm-breadcrumb {
  margin-top: 20px;
  margin-bottom: 10px;
  ::v-deep .el-breadcrumb__item {
    span {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
    }
    // 只有路由菜单最后一项的颜色较深
    &:last-of-type {
      span {
        color: #4d4d4d;
      }
    }
    .el-breadcrumb__separator {
      margin: 0 4px;
      font-size: 12px;
    }
  }
}
</style>
