/**
 * 聊天模块
 * 消息对象类之一
 *
 * @author hukeyi
 * @description 聊天消息类 ChatMsg
 *  - TextMsg
 *  - ImageMsg
 *  - GoodsCardMsg
 *  - OrderCardMsg
 *  - QuoteMsg
 */

// 消息
export class ChatMsg {
  constructor(msg, type, data, isFromMe) {
    this.type = type;
    this.data = data;

    this.idClient = '';
    this.idServer = '';
    this.from = '';
    this.to = '';
    this.fromNick = '';
    this.status = '';
    this.time = 0;
    this.scene = '';
    this.sessionId = '';
    this.normalizeMsgAttrs(msg);

    this.isFromMe = isFromMe;

    this.nimMsg = msg; // 保存原本 NIMMessage 对象
  }
  /**
   * 统一云信 v8 和 v10 的状态码
   * @description
   * v8: 'sending' 发送中 'success' 发送成功 'fail' 发送失败
   * https://doc.yunxin.163.com/messaging/api-refer/web/typedoc/Latest/zh/NIM/interfaces/nim_MessageInterface.NIMMessage.html
   * v10: deleted read receipt refused sendFailed sending sent unread
   * https://doc.yunxin.163.com/messaging-enhanced/api-refer/web/typedoc/Latest/zh/NIM/interfaces/src_MsgServiceInterface.IMMessage.html
   * v8 success -> v10 sent
   * v8 sending -> v10 sending
   * v8 fail -> v10 sendFailed
   * @param {*} status
   * @returns v10 状态码；v8 映射到相应的 v10 状态码上
   */
  normalizeMsgStatus(status) {
    if (status === 'success') return 'sent';
    else if (status === 'fail') return 'sendFailed';
    else return status;
  }
  normalizeMsgAttrs(msg) {
    this.idClient = msg.idClient;
    this.idServer = msg.idServer;
    this.from = msg.from;
    this.to = msg.to;
    this.fromNick = msg?.fromNick ?? '';
    this.status = this.normalizeMsgStatus(msg.status); // 整合 v8 和 v10 不同的状态码
    this.time = msg.time;
    this.scene = msg.scene ?? 'p2p';
    this.sessionId = msg.sessionId;
  }
}
// 文本消息
export class TextMsg extends ChatMsg {
  constructor(msg, type, data, isFromMe) {
    super(msg, type, data, isFromMe);
    this.isRecallReEditAllowed = true; // 是否允许撤回后重新编辑
  }
}
// 图片消息
export class ImageMsg extends ChatMsg {
  constructor(msg, type, data, isFromMe) {
    super(msg, type, data, isFromMe);
    this.isRecallReEditAllowed = false;
  }
}
// 商品卡片消息
export class GoodsCardMsg extends ChatMsg {
  constructor(msg, type, data, isFromMe) {
    super(msg, type, data, isFromMe);
    this.isRecallReEditAllowed = false;
  }
}
// 订单卡片消息
export class OrderCardMsg extends ChatMsg {
  constructor(msg, type, data, isFromMe) {
    super(msg, type, data, isFromMe);
    this.isRecallReEditAllowed = false;
  }
}
// 引用消息
export class QuoteMsg extends ChatMsg {
  constructor(msg, type, data, quote, isFromMe) {
    super(msg, type, data, isFromMe);
    this.quote = quote;
    this.isRecallReEditAllowed = true;
  }
}
