<template>
  <div class="basic-box">
    <div class="top">
      <div class="left">
        <div class="title">{{ title }}</div>
        <div class="time" v-if="updateTime != ''">({{ updateTime }})</div>
      </div>

      <div class="right" v-if="moreLink != ''" @click="goUrl">
        <span>更多</span>
        <span class="iconfont">&#xe603;</span>
      </div>
    </div>

    <div class="content">
      <slot name="content-list"></slot>

      <div class="content-list" v-for="(item, index) in boxData" :key="index">
        <div class="list-title">
          {{ item.title }}<span v-if="item.isMoney">({{ currencySign }})</span>
          <el-popover
            v-if="item.tip"
            placement="bottom-start"
            trigger="hover"
            :content="item.tip"
            popper-class="myplat-popover"
          >
            <img
              slot="reference"
              src="@/assets/images/question-outline.svg"
              alt=""
            />
          </el-popover>
        </div>
        <div :class="item.num && item.num != 0 ? 'blue' : 'grey'">
          {{ item | decimal }}<span v-if="item.units">{{ item.units }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BasicBox',
  props: {
    // 当前页面币种, 1 人民币 2 美元
    pageCurrency: {
      type: String,
      default: '1',
    },
    // 盒子的标题
    title: {
      type: String,
      default: '',
    },
    // 更新时间
    updateTime: {
      type: String,
      default: '',
    },
    // 更多的跳转地址
    moreLink: {
      type: String,
      default: '',
    },
    // 展示的数据
    /**
     * title: '商标总数' (小标题)
     * tip: '状态为已审核通过的商标总数' (小标题后的tip,需要才传)
     * name: 'approvalNumber' (后端传回对应数据)
     * num: '22' (展示的数据)
     * units: '%' (单位,不需要可不写)
     * isMoney: true, (判断是不是金钱，需要币种符号/保留2位小数)
     */
    boxData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {
    // 货币符号
    currencySign() {
      switch (this.pageCurrency) {
        case '1':
          return '￥';
        case '2':
          return '$';
      }
    },
  },
  filters: {
    decimal(val) {
      // 是金额， 需要2位小数
      if (val?.isMoney) {
        if (!val.num || val.num == 0) {
          return 0;
        }
        return Number(val.num).toFixed(2);
      }
      // 不是金额，不需要处理
      if (!val?.isMoney) {
        return val.num ? val.num : 0;
      }
    },
  },
  mounted() {},
  methods: {
    // 点击跳转更多
    goUrl() {
      this.$router.push(this.moreLink);
    },
  },
};
</script>
<style lang="scss" scoped>
.basic-box {
  // width: 710px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);

  .top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;

    .left {
      display: flex;
      align-items: center;
      gap: 10px;

      .title {
        line-height: 22px;
        font-size: 16px;
        color: #111;
      }

      .time {
        line-height: 14px;
        color: #808080;
        font-size: 12px;
      }
    }

    .right {
      display: flex;
      align-items: center;
      gap: 4px;
      height: 16px;
      color: #808080;
      font-size: 12px;
      cursor: pointer;

      .iconfont {
        font-size: 14px;
      }

      &:hover {
        color: #026bff;
      }
    }
  }

  .content {
    display: flex;
    // justify-content: space-between;

    .content-list {
      line-height: 22px;
      overflow: hidden;

      .list-title {
        color: #808080;
        font-size: 12px;

        ::v-deep img {
          cursor: pointer;
        }
      }

      .blue,
      .grey {
        padding-top: 10px;
        color: #026bff;
        font-size: 16px;
        font-weight: bold;
      }

      .grey {
        color: #808080;
      }
    }
  }
}
</style>

<style>
.myplat-popover {
  margin-left: -7px;
  margin-top: 5px !important;
  padding: 6px 12px !important;
  min-width: 100px !important;
  font-size: 12px !important;
  color: #999 !important;
  line-height: 14px !important;
  border: none !important;
}

.myplat-popover .popper__arrow {
  border-bottom-color: #fff !important;
}
</style>
