<template>
  <div class="radio-model">
    <div
      class="radio-out"
      @click="checkSelect"
      :class="select ? 'active-model' : ''"
    >
      <div class="radio-inner"></div>
    </div>
    <div class="title-text" :class="select ? 'active-color' : ''">
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      select: false,
    };
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    radioCheck: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.select = this.radioCheck;
  },
  methods: {
    checkSelect() {
      this.select = !this.select;
      this.$emit('changeRadio', this.select);
    },
  },
};
</script>

<style lang="scss" scoped>
.radio-model {
  display: flex;
  justify-content: flex-start;

  .radio-out {
    width: 14px;
    height: 14px;
    border-radius: 100%;
    border: 1px solid #cccccc;
    position: relative;
    background: #ffffff;
    cursor: pointer;
    margin: auto;
    margin-right: 8px;

    .radio-inner {
      width: 5px;
      height: 5px;
      border-radius: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #ffffff;
    }
  }

  .active-model {
    background: #026bff;
  }

  .title-text {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #4d4d4d;
  }

  .active-color {
    color: #026bff;
  }
}
</style>
