<template>
  <div class="xm-upload-file-name">
    <el-upload
      class="outside-box"
      v-if="canDrag"
      :class="{ 'no-action': disabledUpload }"
      :action="curUploadApi"
      :data="uploadData"
      drag
      :disabled="disabledUpload"
      :show-file-list="false"
      :before-upload="beforeUpload"
      :on-progress="onProgress"
      :on-success="onSuccess"
      :on-error="onError"
      :accept="acceptFile"
      :headers="{ token }"
    >
      <i class="el-icon-upload"></i>
      <!-- 展示在上传窗口中的文案，可传入 html 代码 -->
      <div class="el-upload__text" v-html="uploadText"></div>
    </el-upload>
    <el-upload
      v-if="!canDrag"
      :class="{ 'no-action': disabledUpload }"
      :disabled="disabledUpload"
      :action="curUploadApi"
      :data="uploadData"
      :show-file-list="false"
      :before-upload="beforeUpload"
      :on-progress="onProgress"
      :on-success="onSuccess"
      :on-error="onError"
      :accept="acceptFile"
      :headers="{ token }"
    >
      <div class="upload-button">{{ buttonFont }}</div>
    </el-upload>

    <!-- 上传内容提示 -->
    <slot name="rule-tip"></slot>

    <!-- 上传成功的展示 -->
    <div class="already-upload-file" v-if="fileList.length !== 0">
      <div
        class="success-list flex-between"
        v-for="(item, index) in fileList"
        :key="index"
      >
        <div
          class="left-side overflow-line flex-align-center"
          @click="showPreviewimgFunc(item)"
        >
          <XmPreviewImage
            v-if="item.type == 'image'"
            width="22px"
            height="22px"
            :imgSrc="item.preview"
          ></XmPreviewImage>
          <video v-if="item.type == 'video'" :src="item.preview"></video>
          <img
            v-if="item.type !== 'image' && item.type !== 'video'"
            :src="item.type | uploadFileType"
            @error="handleImgError"
            alt=""
          />
          <div class="overflow-line" :title="item.name">
            {{ item.name }}
          </div>
        </div>

        <div class="right-side flex-align-center">
          <div class="iconfont upload-success">&#xe660;</div>
          <div
            class="del-file hover-color"
            @click="delFile(item.link)"
            v-if="isDeleteBtn"
          >
            删除
          </div>
        </div>
      </div>
    </div>
    <!-- 上传中的样式 -->
    <div
      class="progress-upload-file flex-between"
      :class="{ 'padding-top6': fileList.length == 0 }"
      v-if="onProgressTime"
    >
      <div class="left-side overflow-line flex-align-center">
        <img
          v-if="this.fileType == 'image'"
          :src="imgUrl"
          @error="handleImgError"
          alt=""
        />
        <video v-if="this.fileType == 'video'" :src="imgUrl"></video>
        <img
          v-if="this.fileType !== 'image' && this.fileType !== 'video'"
          :src="this.fileType | uploadFileType"
          @error="handleImgError"
          alt=""
        />
        <div class="overflow-line">
          {{ onProgressFileName }}
        </div>
      </div>

      <div class="right-side flex-align-center">
        <el-progress
          type="circle"
          v-if="percentage != 100"
          :stroke-width="8"
          :percentage="percentage"
          status="warning"
          :show-text="false"
        ></el-progress>
      </div>
    </div>

    <!-- 预览图片 -->
    <xm-previewimg
      :showurl="previewImgUrl"
      @closepreimg="previewCloseFunc"
      v-if="showPreviewimg"
    ></xm-previewimg>
    <!-- 预览文件 -->
    <xm-previewdoc
      :showurl="previewImgUrl"
      @closepredox="previewCloseFunc"
      v-if="showPreviewdoc"
    ></xm-previewdoc>
  </div>
</template>

<script>
// 工具函数
import SparkMD5 from 'spark-md5';
export default {
  name: 'XmUploadFileName',
  props: {
    /**
     * 是否是可以拖拽上传的上传框
     */
    canDrag: {
      type: Boolean,
      default: false,
    },
    // 上传接口
    uploadApi: {
      type: String,
      default: '/api/Material/addMaterial',
    },
    /**
     *
     */
    buttonFont: {
      type: String,
      default: '点击上传',
    },
    // 展示在上传窗口中的文案
    uploadText: {
      type: String,
      default: '将文件拖到此处，或<em>点击上传</em>',
    },
    /**
     * 限制上传的限制特定值
     */
    limitType: {
      type: String,
      default: '',
    },
    /**
     * 编辑页的回显已上传文件
     * 传字符串，逗号分隔
     * 一定是完整路径
     */
    editUploadFile: {
      type: [String],
      default: '',
    },
    /**
     * 限制上传的文件数
     */
    maxFileNumer: {
      type: Number,
      default: 1,
    },
    /**
     * 限制能上传的文件格式
     * 默认只能传png,jpeg,jpg图片
     * eg: '.png, .jpeg, .jpg, .mp4'
     */
    acceptFile: {
      type: String,
      default: '.png, .jpeg, .jpg',
    },
    /**
     * 限制可上传图片的文件数
     */
    limitUploadPicNum: {
      type: Number,
      default: 1000,
    },
    /**
     * 限制可上传视频的文件数
     */
    limitUploadVideoNum: {
      type: Number,
      default: 1000,
    },
    /**
     * 可上传的图片格式文件的最大值
     * 以M为单位的数字
     * 不传默认图片最大不可以超过5M
     */
    picMaxSize: {
      type: Number,
      default: 5,
    },
    /**
     * 可上传的视频格式文件的最大值
     * 以 M 为单位的数字
     * 不传默认视频最大不可以超过200M
     */
    videoMaxSize: {
      type: Number,
      default: 200,
    },
    /**
     * 视频是否限制上传秒数
     * 默认不限制
     */
    videoLimitSecond: {
      type: Boolean,
      default: false,
    },
    /**
     * 视频可上传的最长秒数
     * 以 s(秒) 为单位的数字
     * 不传默认视频最长不可以超过30s
     */
    videoMaxSecond: {
      type: Number,
      default: 30,
    },
    /**
     * 可上传的word格式文件的最大值
     * 以 M 为单位的数字
     * 不传默认word文件最大不可以超过5M
     */
    wordMaxSize: {
      type: Number,
      default: 5,
    },
    /**
     * 可上传的pdf格式文件的最大值
     * 以 M 为单位的数字
     * 不传默认pdf文件最大不可以超过5M
     */
    pdfMaxSize: {
      type: Number,
      default: 5,
    },
    /**
     * 可上传的excel格式文件的最大值
     * 以 M 为单位的数字
     * 不传默认excel文件最大不可以超过5M
     */
    excelMaxSize: {
      type: Number,
      default: 5,
    },
    /**
     * 可上传的ppt格式文件的最大值
     * 以 M 为单位的数字
     * 不传默认ppt文件最大不可以超过5M
     */
    pptMaxSize: {
      type: Number,
      default: 5,
    },
    /**
     * 以kb为单位的上传
     * 值的单位为kb
     * eg: 传500限制500kb
     */
    kbUpload: {
      type: Number,
      default: -1,
    },
    // apk文件回显处理
    editUploadFileApk: {
      type: String,
      default: '',
    },

    // TODO 是否使用上传新接口(临时判断)
    isUploadapi: {
      type: Boolean,
      default: false,
    },
    // 新上传接口场景字段（后端决定） 有就传，没有就不传（详情见上传中心 /upload 接口）
    uploadType: {
      type: String,
      default: '',
    },
    // 限制宽高类型 默认不限制  width-只限制宽  height-只限制高(如有需要自行拓展) all-限制宽高
    limitWidthHeightType: {
      type: String,
      default: '',
    },
    // 限制宽，要起作用 limitWidthHeightType【必传】
    limitWidth: {
      type: [String, Number],
      default: -1,
    },
    // 限制高，要起作用 limitWidthHeightType【必传】
    limitHeight: {
      type: [String, Number],
      default: -1,
    },
    // 限制px尺寸 要起作用 limitWidthHeightType【必传】
    proportion: {
      type: Array,
      default: function () {
        return [];
      },
    },
    // 是否一定按px上传,默认是 配合proportion使用 要起作用 limitWidthHeightType【必传】
    isScalePx: {
      type: Boolean,
      default: true,
    },
    // 是否禁用上传
    isDisabledUpload: {
      type: Boolean,
      default: false,
    },
    // 是否存在删除按钮
    isDeleteBtn: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      token: localStorage.getItem('token3'),
      fileList: [], // 上传的文件列表
      disabledUpload: this.isDisabledUpload, // 是否禁用
      percentage: 0, // 文件上传进度
      onProgressTime: false, // 文件上传开始
      onProgressFileName: '', // 上传中的文件名
      imgUrl: '', // 默认展示的图片/视频
      fileType: '', // 当前上传文件的类型

      // 上传参数
      uploadData: { limit_type: this.limitType },

      // 预览相关
      showPreviewimg: false, // 预览图片弹窗标识
      previewImgUrl: '', // 预览图片

      showPreviewdoc: false, // 预览文档弹窗标识

      userShowTime: 0, // 使用回显函数次数
    };
  },
  watch: {
    // 编辑时回显的数据
    editUploadFile: {
      immediate: true,
      handler(val) {
        /**
         * 如果是新的上传接口的话，那么后端会返回两个图片地址，一个是全路径的，一个是相对路径的，只要把全路径的传进来就好了
         * 相对路径的是要传给后端的
         */
        if (this.isUploadapi) {
          if (
            val !== '' &&
            (val.substring(0, 6) == 'https:' || val.substring(0, 5) == 'http:')
          ) {
            this.showEditFile2(val);
          }
        } else {
          if (
            val !== '' &&
            this.userShowTime == 0 &&
            (val.substring(0, 6) == 'https:' || val.substring(0, 5) == 'http:')
          ) {
            this.showEditFile(val);
          }
        }
      },
    },
    // apk编辑回显
    editUploadFileApk: {
      handler(newVal) {
        this.showEditFileApk(newVal);
      },
      deep: true,
    },
    // 是否禁用上传
    isDisabledUpload(n) {
      this.disabledUpload = n;
    },
  },
  filters: {
    uploadFileType(val) {
      if (val == 'excel') {
        return require('@/assets/material/Excel.svg');
      }
      if (val == 'pdf') {
        return require('@/assets/material/pdf.svg');
      }
      if (val == 'word') {
        return require('@/assets/material/word.svg');
      }
      if (val == 'zip') {
        return require('@/assets/material/zip.svg');
      }
      if (val == 'ppt') {
        return require('@/assets/material/ppt.svg');
      }
    },
  },
  computed: {
    // 当前上传地址
    curUploadApi() {
      let uploadApi = this.uploadApi;
      if (this.isUploadapi) {
        uploadApi = process.env.VUE_APP_API_HOST + '/uploadapi/upload';
      }
      return uploadApi;
    },
  },
  methods: {
    //预览图片回调
    showPreviewimgFunc(item) {
      let key = item?.link;
      // 如果采用新上传接口，预览采用 imgUrl 变量
      if (this.isUploadapi) {
        key = item?.imgUrl;
      }
      let fileType = key.split('.').pop();
      let isImage = this.matchType(fileType).toLowerCase() == 'image';
      let isVideo = this.matchType(fileType).toLowerCase() == 'video';
      if (isImage || isVideo) {
        this.showPreviewimg = true;
      } else {
        this.showPreviewdoc = true;
      }
      this.previewImgUrl = this.$options.filters.imgbaseurl(key);
      console.log(this.previewImgUrl, 'this.previewImgUrl');
    },
    //关闭预览回调
    previewCloseFunc() {
      this.showPreviewimg = this.showPreviewdoc = false;
    },
    /**
     * 上传前钩子
     * 1.过滤不可上传的文件类型 props: acceptFile
     * 2.限制图片大小 props: picMaxSize
     * 3.限制视频 props: videoMaxSize(大小) videoLimitSecond(是否限制秒数) videoMaxSecond(限制的秒数)
     * 4.(需要的话继续往后加)
     */
    async beforeUpload(file) {
      let fileNameLength = file.name.split('.').length;
      this.fileType = this.matchType(file.name.split('.')[fileNameLength - 1]);
      // 限制KB
      if (this.kbUpload != -1 && file.size > this.kbUpload * 1024) {
        this.$message.warning('上传图片大小不能超过' + this.kbUpload + 'KB');
        return Promise.reject();
      }
      // 限制最多上传的总文件数
      if (this.fileList.length >= this.maxFileNumer) {
        this.$message.warning('最多上传' + this.maxFileNumer + '个文件');
        return Promise.reject();
      }

      // 限制最多上传的图片文件数
      if (
        this.fileType == 'image' &&
        this.fileList.filter((item) => item.type === 'image').length >=
          this.limitUploadPicNum
      ) {
        this.$message.warning('最多可上传' + this.limitUploadPicNum + '张图片');
        return Promise.reject();
      }

      // 限制最多上传的视频文件数
      if (
        this.fileType == 'video' &&
        this.fileList.filter((item) => item.type === 'video').length >=
          this.limitUploadVideoNum
      ) {
        this.$message.warning(
          '最多可上传' + this.limitUploadVideoNum + '个视频'
        );
        return Promise.reject();
      }

      // 自定义限制的可上传的文件格式字符串（后缀们）
      let limitType = this.acceptFile.replace(/ /g, '').replace(/\./g, '');
      if (limitType.includes(file.name.split('.')[fileNameLength - 1])) {
      } else {
        this.$message.warning('请上传' + limitType + '格式文件');
        return Promise.reject();
      }

      // 校验图片宽高
      if (this.fileType == 'image') {
        let fig = () => {
          return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              const img = new Image();
              img.src = reader.result;
              img.onload = () => {
                // 只限制宽度
                if (
                  this.limitWidthHeightType == 'width' &&
                  this.limitWidth != -1
                ) {
                  if (Number(img.width) != this.limitWidth) {
                    this.$message.warning(
                      `请上传宽度为${this.limitWidth}px的图片`
                    );
                    resolve(false);
                  }
                } else if (
                  this.limitWidthHeightType == 'height' &&
                  this.limitHeight != -1
                ) {
                  if (Number(img.height) != this.limitHeight) {
                    this.$message.warning(
                      `请上传高度为${this.limitHeight}px的图片`
                    );
                    resolve(false);
                  }
                } else if (
                  this.limitWidthHeightType == 'all' &&
                  this.proportion?.length > 0
                ) {
                  if (!this.isScalePx) {
                    if (
                      img.width / img.height !=
                      this.proportion[0] / this.proportion[1]
                    ) {
                      this.$message.warning(
                        `请上传宽高比为${this.proportion[0]}*${this.proportion[1]}的图片`
                      );
                      resolve(false);
                    }
                  } else {
                    // 限制px
                    if (
                      !(
                        img.width == this.proportion[0] &&
                        img.height == this.proportion[1]
                      )
                    ) {
                      this.$message.warning(
                        `请上传宽为${this.proportion[0]}px,高为${this.proportion[1]}px的图`
                      );
                      resolve(false);
                    }
                  }
                }
                resolve(true);
              };
            };
          });
        };

        if (!(await fig())) {
          return Promise.reject();
        }
      }

      // 限制图片的大小
      if (
        this.fileType == 'image' &&
        file.size > this.picMaxSize * 1024 * 1024
      ) {
        this.$message.warning('上传图片大小不能超过' + this.picMaxSize + 'M');
        return Promise.reject();
      }

      // 限制视频的大小和秒数
      if (this.fileType == 'video') {
        if (file.size > this.videoMaxSize * 1024 * 1024) {
          this.$message.warning(
            '上传视频大小不能超过' + this.videoMaxSize + 'M'
          );
          return Promise.reject();
        }

        if (this.videoLimitSecond == false) return;

        let url = URL.createObjectURL(file);
        let audioElement = new Audio(url);
        let maxLengthSecond = this.videoMaxSecond;

        function dedmetadata() {
          return new Promise((resolve, reject) => {
            audioElement.onloadedmetadata = () => {
              let duration = parseInt(audioElement.duration, '视频的秒数'); //时长为秒，取整
              if (duration > maxLengthSecond) {
                resolve(true);
              } else {
                resolve(false);
              }
            };
          });
        }

        const res = await dedmetadata();
        if (res) {
          this.$message.warning('上传视频不能超过' + this.videoMaxSecond + 's');
          return Promise.reject();
        }
      }

      // 限制word文档的大小
      if (
        this.fileType == 'word' &&
        file.size > this.wordMaxSize * 1024 * 1024
      ) {
        this.$message.warning(
          '上传word文档大小不能超过' + this.wordMaxSize + 'M'
        );
        return Promise.reject();
      }

      // 限制pdf文档的大小
      if (this.fileType == 'pdf' && file.size > this.pdfMaxSize * 1024 * 1024) {
        this.$message.warning(
          '上传pdf文档大小不能超过' + this.pdfMaxSize + 'M'
        );
        return Promise.reject();
      }

      // 限制excel文档的大小
      if (
        this.fileType == 'excel' &&
        file.size > this.excelMaxSize * 1024 * 1024
      ) {
        this.$message.warning(
          '上传excel文档大小不能超过' + this.excelMaxSize + 'M'
        );
        return Promise.reject();
      }

      // 限制ppt文档的大小
      if (this.fileType == 'ppt' && file.size > this.pptMaxSize * 1024 * 1024) {
        this.$message.warning(
          '上传ppt文档大小不能超过' + this.pptMaxSize + 'M'
        );
        return Promise.reject();
      }

      if (this.isUploadapi) {
        const options = {
          totalChunks: '1', //分片总数
          chunkNumber: '1', //第几块分片
        };

        if (await this.calculateMD5(file)) {
          options.identifier = await this.calculateMD5(file);
        }
        if (this.uploadType) {
          options.type = this.uploadType;
        }
        this.uploadData = options;
      }

      // 判断是否有同名文件
      // if (this.fileList.length !== 0) {
      //   const hasSameName = this.fileList.some(item => item.name === file.name);
      //   if (hasSameName == true) {
      //     // 有同名文件
      //   } else {
      //     // 无同名文件
      //   }
      // }
    },
    /**
     * 上传中钩子
     * imgUrl 上传中需要展示的默认图片/视频
     * onProgressTime 为true文件上传开始(页面显示上传中样式的判断条件)
     * onProgressFileName 当前用户上传的文件的文件名
     * percentage 上传进度
     */
    onProgress(event, file) {
      this.disabledUpload = true;
      this.imgUrl = URL.createObjectURL(file.raw);
      // this.fileType = this.matchType(file.name.split('.')[0]);
      this.onProgressTime = true;
      this.onProgressFileName = file.name;
      this.percentage = Math.floor(event.percent) - 2;
    },
    /**
     * 上传成功钩子
     * percentage 上传进度
     * onProgressTime false不显示上传中样式
     * fileList 已上传成功的文件列表
     */
    onSuccess(res, file, fileList) {
      if (res.code == 200 || res.errcode == 0) {
        this.percentage = 100;
        this.onProgressTime = false;
        let uploadSuccessFile = {
          type: this.fileType, // 文件类型
          preview: this.imgUrl, //
          name: this.onProgressFileName, // 用户上传文件的文件名
          link: res.data.link ? res.data.link : res.data.filePath, // 后端返回的
          imgUrl: res?.data?.uri,
        };
        this.fileList.push(uploadSuccessFile);

        let linkFileList = this.fileList.map((item) => item.link);

        this.$emit('upload-file', linkFileList.toString());
        this.$emit('upload-data', JSON.parse(JSON.stringify(this.fileList)));
        this.disabledUpload = false;
      } else {
        this.onProgressTime = false;
        this.disabledUpload = false;
        fileList = fileList.pop();
        this.$message.error(res.msg);
      }
    },
    // 上传失败钩子
    onError(err, file) {
      this.$message.error(file.name + '上传失败');
      this.onProgressTime = false;
      this.disabledUpload = false;
    },
    // 删除已上传文件
    delFile(delFileLink) {
      this.fileList = this.fileList.filter((item) => item.link !== delFileLink);
      let linkFileList = this.fileList.map((item) => item.link);
      this.$emit('upload-file', linkFileList.toString());
      this.$emit('del-file');
    },
    // 回显已成功上传的文件
    showEditFile(val) {
      this.userShowTime++;
      let arr = val.split(',').map((item) => {
        const fileName = item.trim();
        const type = this.matchType(fileName.split('.').pop());
        const preview = fileName;
        const name = fileName.split('/').pop();
        const link = fileName.split('/').pop();

        // 需要回显返回完整路径
        return {
          type: type,
          preview: preview,
          name: name,
          link: link,
        };
      });
      this.fileList = arr;

      let linkFileList = arr.map((item) => item.link);
      this.$emit('upload-file', linkFileList.toString());
    },

    // 主要用于上传新接口的回显，回显的时候，不需要更新上传的数据
    showEditFile2(val) {
      let arr = val.split(',').map((item) => {
        const fileName = item.trim();
        const type = this.matchType(fileName.split('.').pop());
        const preview = fileName;
        const name = fileName.split('/').pop();

        // 需要回显返回完整路径
        return {
          type: type,
          preview: preview,
          name: name,
          link: item,
          imgUrl: item,
        };
      });
      this.fileList = arr;
    },

    showEditFileApk(val) {
      this.userShowTime++;
      let arr = val.split(',').map((item) => {
        const fileName = item.trim();
        const type = this.matchType(fileName.split('.').pop());
        const link = fileName;

        return {
          name: fileName,
          type: type,
          link: link,
        };
      });
      this.fileList = arr;

      let linkFileList = arr.map((item) => item.link);
      this.$emit('upload-file', linkFileList.toString());
    },

    // 上传前将文件进行md5编码
    // 上传前将文件进行md5编码
    calculateMD5(file) {
      return new Promise((resolve, reject) => {
        const spark = new SparkMD5.ArrayBuffer();

        const reader = new FileReader();
        // 获取ArrayBuffer数据
        reader.readAsArrayBuffer(file);
        reader.onload = (e) => {
          const buffer = e.target.result;
          spark.append(buffer);

          const md5 = spark.end();

          resolve(md5);
        };
        reader.onerror = (error) => reject(error);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.xm-upload-file-name {
  color: #666;
  font-size: 14px;

  ::v-deep .el-upload {
    text-align: left;

    .el-upload-dragger {
      border: 1px dashed #ccc;
      border-radius: 0;

      .el-upload__text {
        line-height: 22px;
        color: #999;
        letter-spacing: -0.01px;
      }
    }

    .upload-button {
      padding: 8px 31px;
      line-height: 22px;
      color: #666;
      font-size: 14px;
      border: 1px solid #ccc;
      // letter-spacing: -0.01px;
    }
  }

  .rule-tip {
    line-height: 22px;
    color: #666;
    font-size: 14px;
    letter-spacing: -0.01px;
  }

  .flex-between {
    display: flex;
    justify-content: space-between;
  }

  .flex-align-center {
    display: flex;
    align-items: center;
  }

  .overflow-line {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 22px;
  }

  .hover-color {
    cursor: pointer;

    &:hover {
      color: #026bff !important;
    }
  }

  // 上传成功文件展示
  .already-upload-file {
    padding-top: 6px;
    width: 360px;

    & > div:last-child {
      padding-bottom: 0 !important;
    }

    .success-list {
      padding-bottom: 10px;
      line-height: 22px;

      .left-side {
        letter-spacing: -0.01px;
        cursor: pointer;

        ::v-deep .xm-preview-image {
          margin-right: 4px;

          img {
            object-fit: contain;
            border: 1px solid #ccc;
          }
        }

        img,
        video {
          margin-right: 4px;
          width: 22px;
          height: 22px;
          object-fit: contain;
          border: 1px solid #ccc;
        }

        &:hover {
          color: #026bff;
        }
      }

      .right-side {
        // flex: 1;
        justify-content: flex-end;

        .upload-success {
          margin: 0 10px 0 20px;
          color: #67c23a;
          font-size: 14px;
        }

        .del-file {
          width: 2em;
          flex: 1;
        }
      }
    }
  }

  // 上传中文件展示
  .padding-top6 {
    padding-top: 6px !important;
  }
  .progress-upload-file {
    padding-top: 10px;
    width: 360px;

    .left-side {
      img,
      video {
        margin-right: 4px;
        width: 22px;
        height: 22px;
        object-fit: contain;
        border: 1px solid #ccc;
      }
    }

    .right-side {
      flex: 1;
      justify-content: flex-end;
    }

    ::v-deep .el-progress {
      margin: 0 38px 0 20px;

      .el-progress-circle {
        width: 14px !important;
        height: 14px !important;
      }
    }
  }

  ::v-deep .no-action {
    .el-upload-dragger,
    .upload-button {
      cursor: no-drop !important;
    }
  }
}
</style>
