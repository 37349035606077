// 注册全局组件
import Vue from 'vue';
import XmTitle from '../components/XmTitle.vue'; //标题
import XmSelect from '../components/XmSelect.vue'; //带搜索的选择器
import XmTip from '@/components/XmTip.vue'; //提示
import XmPagel from '@/components/XmPagel.vue'; //列表分页
import XmSearchInput from '@/components/XmSearchInput/index.vue'; // 带放大镜的输入框
import XmPaged from '@/components/XmPaged.vue'; //弹框分页
import XmTable from '@/components/XmTable.vue'; //表格
import XmDialog from '@/components/XmDialog.vue'; //带有插槽的弹窗
import XmPreviewImage from '@/components/XmPreviewImage/index.vue'; // 悬浮查看大图（用这个噢）
import XmDeleteConfig from '@/components/XmDeleteConfig.vue'; //删除确认框
import XmBetweenInput from '@/components/XmBetweenInput.vue'; //input区间输入
import XmSaleableArea from '@/components/XmSaleableArea.vue'; //可售区域
import XmImgUpload from '@/components/XmImgUpload.vue'; //上传组件(不走素材库)
import XmGoodsInfo from '@/components/XmGoodsInfo/index.vue'; //表格商品信息
import XmCardTableItem from '@/components/XmCardTableItem/index.vue'; //卡片表格行组件
import XmDatePicker from '@/components/XmDatePicker/index.vue'; //带下拉框的时间选择器，下拉框可要可不要自己控制
import XmPreviewimg from '@/components/XmPreviewimg.vue'; //预览图片
import XmPreviewdoc from '@/components/XmPreviewdoc.vue'; //预览文档
import XmPreviewMedia from '@/components/XmPreviewMedia.vue'; //上一张下一张 预览图片或视频
import XmSteps from '@/components/XmSteps/index.vue'; // 步骤条组件
import XmInput from '@/components/XmInput/index.vue'; //输入框
import XmBreadcrumb from '@/components/XmBreadcrumb/index.vue'; // 顶部路由小菜单
import XmInputInterval from '@/components/XmInputInterval/index.vue'; // input区间范围
import XmUnitInput from '@/components/XmUnitInput/index.vue'; // 带单位的输入框
import XmRuleSettings from '@/components/XmRuleSettings.vue'; //规则设置
import XmRadioCascade from '@/components/XmRadioCascade.vue'; //类目单选级联
import XmCategory from '@/components/XmCategory/index.vue'; // 类目多选
import XmMultipleChoice from '@/components/XmMultipleChoice/index.vue'; //多选下拉框
import BasicBox from '@/pages/platform/componens/basic-unit/BasicBox.vue'; // 我的平台数据面板
import XmDateMonth from '@/components/XmDateMonth/index.vue'; // 可切换日、月的日期选择器
import XmEchartsLine from '@/components/XmEchartsLine.vue'; // echarts折线图
import XmEchartsPie from '@/components/XmEchartsPie.vue'; // echarts饼图
import XmTitleDialog from '@/components/XmTitleDialog.vue'; // 自定义title的弹窗
import XmCheckArea from '@/components/XmCheckArea/index.vue'; // 地区多选
import XmNoDataSelect from '@/components/XmNoDataSelect.vue'; // 无匹配数据的下拉框
import XmSingleRadio from '@/components/XmSingleRadio.vue'; // 单个radio框
import XmSmallPreview from '@/components/XmSmallPreview.vue'; // 悬浮查看大图  (用的比较少，后续悬浮查看大图用XmPreviewImage往上翻)
import XmCardTabs from '@/components/XmCardTabs.vue'; // 方块标签页
import XmMultipleDisabledCascade from '@/components/XmMultipleDisabledCascade.vue'; // 多选类目(已选禁用)
import XmAddressMultiSelect from '@/components/XmAddressMultiSelect.vue'; // 国家复选
import XmVideo from '@/components/XmVideo.vue'; // 视频播放组件
import XmShowFileBox from '@/components/XmShowFileBox.vue'; // 文件展示组件
import XmExportButton from '@/components/XmExportButton.vue'; // 下拉选择导出按钮
import XmUploadFileName from '@/components/XmUploadFileName.vue'; // 上传文件组件
import XmSearchInterval from '@/components/XmSearchInterval.vue'; // 搜索输入范围
import XmEasyMultipleSelect from '@/components/XmEasyMultipleSelect.vue'; // 简易版下拉多选
import XmLanguageTab from '@/components/XmLanguageTab.vue'; // 语言切换tab
import XmRejectPicDialog from '@/components/XmRejectPicDialog.vue'; // 驳回弹窗带图片
import XmGoodsMark from '@/components/XmGoodsMark.vue'; // 认证、赔、正、退、批 标识
import XmPreviewDownloadImg from '@/components/XmPreviewDownloadImg.vue'; // 可以预览和下载的图片的组件。

import XmNewAreaSelect from '@/components/xm-new-area-select/XmNewAreaSelect.vue'; //新的地址单选

// 导出任务列表
import ExportHistory from '@/components/export-history/ExportHistory.vue';
const components = {
  XmTitle,
  XmSelect,
  XmTip,
  XmPagel,
  XmSearchInput,
  XmPaged,
  XmTable,
  XmDialog,
  XmPreviewImage,
  XmDeleteConfig,
  XmBetweenInput,
  XmSaleableArea,
  XmImgUpload,
  XmGoodsInfo,
  XmCardTableItem,
  XmDatePicker,
  XmPreviewimg,
  XmSteps,
  XmInput,
  XmPreviewdoc,
  XmBreadcrumb,
  XmInputInterval,
  XmUnitInput,
  XmRuleSettings,
  XmCategory,
  XmMultipleChoice,
  XmRadioCascade,
  BasicBox,
  XmDateMonth,
  XmEchartsLine,
  XmTitleDialog,
  XmEchartsPie,
  XmCheckArea,
  XmNoDataSelect,
  XmSingleRadio,
  XmSmallPreview,
  XmCardTabs,
  XmMultipleDisabledCascade,
  XmAddressMultiSelect,
  XmVideo,
  XmShowFileBox,
  XmExportButton,
  XmUploadFileName,
  XmPreviewMedia,
  XmSearchInterval,
  ExportHistory,
  XmEasyMultipleSelect,
  XmLanguageTab,
  XmRejectPicDialog,

  XmNewAreaSelect,
  XmGoodsMark,
  XmPreviewDownloadImg,
};
export function initGlobalComponents() {
  Object.keys(components).forEach((component_name) => {
    Vue.component(component_name, components[component_name]);
  });
}
