<!-- 驳回原因+附件原因弹窗 -->
<template>
  <div class="xm-reject-pic-dialog">
    <XmDialog :title="title" @close-dialog="cancel">
      <el-form :model="form" :rules="rules" ref="rejectForm">
        <el-form-item label="原因" prop="reason">
          <el-input
            type="textarea"
            resize="none"
            v-model="form.reason"
            placeholder="请输入"
            maxlength="300"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item class="last-item" label="添加附件" prop="pic">
          <XmUploadFileName
            :canDrag="true"
            :maxFileNumer="5"
            @upload-file="uploadFile"
          >
            <template slot="rule-tip">
              <div class="upload-tip">
                最多上传5张图片，支持jpg,jpeg,png格式文件，文件不超过5M
              </div></template
            >
          </XmUploadFileName>
        </el-form-item>
      </el-form>
      <div class="btns">
        <div class="btn sure" @click="sure">确定</div>
        <div class="btn" @click="cancel">取消</div>
      </div>
    </XmDialog>
  </div>
</template>

<script>
export default {
  name: 'XmRejectPicDialog',
  props: {
    // 弹窗标题
    title: {
      type: String,
      default: '驳回请求',
    },
  },
  data() {
    // 转账记录
    let reasonCheck = (rule, value, callback) => {
      if (!value?.trim()) {
        callback(new Error('请输入驳回原因'));
      }
      callback();
    };
    return {
      // 驳回原因表单
      form: {
        reason: '', // 驳回原因
        pic: '', // 驳回附件
      },
      // 表单规则
      rules: {
        reason: [
          { required: true, message: '请输入驳回原因', trigger: 'blur' },
          { validator: reasonCheck, trigger: ['blur', 'change'] },
        ],
      },
    };
  },
  methods: {
    // 添加附件
    uploadFile(file) {
      this.form.pic = JSON.parse(JSON.stringify(file));
    },
    // 点击确定按钮
    sure() {
      this.$refs.rejectForm.validate((valid) => {
        if (valid) {
          this.$emit('sure-reject', this.form);
        }
      });
    },
    // 点击取消按钮
    cancel() {
      this.$emit('close-dialog');
    },
  },
};
</script>

<style lang="scss" scoped>
.xm-reject-pic-dialog {
  ::v-deep .ximu-dialog {
    .box {
      width: 476px !important;
    }
    .box-main {
      padding-top: 10px;
      .el-form {
        margin-right: 4px;
        padding: 10px 20px;
        max-height: 370px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 6px; /* 设置滚动条的宽度 */
        }
        &::-webkit-scrollbar-track {
          background: #fff; /* 设置滚动条的背景颜色 */
          border-radius: 20px;
        }
        &::-webkit-scrollbar-thumb {
          background: #d9d9d9; /* 设置滚动条的滑块颜色 */
          border-radius: 20px;
        }

        .el-form-item {
          display: flex;
          margin-bottom: 20px;
          &.last-item {
            margin-bottom: 0;
          }
          &.is-required {
            .el-form-item__label:before {
              color: #d22316;
            }
          }
          &.is-error {
            .el-textarea__inner {
              border-color: #d22316;
            }
            .el-form-item__error {
              color: #d22316;
            }
          }
          .el-form-item__label {
            flex-shrink: 0;
            padding-right: 12px;
            width: 68px;
            line-height: 36px;
            text-align: right;
            color: #333;
            font-size: 14px;
          }
          .el-form-item__content {
            .el-textarea {
              .el-textarea__inner {
                padding: 9px 16px;
                width: 358px;
                height: 130px;
                line-height: 22px;
                font-size: 14px;
              }
              .el-input__count {
                right: 0;
                bottom: -18px;
                line-height: 20px;
                background: transparent;
              }
            }
            .xm-upload-file-name {
              .outside-box,
              .el-upload-dragger {
                width: 358px;
                height: 158px;
              }
              .upload-tip {
                padding-top: 6px;
                line-height: 18px;
                color: #808080;
                font-size: 12px;
              }
              .already-upload-file {
                width: 358px;
                .success-list {
                  padding-bottom: 6px;
                }
              }
              .progress-upload-file {
                width: 358px;
                padding-top: 6px;
              }
            }
          }
        }
      }
      .btns {
        display: flex;
        justify-content: center;
        padding: 20px 0 30px;
        .btn {
          padding: 8px 15px;
          line-height: 22px;
          color: #666;
          font-size: 14px;
          background-color: #fff;
          border: 1px solid #ccc;
          cursor: pointer;
          &.sure {
            margin-right: 20px;
            color: #fff;
            background-color: #026bff;
            border: 1px solid #026bff;
          }
        }
      }
    }
  }
}
</style>
