<template>
  <div class="xm-search-input">
    <el-input
      :value="value"
      @input="inputChange($event)"
      :placeholder="placeholder"
      clearable
      @clear="searchclick"
      @keyup.enter.native="searchclick()"
    >
      <i slot="suffix" class="iconfont search-icon" @click="searchclick()">
        &#xe689;
      </i>
    </el-input>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    placeholder: String,
  },
  components: {},
  data() {
    return {};
  },
  methods: {
    inputChange(val) {
      this.$emit('input', val);
    },
    searchclick() {
      this.$emit('click');
    },
  },
};
</script>
<style scoped>
.search-icon {
  font-size: 18px;
  padding-right: 10px;
  position: absolute;
  top: 50%;
  right: 18px;
  transform: translateY(-50%);
  cursor: pointer;
}
.searchicon {
  display: block;
  cursor: pointer;
  width: 50px;
  height: 100%;
  position: relative;
}

.searchicon > img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

::v-deep .el-input__suffix {
  right: 0;
}

::v-deep .el-input--suffix .el-input__inner {
  padding-right: 50px;
}

::v-deep .el-input__inner {
  padding: 8px 12px;
}
</style>
