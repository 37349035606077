<template>
  <div class="boxConteniner">
    <div
      v-if="isShowFiledName"
      :style="{
        color: filedNameColor,
        fontSize: filedNameFontSize,
        'margin-right': filedNameMarginRight,
      }"
    >
      {{ filedName }}
    </div>
    <div
      class="betweenInput"
      :style="{ width: widthProps, height: heightProps }"
      :ref="refName"
    >
      <div>
        <el-input
          :placeholder="firstPlaceholder"
          @focus="borderActive(true)"
          @blur="borderActive(false, 'first')"
          v-model="firstInputValue"
          @input="returnData('firstInputValue')"
        ></el-input>
      </div>
      <span>-</span>
      <div>
        <el-input
          :placeholder="lastPlaceholder"
          @focus="borderActive(true)"
          @blur="borderActive(false, 'last')"
          v-model="lastInputValue"
          @input="returnData('lastInputValue')"
        ></el-input>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    borderActive(flag, countFlag) {
      let temp = this.$refs[this.refName].classList;
      flag ? temp.add('borderAction') : temp.remove('borderAction');
      if (countFlag === 'first') {
        if (this.lastInputValue) {
          this.firstInputValue =
            Number(this.firstInputValue) <= Number(this.lastInputValue)
              ? this.firstInputValue
              : '';
        }
      } else if (countFlag === 'last') {
        if (this.firstInputValue) {
          this.lastInputValue =
            Number(this.lastInputValue) >= Number(this.firstInputValue)
              ? this.lastInputValue
              : '';
        }
      }
    },
    returnData(flag) {
      this[flag] = this[flag].replace(/[^\d]/g, '');
      if (this.setTimeoutFlag) clearTimeout(this.setTimeoutFlag);
      this.setTimeoutFlag = setTimeout(() => {
        this.$emit('getData', this.firstInputValue, this.lastInputValue);
        clearTimeout(this.setTimeoutFlag);
        this.setTimeoutFlag = '';
      }, 500);
    },
  },
  props: {
    firstPlaceholder: {
      type: String,
      default: '最低分',
    },
    lastPlaceholder: {
      type: String,
      default: '最高分',
    },
    widthProps: {
      //设置最大容器宽
      type: String,
      default: '120px',
    },
    heightProps: {
      //设置最大容器高
      type: String,
      default: '40px',
    },
    filedName: {
      //字段名称
      type: String,
      default: '',
    },
    isShowFiledName: {
      //是否显示字段名称
      type: Boolean,
      default: false,
    },
    filedNameColor: {
      //字段名称颜色
      type: String,
      default: '#4d4d4d',
    },
    filedNameFontSize: {
      //字段名称大小
      type: String,
      default: '14px',
    },
    filedNameMarginRight: {
      //字段名称右间距
      type: String,
      default: '8px',
    },
    refName: {
      //多个要设置不一样的ref
      type: String,
      default: 'refName',
    },
  },
  data() {
    return {
      firstInputValue: '',
      lastInputValue: '',
    };
  },
};
</script>

<style lang="scss" scoped>
.borderAction {
  border-color: #026bff !important;
}
.boxConteniner {
  display: flex;
  align-items: center;
}
.betweenInput {
  border: 1px solid #ccc;
  box-sizing: border-box;
  & > div {
    width: 42px;
  }
  & > span {
    color: #808080;
    margin: 0 4px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  ::v-deep .el-input__inner,
  ::v-deep .el-input__inner:focus {
    border: 0;
    padding: 0;
    height: 22px;
    text-align: center;
  }
}
</style>
