<template>
  <!-- 标题组件 -->
  <div class="xm-title" :style="{ marginTop: marginTop }">
    <div class="content">
      {{ title }}
      <el-popover placement="bottom" trigger="hover" v-if="popContent">
        <div class="popover-text">{{ popContent }}</div>
        <img slot="reference" src="@/assets/images/tip.svg" />
      </el-popover>
    </div>
    <XmLanguageTab
      v-if="needCurrency"
      :pageCurrency="pageCurrency"
      @change="changeCurrency"
    ></XmLanguageTab>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'XmTitle',
  props: {
    title: {
      type: String,
    },
    // 提示的内容
    popContent: {
      type: String,
    },
    marginTop: {
      type: String,
      default: '20px',
    },
    // 是否需要切换币种
    needCurrency: {
      type: Boolean,
      default: false,
    },
    // 当前页面展示的币种, 1 人民币 2 美元
    pageCurrency: {
      type: [Number, String],
      default: '1',
    },
  },
  methods: {
    // 切换显示币种
    changeCurrency(id) {
      this.$emit('change', id);
    },
  },
};
</script>

<style lang="scss" scoped>
.xm-title {
  // height: 42px;
  // margin: 20px 0;
  display: flex;
  margin-bottom: 20px;
  width: 1440px;
  background: #f7f7f7;
  font-size: 24px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  line-height: 22px;
  color: #111111;

  .content {
    // display: flex;
    // align-items: center;

    ::v-deep .el-popover__reference-wrapper img {
      vertical-align: 2px;
      width: 20px;
      height: 20px;
    }
  }
  ::v-deep .xm-language-tab {
    margin-left: 10px;
  }
}
</style>
