/**
 * 数字格式化
 * @param {Number} num 传进来的数字
 * @param {String} locale 语言环境
 * @param {Object} options Intl.NumberFormat KPI 相对应的配置(根据自己需要)
 * @returns 相对应的数字格式
 */
// 用法可以看  src\pages\capital\transactionRecord\components\table-list\BillTable.vue
export function digitalFormat(
  num = 0,
  locale = 'zh-CN',
  options = {
    // 选择相对应需要用到的配置
    style: 'decimal', //数字格式的样式。可以是decimal（十进制）、currency（货币）或percent（百分比）。默认值为decimal。
    currency: 'currency', //如果样式为currency，则使用的货币代码。默认值为当前用户的货币代码。
    currencyDisplay: 'code', //如果样式为currency，则货币符号的显示位置。可以是symbol（符号）、code（代码）或name（名称）。默认值为symbol。
    minimumIntegerDigits: 1, //数字的最小整数位数。默认值为 1。
    minimumFractionDigits: 0, //数字的最小小数位数。默认值为 0。
    maximumFractionDigits: 3, //数字的最大小数位数。默认值为 3。
    minimumSignificantDigits: 1, //数字的最小有效数字位数。默认值为 1。
    maximumSignificantDigits: 21, //数字的最大有效数字位数。默认值为 21。
  }
) {
  const formatter = new Intl.NumberFormat(locale, options);
  return formatter.format(num);
}
