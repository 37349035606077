<!-- 语言tab切换 -->
<template>
  <div class="xm-language-tab">
    <div
      class="currency-type"
      v-for="item in currencyList"
      :key="item.value"
      :class="{ 'chose-type': pageCurrency == item.value }"
      @click="changePageCurrency(item.value)"
    >
      {{ item.label }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'XmLanguageTab',
  props: {
    // 当前页面展示的币种, 1 人民币 2 美元
    pageCurrency: {
      type: [Number, String],
      default: '1',
    },
  },
  data() {
    return {
      // 币种
      currencyList: [
        {
          label: '人民币',
          value: '1',
        },
        {
          label: '美元',
          value: '2',
        },
      ],
    };
  },
  methods: {
    // 切换显示币种
    changePageCurrency(id) {
      if (this.pageCurrency == id) return;
      this.$emit('change', id);
    },
  },
};
</script>

<style lang="scss" scoped>
.xm-language-tab {
  display: flex;
  padding: 2px 4px;
  width: fit-content;
  height: 24px;
  font-size: 12px;
  color: #808080;
  border-radius: 12px;
  background-color: #f2f2f2;

  .currency-type {
    padding: 1px 14px;
    line-height: 18px;
    border-radius: 10px;
    cursor: pointer;
  }

  .chose-type {
    color: #fff;
    background-color: #026bff;
  }
}
</style>
