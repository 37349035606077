import { render, staticRenderFns } from "./XmShowFileBox.vue?vue&type=template&id=a2ad8f62&scoped=true"
import script from "./XmShowFileBox.vue?vue&type=script&lang=js"
export * from "./XmShowFileBox.vue?vue&type=script&lang=js"
import style0 from "./XmShowFileBox.vue?vue&type=style&index=0&id=a2ad8f62&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2ad8f62",
  null
  
)

export default component.exports