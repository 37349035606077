<template>
  <div class="context" v-if="isshow">
    <span class="tip" :class="Backwards === '-1' ? '' : 'back'">{{
      text
    }}</span>
  </div>
</template>
<script>
export default {
  name: 'Toast',
  data() {
    return {
      Backwards: '-1',
      jsq: null,
      jsq1: null,
    };
  },
  props: {
    isshow: {
      type: Boolean,
    },
    text: {
      type: String,
    },
  },
  watch: {
    isshow(val) {
      if (val === true) {
        this.jsq = setTimeout(() => {
          this.Backwards = '1';
          clearInterval(this.jsq);
          this.jsq = null;
        }, 1000);
        this.jsq1 = setTimeout(() => {
          this.isshow = false;
          this.Backwards = '-1';
          clearInterval(this.jsq1);
          this.jsq1 = null;
        }, 1500);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.context {
  position: absolute;
  top:0;
  width: 100%;
  height: 100%;
  z-index: 1888;
  display: flex;
  justify-content: center;
  align-items: center;
  .tip {
    background: rgba(0, 0, 0, 0.9);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    padding: 10px 20px;
    animation: fadeOut 0.5s linear forwards;
  }
  .back {
    animation: fadeOut1 0.5s linear forwards;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut1 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
