<template>
  <div class="xm-echarts-line">
    <!-- 折线图 -->
    <div
      v-if="!noData"
      ref="statistEcharts"
      class="echartsLine"
      :style="{ height: echartsHeight }"
    ></div>
    <!-- 暂无数据 -->
    <div v-if="noData" class="no-data">暂无数据</div>
  </div>
</template>
<script>
// import * as echarts from 'echarts';
export default {
  name: 'XmEchartsLine',
  components: {},
  props: {
    /**
     * yAxisName: y轴顶部单位(String)(必须)
     * boxNames：图例/数据所属类别(Array)(必须)
     * boxNums: 数据(Array)(必须)
     * boxTimes: x轴文字(Array)(必须)
     * boxColor：图例/数据颜色(Array)(必须)
     * legendShow：图例是否展示(Boolean，true可以不传)(可选)
     */
    chartBoxData: {
      type: Object,
      default: () => {},
    },
    // 同年悬浮是否需要展示完整年份
    sameYearShow: {
      type: Boolean,
      default: false,
    },
    // y轴步长
    yStepLength: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      echartsHeight: '500px',
      noData: false, // 有无数据
    };
  },
  watch: {
    chartBoxData: {
      handler: function (newVal) {
        if (!newVal?.boxTimes?.length) {
          this.noData = true;
          return;
        } else {
          this.noData = false;
        }
        this.$nextTick(() => {
          this.initEcharts(newVal);
        });
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    // this.initEcharts(chartBoxData);
  },
  beforeUpdate() {
    // this.initEcharts(this.chartBoxData);
  },
  methods: {
    initEcharts(data) {
      // const el = this.$refs.statistEcharts;
      // const chart = echarts.init(el);

      // 避免报错：There is a chart instance already initialized on the dom
      const el = this.$refs.statistEcharts;
      let chart = echarts.getInstanceByDom(el);
      if (chart == null) {
        chart = echarts.init(el);
      }

      let options = {
        // x轴
        xAxis: {
          type: 'category', // 坐标轴类型,类目轴，会自动从series.data中取
          data: [], // 类目数据
          // 坐标轴刻度
          axisTick: {
            alignWithLabel: true, // 刻度线跟标签对齐
          },
          // 坐标轴刻度标签相关设置
          axisLabel: {
            show: true, // 是否显示
            margin: 20, // 刻度标签与轴线之间的距离
            color: '#808080', // 颜色
            fontSize: 12, // 字体大小
            fontFamily: 'Microsoft YaHei', // 字体系列
            // 刻度标签的内容格式器，支持字符串模板和回调函数两种形式
            formatter: function (value) {
              return value.split(' ').join('\n');
            },
          },
        },
        // y轴
        yAxis: {
          type: 'value', // 数值轴，使用于连续数据
          name: '金额', // 坐标轴名称
          // 坐标轴名称的文字样式
          nameTextStyle: {
            // padding: [0, 0, 5, -55],
            fontSize: 14, // 字体大小
            fontFamily: 'Microsoft YaHei', // 字体系列
          },
          splitNumber: 4, // 坐标轴的分割段数,在类目轴中无效
          interval: 0, // 强制设置坐标轴分割间隔
          min: 0, // 坐标轴刻度最小值
          max: 0, // 坐标轴刻度最大值
          // 坐标轴刻度标签的相关设置
          axisLabel: {
            show: true,
            margin: 10, // 刻度标签与轴线之间的距离
            color: '#808080',
            fontSize: 12,
            fontFamily: 'Microsoft YaHei', // 字体系列
            formatter: function (value, index) {
              var value;
              if (Number(value) < 10000 && Number(value) >= 0) {
                value = value;
              } else {
                value = (Number(value) / 10000).toFixed(0) + '万';
              }
              return value;
            },
          },
          // 坐标轴在 grid 区域中的分隔线
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed', // 线的类型
            },
          },
        },
        // 直角坐标系内绘图网格
        grid: {
          top: '72', // grid 组件离容器上侧的距离
          right: '30', // 右
          bottom: '30', // 下
          left: '62', // 左
        },
        // 图例组件
        legend: {
          icon: 'circle', // 图例项的 icon
          data: [], // 图例的数据数组
          itemWidth: 10, // 图例标记的图形宽度
          itemHeight: 10, // 高度
          itemGap: 20, // 图例每项之间的间隔
          align: 'auto', // 图例标记和文本的对齐
          left: 0, // 图例组件离容器左侧的距离
          top: 0, // 上
          // 图例的公用文本样式
          textStyle: {
            color: '#111',
            fontSize: 12,
            fontFamily: 'Microsoft YaHei', // 字体系列
            padding: [0, 0, -2, 5], // 文字块的内边距
            // 在 rich 里面，可以自定义富文本样式
            // rich一定要有不然目前图例样式会出错
            rich: {
              // a: {
              //   verticalAlign: 'middle',
              // },
            },
          },
          // itemStyle: {}, // 图例的图形样式
          inactiveBorderWidth: 0, // 图例关闭时的描边粗细。系列不存在描边取0(感觉没生效)
          inactiveBorderColor: '#fff', // 图例关闭时的描边颜色
        },
        series: [
          {
            name: '', // 系列名称，用于tooltip的显示，legend 的图例筛选
            data: [], // 系列中的数据内容数组
            type: 'line', // 折线图
            smooth: true, // 是否平滑曲线显示,支持布尔值和数字(0-1,越小表示越接近折线段)
            // symbol: 'circle', // 标记的图形,默认是circle
            showSymbol: false, // 是否显示symbol,false只有在tooltip hover时显示
            // 折线拐点标志的样式
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 1,
              color: '#026bff', // 图例的圆点颜色
            },
            lineStyle: {
              color: '#026bff', //改变折线颜色
            },
            // large: true, //开启大数据量优化，在数据图形特别多而出现卡顿时候开启
          },
        ],
        // 这是坐标轴指示器(axisPointer)的全局公用设置
        axisPointer: {
          type: 'line',
          snap: true, // 坐标轴指示器是否自动吸附到点上。默认自动判断
          // axisPointer.type为line时有效
          lineStyle: {
            type: 'solid', // 线的类型
            color: '#d9d9d9', // 线的颜色
            cap: 'round', // 用于指定线段末端的绘制方式 round圆形
          },
        },
        // 提示框组件
        tooltip: {
          trigger: 'axis', // 触发类型 axis坐标轴触发
          className: 'tooltip', // 指定tooltip的DOM节点的CSS类
          padding: 10, // 提示框浮层内边距,单位px,接受数组分别设定[上,右,下,左]/[上下,右左]边距
          backgroundColor: 'rgba(255, 255, 255, .9)', // 提示框浮层的背景颜色
          // 提示框浮层的文本样式
          textStyle: {
            color: '#808080',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: 12,
            fontFamily: 'Microsoft YaHei', // 字体系列
            height: 16, // 文本显示高度
            lineHeight: 14,
          },
          // 提示框浮层内容格式器，支持字符串模板和回调函数两种形式
          formatter: (params) => {
            let topName = params[0].name.split(' ').join('');
            // 同年 && 月日 也需要在图例展示年份
            if (this.sameYearShow && topName?.length <= 5) {
              topName = data.boxTimes[0].substr(0, 5) + topName;
            }
            // 获取xAxis data中的数据
            let dataStr = `<div><p style="">${topName}</p></div>`;
            params.forEach((item) => {
              dataStr += `
                <div style="display: flex;align-items: center;justify-content: space-between;gap: 10px;padding-top: 10px">
                  <div style="text-align: right;display: flex;align-items: center;justify-content: space-between;gap: 10px;">
                    <div style="width: 6px;height: 6px;border-radius: 50%;background-color: ${item.color};"></div>
                    <div style="text-align: right;">${item.seriesName}：</div>
                  </div>
                  <div style="">${item.data}</div>
                </div>`;
            });
            return dataStr;
          },
        },
      };

      if (data.legendShow != undefined && data.legendShow == false) {
        options.legend.show = false;
        this.echartsHeight = '221px';
        chart.resize({ height: this.echartsHeight });
      }

      let margin = '';
      // let padding = [];
      let bottom = '';
      // 是否同年
      let labels = data.boxTimes?.slice(); // 复制
      // 只有年月
      if (labels[0]?.length <= 7) {
        this.echartsHeight = '245px';

        if (data.legendShow != undefined && data.legendShow == false) {
          options.legend.show = false;
          this.echartsHeight = '221px';
          options.grid.top = '35';
        }

        chart.resize({ height: this.echartsHeight });
        margin = '20';
        bottom = '30';
        options.xAxis.axisLabel.margin = margin;
        options.grid.bottom = bottom;
      }
      // 年月日
      if (labels[0]?.length > 7) {
        if (
          labels[0]?.substring(0, 4) == labels?.slice(-1)[0]?.substring(0, 4)
        ) {
          for (let i = 0; i < labels.length; i++) {
            labels[i] = labels[i]?.substring(5, 10);
          }
          this.echartsHeight = '245px';

          if (data.legendShow != undefined && data.legendShow == false) {
            options.legend.show = false;
            this.echartsHeight = '221px';
            options.grid.top = '35';
          }

          chart.resize({ height: this.echartsHeight });
          margin = '20';
          bottom = '30';
          options.xAxis.axisLabel.margin = margin;
          options.grid.bottom = bottom;
        } else {
          for (let i = 0; i < labels.length; i++) {
            labels[i] = labels[i].replace('-', ' - ');
          }
          this.echartsHeight = '257px';

          if (data.legendShow != undefined && data.legendShow == false) {
            options.legend.show = false;
            this.echartsHeight = '233px';
            options.grid.top = '35';
          }

          chart.resize({ height: this.echartsHeight });
          margin = '10';
          bottom = '44';
          options.xAxis.axisLabel.margin = margin;
          options.grid.bottom = bottom;
        }
      }

      options.xAxis.data = labels;
      options.yAxis.name = data.yAxisName;

      // switch (this.search.type) {
      //   // 3个字-80，多一个字加14
      //   case 1:
      //     padding = [0, 0, 5, -66];
      //     options.yAxis.nameTextStyle.padding = padding;
      //     options.yAxis.name = '支付金额';
      //     break;
      //   case 2:
      //     padding = [0, 0, 5, -52];
      //     options.yAxis.nameTextStyle.padding = padding;
      //     options.yAxis.name = '支付订单数';
      //     break;
      //   case 3:
      //     padding = [0, 0, 5, -38];
      //     options.yAxis.nameTextStyle.padding = padding;
      //     options.yAxis.name = '支付分销商数';
      //     break;
      //   case 4:
      //     padding = [0, 0, 5, -80];
      //     options.yAxis.nameTextStyle.padding = padding;
      //     options.yAxis.name = '客单价';
      //     break;
      //   case 5:
      //     padding = [0, 0, 5, -38];
      //     options.yAxis.nameTextStyle.padding = padding;
      //     options.yAxis.name = '收藏分销商数';
      //     break;
      //   default:
      //     break;
      // }

      let series = [];
      let sumValue = [];
      for (let i = 0; i < data.boxNums.length; i++) {
        series.push({
          name: data.boxNames[i],
          type: 'line',
          data: data.boxNums[i],
          smooth: true,
          symbol: 'circle',
          showSymbol: false,
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 1,
            color: data.boxColor[i],
          },
          lineStyle: {
            color: data.boxColor[i], //改变折线颜色
          },
        });
        sumValue += [...data.boxNums[i]] + ',';
      }

      options.series = series;
      options.legend.data = data.boxNames;

      //计算ehcarts y轴最大值 和 步长
      sumValue = sumValue
        ?.slice(0, sumValue.length - 1)
        ?.split(',')
        ?.map(Number);
      let maxVal = 0; //最大值
      let intVal = 0; //步长
      // 1. 遍历 dataList（图表需要展示的数据） 获取最大值
      sumValue.forEach((res) => {
        let number = parseFloat(res);
        if (number > maxVal) {
          maxVal = number;
        }
      });
      // 2. 最大值 向上取整
      maxVal = Math.ceil(maxVal);
      // 3. 最大值 能整除100，防止y轴步长出现小数
      maxVal = Math.ceil(maxVal / 100) * 100;
      // 4. 计算y轴步长
      intVal = maxVal / this.yStepLength;
      if (maxVal == 0) {
        maxVal = 1;
        intVal = 1;
      }
      options.yAxis.max = maxVal;
      options.yAxis.interval = intVal;

      chart.setOption(options);
    },
  },
};
</script>
<style lang="scss" scoped>
.xm-echarts-line {
  .no-data {
    line-height: 100px;
    text-align: center;
    color: #808080;
  }
}
</style>
