/**
 * 控制权限、菜单和路由的工具函数合集之二
 *
 * @date 2023-11-24
 * @author hukeyi
 * @description 合集主要包含三个函数：1）渲染侧边栏；2）注册路由；3）获取当前用户的按钮权限
 * 合集二包含（2）和（3）
 * （1）见 ./menu_render.js
 */

import store from '@/store/index.js';

/**
 * 根据用户权限添加用户的按钮权限
 * @param { Array } auths
 * @description 缓存用户当前按钮权限
 */
export function addBtnPerms(auths) {
  if (!Array.isArray(auths) || auths.length === 0) return;

  let btnPerms = [];
  for (let i = 0; i < auths?.length; i++) {
    let secondLayer = auths[i]?._child;
    for (let j = 0; j < secondLayer?.length; j++) {
      if (!secondLayer[j]) continue;

      let thirdLayer = secondLayer[j]._child;
      for (let k = 0; k < thirdLayer?.length; k++) {
        if (!thirdLayer[k]) continue;

        let btnLayer = thirdLayer[k]._child;
        // 这里不能过滤空值，必须保留一个空字符串 ''。否则
        // 当某个页面有按钮权限，且当前用户只有页面权限却无按钮权限时
        // 会出现默认赋予用户当前页面所有权限的情况
        // （因为如果不给空字符串，无法区分【当前用户有此页面权限】和
        // 【当前用户仅拥有查看页面权限而无页面下的按钮权限】两种情况
        btnPerms = btnPerms.concat(btnLayer?.map((x) => x.key));
      }
    }
  }
  store.dispatch('setBtnPerms', btnPerms);
}

/**
 * 根据用户权限获取用户可进入的路由集合
 * @param { Array } auths
 * @returns { Array } 用户有权限进入的 vue-router 路由对象数组
 */
export function getMyRoutes(auths) {
  if (!Array.isArray(auths) || auths.length === 0) return;

  const routes = {
    path: '/',
    component: () => import('@/pages/layout'),
    children: [],
  };
  for (let i = 0; i < auths?.length; i++) {
    let top = auths[i];
    if (!top) continue;

    const topRoute = {
      path: '/' + top.url,
      name: top.url,
      component: () =>
        require.ensure([], (require) => require(`@/pages/${top.component}`)),
      children: [],
    };
    routes.children.push(topRoute);
    const secondLayer = top?._child;
    for (let j = 0; j < secondLayer?.length; j++) {
      let second = secondLayer[j];
      if (!second) continue;

      // 特殊处理我的平台：我的平台不添加子路由数组
      if (top.url !== 'myPlatform') {
        const subRoutes = [];
        const thirdLayer = second._child;
        let topEntranceUrl = `/${top.url}`; // 当前顶级模块的入口路径
        for (let k = 0; k < thirdLayer?.length; k++) {
          let third = thirdLayer[k];
          if (!third) continue;
          if (top.url === third.pName) {
            const subRoute = {
              path: third.url,
              name: third.url,
              meta: third.meta ? JSON.parse(third.meta) : {},
              component: () =>
                require.ensure([], (require) =>
                  require(`@/pages/${third.component}`)
                ),
              pname: third.pName,
            };
            subRoutes.push(subRoute);
            if (third.is_entrance == 1 && third.url) {
              // 侧边栏入口菜单完整路径
              topEntranceUrl += `/${third.url}`;
            }
          }
        }
        // 生成要高亮的侧边栏菜单对应的 url 路径
        // （实现侧边栏菜单高亮的核心算法：）
        subRoutes.forEach((x) => {
          if (!x.meta) x.meta = {};
          x.meta.sideNavUrl = topEntranceUrl;
          topRoute.children.push(x);
        });
      }
    }
  }
  const page404 = {
    path: '*',
    name: '页面不存在',
    component: () => import('@/components/error.vue'),
  };
  return [routes, page404];
}

/**
 * 获取第一个入口页面url，作为 / 的默认页面入口
 * @param { Array } auths
 * @returns 入口页面 url
 */
export function getDefaultEntrancePage(auths) {
  if (!Array.isArray(auths) || auths.length === 0) return '/login';

  for (let i = 0; i < auths?.length; i++) {
    let top = auths[i];
    let secondLayer = top?._child;
    for (let j = 0; j < secondLayer?.length; j++) {
      if (!secondLayer[j]) continue;

      let thirdLayer = secondLayer[j]._child;
      for (let k = 0; k < thirdLayer?.length; k++) {
        if (!thirdLayer[k]) continue;
        let page = thirdLayer[k];
        if (page.is_entrance == 1) {
          return top.menu_id == 1 ? page.url : `${top.url}/${page.url}`;
        }
      }
    }
  }
  return '/login';
}
