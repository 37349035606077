<template>
  <div class="xm-card-table-item">
    <header :style="myHeaderStyle">
      <slot name="header">默认标题</slot>
    </header>

    <main>
      <el-table
        :header-row-style="myTableDefaultHeaderRowStyle"
        :header-cell-style="myTableDefaultHeaderCellStyle"
        :row-style="myTableDefaultRowStyle"
        :cell-style="myTableDefaultCellStyle"
        :data="rowData"
      >
        <el-table-column
          v-for="(format, index) in myTableFormat"
          :key="index"
          :prop="format.prop"
          :label="format.label"
          :width="format.width"
        >
          <!-- 标题行的插槽 -->
          <!-- 标题行做插槽的原因：标题上可能会有有悬浮内容的小圆问号或复制的 icon -->

          <!-- slot-scope 是从 el-table-column 拿到的值 -->
          <template slot="header" slot-scope="{ row }">
            <!-- row 传给 XmCardTableItem 的父组件 -->
            <slot
              v-if="format.isHeaderSlot"
              :row="{ row }"
              :name="getSlotName('header', format)"
            ></slot>
            <span v-else>{{ format.label }}</span>
          </template>

          <!-- 单元格的插槽 -->
          <template slot-scope="{ row }">
            <slot
              v-if="format.isCellSlot"
              :row="{ row }"
              :name="getSlotName('cell', format)"
            ></slot>
            <span v-else>{{ handleEmptyValue(row[format.prop]) }}</span>
          </template>
        </el-table-column>
      </el-table>
    </main>

    <footer :style="myFooterStyle">
      <slot name="footer"></slot>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'XmCardTableItem',
  props: {
    // 列的对象数组，包含列的列名、列宽、对应的数据字段名
    tableFormat: {
      type: Array,
      default: () => [
        // {
        //   label: '默认标签', // 列名
        //   width: '100', // 列宽
        //   prop: 'test', // 对应数据字段名
        //   headerSlotName: 'test', // 标题插槽的名字
        //   cellSlotName: 'test', // 单元格插槽的名字
        //   isHeaderSlot: false, // 标题是否是插槽
        //   isCellSlot: false, // 单元格是否是插槽
        // },
      ],
    },
    // 当前行的数据
    rowData: {
      type: Array, // 注意，务必传一个对象【数组】
      // 该组件虽然只是一行表格，但是是用一整个 el-table 模拟的卡片表格
      // 看上去只是一行但实际上是一整个表格，所以其 data 必须用数组做遍历
      default: () => [
        {
          test: 'test',
          test1: 'test1',
        },
      ],
    },
    // 标题行的样式
    headerStyle: {
      type: Object,
      default: () => {},
    },
    // 表格的标题行样式
    tableHeaderRowStyle: {
      type: Object,
      default: () => {},
    },
    // 表格的标题单元格样式
    tableHeaderCellStyle: {
      type: Object,
      default: () => {},
    },
    // 表格的内容行样式
    tableRowStyle: {
      type: Object,
      default: () => {},
    },
    // 表格的内容单元格样式
    tableCellStyle: {
      type: Object,
      default: () => {},
    },
    // 尾行的样式
    FooterStyle: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // 插槽名不能是 kebab-case： aa-bb-cc
      tableHeaderSlotSuffix: 'headerSlot',
      tableCellSlotSuffix: 'cellSlot',

      /**
       * 表格的默认样式
       * 想要改变的话，只需要传入要改变的那个属性和其值
       * 组件会自动覆盖和合并样式
       */
      //   文字的默认样式
      textDefaultStyle: {
        fontWeight: '400',
        color: '#666',
        fontSize: '14px',
      },
      // 标题行的默认样式
      headerDefaultStyle: {
        height: '40px',
        background: '#F1F2F4',
        borderRadius: '0px',
        borderBottom: '1px solid #DCE4F4',
        // paddingLeft: '6px',
      },
      // 表格标题行的默认样式
      tableDefaultHeaderRowStyle: {
        height: '40px',
      },
      // 表格标题单元格的默认样式
      tableDefaultHeaderCellStyle: {
        background: '#F5F5F5',
        boxShadow: 'inset 0px -1px 0px 0px #EBEEF5',
        // paddingLeft: '6px',
      },
      // 表格内容行的默认样式
      tableDefaultRowStyle: {
        height: '106px',
      },
      // 表格内容单元格的默认样式
      tableDefaultCellStyle: {
        width: '98px',
        background: '#FFFFFF',
        boxShadow: 'inset 0px -1px 0px 0px #EBEEF5',
        borderRadius: '0px 0px 0px 0px',
        color: '#333',
        border: 'none',
      },
      // 尾行的样式
      footerDefaultStyle: {
        // height: '40px',
        background: '#F1F2F4',
        borderRadius: '0px',
        // paddingLeft: '6px',
      },
    };
  },
  computed: {
    myTableFormat() {
      if (this.tableFormat) {
        return this.tableFormat;
      }
    },
    myHeaderStyle() {
      // Object.assign 第一个参数最好传{}，因为
      // Object.assign 会改变第一个参数对象，进而污染其他样式对象
      return Object.assign(
        {},
        this.textDefaultStyle,
        this.headerDefaultStyle,
        this.headerStyle
      );
    },
    myTableDefaultHeaderRowStyle() {
      return Object.assign(
        {},
        // this.textDefaultStyle,
        this.tableDefaultHeaderRowStyle,
        this.tableHeaderRowStyle
      );
    },
    myTableDefaultHeaderCellStyle() {
      return Object.assign(
        {},
        this.textDefaultStyle,
        this.tableDefaultHeaderCellStyle,
        this.tableHeaderCellStyle
      );
    },
    myTableDefaultRowStyle() {
      return Object.assign(
        {},
        // this.textDefaultStyle,
        this.tableDefaultRowStyle,
        this.tableRowStyle
      );
    },
    myTableDefaultCellStyle() {
      return Object.assign(
        {},
        this.textDefaultStyle,
        this.tableDefaultCellStyle,
        this.tableCellStyle
      );
    },
    myFooterStyle() {
      return Object.assign(
        {},
        this.textDefaultStyle,
        this.footerDefaultStyle,
        this.FooterStyle
      );
    },
  },
  methods: {
    /**
     * 处理空值的工具函数
     * 空值展示为 '--'
     */
    handleEmptyValue(value) {
      return value || value === 0 ? value : '--';
    },
    // 插槽的默认名称，可自定义
    getSlotName(type, item) {
      const suffix =
        type == 'header'
          ? this.tableHeaderSlotSuffix
          : this.tableCellSlotSuffix;
      const slotName =
        type == 'header' ? item.headerSlotName : item.cellSlotName;
      return slotName + suffix;
    },
  },
};
</script>

<style lang="scss" scoped>
.xm-card-table-item {
  * {
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;

    padding: 0;
    margin: 0;
  }

  main {
    // 表格行中的 checkbox 向上对齐
    ::v-deep .el-table {
      table tbody tr td:first-of-type {
        height: 14px;
        line-height: 14px;
        vertical-align: top;

        .cell {
          width: fit-content;
        }
      }
    }
  }
}
</style>
