<template>
  <div id="app">
    <router-view class="margin-auto"></router-view>
  </div>
</template>
<script>
export default {
  name: 'App',
};
</script>
<style lang="scss" scoped>
.margin-auto {
  width: 1440px;
  margin: 0 auto;
}
//设置级联选择器选中背景以及字体颜色
::v-deep .el-cascader-panel {
  .in-checked-path {
    .el-cascader-node__label {
      color: #026bff;
    }
    background: rgba(2, 107, 255, 0.1);
  }
  .el-cascader-node__label {
    color: #808080;
  }
}
// 设置级联选择器悬浮样式
::v-deep .el-cascader-panel .el-cascader-node:hover {
  background: rgba(2, 107, 255, 0.1);
}

// 设置横向滚动条样式
::v-deep .el-scrollbar-color .el-scrollbar__bar.is-horizontal {
  height: 10px;
}

::v-deep .el-scrollbar-color .el-scrollbar__thumb {
  background-color: #c1c1c1;
}

::v-deep .el-scrollbar-color .el-scrollbar__thumb:active {
  background-color: #787878;
}

::v-deep .el-scrollbar-color .el-scrollbar__thumb:hover {
  background-color: #a8a8a8;
}
</style>
