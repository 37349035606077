<template>
  <div>
    <div class="boxContainer">
      <div class="img-show-content">
        <div class="uploadImg">
          <div
            v-for="(file, index) in fileArray"
            :key="file + index"
            :class="maxNumber ? 'noInfo' : ''"
          >
            <img
              :src="getImgUrl(file)"
              v-if="sourceFileArray.some((item) => item.index === file)"
              @click="fileClickFunc(index)"
            />
            <img
              v-else
              :src="file"
              alt=""
              @click="!maxNumber ? showImgFunc(file, false) : ''"
            />
            <div v-if="maxNumber">
              <div class="view-del-icon-box">
                <i
                  v-if="showViewIcon"
                  class="iconfont view-icon"
                  @click="showImgFunc(file, false, true)"
                  >&#xe626;</i
                >
                <i class="el-icon-delete del-icon" @click="delImage(index)"></i>
              </div>
              <div class="mask"></div>
            </div>
          </div>
        </div>
        <el-upload
          :accept="file_type.join(',')"
          :disabled="isDisabled"
          class="upload-demo"
          :action="url + '/Material/addMaterial'"
          multiple
          :show-file-list="false"
          :on-success="uploadSuccess"
          :before-upload="beforeUpload"
          :on-error="uploadError"
          :on-exceed="exceedFunc"
          :on-progress="progressFunc"
          :data="{ ...paramsInfo }"
          :headers="{ token }"
          :file-list="fileList"
          :limit="numLimit"
        >
          <div class="uploadContainer">
            <div v-if="currentStatus === ''">
              <div
                class="uploadBox"
                v-if="fileArray.length < maxNumber"
                @click="TanChuangflag = true"
                slot="trigger"
              >
                <i class="iconfont add-icon">&#xe623;</i>
                <span v-if="content">{{ content }}</span>
              </div>
            </div>
            <div v-else-if="currentStatus === 'conduct'">
              <div class="uploadBox conduct">
                <span>{{ conduct }}</span>
              </div>
            </div>
            <div v-else-if="currentStatus === 'failure'">
              <div class="uploadBox failure">
                <span>{{ failure }}</span>
                <span @click="reload">失败</span>
              </div>
            </div>
          </div>
        </el-upload>
      </div>
      <div slot="tip" class="el-upload__tip" v-if="tipText">
        {{ tipText }}
      </div>
    </div>
    <xm-previewdoc
      :showurl="imgUrl"
      @closepredox="previewCloseFunc"
      v-if="showPreviewdoc"
    >
    </xm-previewdoc>
    <xm-previewimg
      :showurl="imgUrl"
      @closepreimg="previewCloseFunc"
      v-if="showPreviewimg"
    >
    </xm-previewimg>
  </div>
</template>

<script>
import XmPreviewdoc from './XmPreviewdoc.vue';
export default {
  components: { XmPreviewdoc },
  props: {
    content: {
      //上传框中提示内容
      type: String,
      default: '',
    },
    maxNumber: {
      //最大上传数量(传0标识详情专用,不能删除)
      type: Number,
      default: 3,
    },
    file_type: {
      //支持哪些文件类型
      type: Array,
      default: () => [],
    },
    propFileArray: {
      //回显专用
      type: Array,
      default: () => [],
    },
    flagType: {
      //当同一个页面多处使用时区分标识
      type: String,
      default: '',
    },
    tipText: {
      //提示信息
      type: String,
      default: '',
    },
    is_not_material: {
      type: Number,
      default: 0,
    },
    // 限制px尺寸
    proportion: {
      type: Array,
      default: function () {
        return [];
      },
    },
    // 是否一定按px上传,默认是 配合proportion使用
    isScalePx: {
      type: Boolean,
      default: true,
    },
    // 限制图片大小 不传则不限制
    picMaxSize: {
      type: Number,
      default: -1,
    },
    // 是否需要展示查看大图按钮和删除按钮，默认展示删除按钮
    showViewIcon: {
      type: Boolean,
      default: false,
    },
    /**
     * 以kb为单位的上传
     * 值的单位为kb
     * eg: 传500限制500kb
     */
    kbUpload: {
      type: Number,
      default: -1,
    },
    // 上传时添加的参数
    imgParams: {
      type: String,
      default: '',
    },
  },
  mounted() {},
  data() {
    return {
      // url: 'https://admin.ximu.cn/api', //域名
      url: '/api', //域名
      host: process.env.VUE_APP_STATIC_HOST,
      isDisabled: false, //控制能否禁用上传
      failure: '上传失败', //失败提示词
      conduct: '上传中', //上传中提示词
      token: localStorage.getItem('token3'), //获取token
      TanChuangflag: false, //控制上传阀门
      fileArray: [], //存储所有数据
      imgUrl: '', //存储大图url
      showPreviewdoc: false, //控制预览弹窗是否显示(文档)
      showPreviewimg: false, //控制预览弹窗是否显示(图片)
      currentImgType: '', //记录当前图片状态
      sourceFileArray: [], //存储文件数据
      imgType: ['jpg', 'jpeg', 'png', 'gif', 'ico', 'tif', 'tiff'], //图片类型
      fileList: [], // 副本数组进行数量限制限制
      currentStatus: '',
      fileImgMap: {
        //各种文件类型所展示的封面图
        pdf: require('@/assets/material/pdf.svg'),
        xlsx: require('@/assets/material/Excel.svg'),
        xls: require('@/assets/material/Excel.svg'),
        doc: require('@/assets/material/word.svg'),
        docx: require('@/assets/material/word.svg'),
        zip: require('@/assets/material/zip.svg'),
        rar: require('@/assets/material/zip.svg'),
        '7z': require('@/assets/material/zip.svg'),
      },
    };
  },
  computed: {
    paramsInfo() {
      let options = {
        is_not_material: this.is_not_material,
      };
      if (this.imgParams) {
        options.app_image = this.imgParams;
      }

      return options;
    },
    // 图片上传数量限制动态
    numLimit() {
      if (this.maxNumber) {
        return this.maxNumber - this.fileArray.length;
      } else {
        return null;
      }
    },
  },
  methods: {
    fileClickFunc(index) {
      if (!this.maxNumber) {
        this.showImgFunc(this.fileArray[index], true);
      }
    },
    reload() {
      this.currentStatus = '';
      setTimeout(() => {
        this.isDisabled = false;
      }, 0);
    },
    //上传失败回调
    uploadError() {
      this.isDisabled = true;
      this.currentStatus = 'failure';
    },
    //上传中回调
    progressFunc(event, file, fileList) {
      this.isDisabled = true;
      // console.log(event, file, fileList);
      this.currentStatus = 'conduct';
    },
    exceedFunc() {
      this.$message('上传超过上限!');
    },
    //上传前效验图片格式
    beforeUpload(file) {
      this.fileType = this.matchType(file.name?.split('.')[1]);
      return new Promise((resolve, reject) => {
        if (this.file_type.length) {
          // 处理文件名后缀全小写
          let falg = this.file_type.some(
            (item) => item === '.' + file.name.split('.').pop().toLowerCase()
          );
          if (!falg) {
            this.$message({
              type: 'warning',
              message: '此处不支持该类型文件上传!',
            });
            reject();
            return;
          }
        }
        // 限制KB
        if (this.kbUpload != -1 && file.size > this.kbUpload * 1024) {
          this.$message.warning('上传图片大小不能超过' + this.kbUpload + 'KB');
          return reject();
        }
        // 限制图片的大小
        if (this.picMaxSize > 0) {
          if (
            this.fileType == 'image' &&
            file.size > this.picMaxSize * 1024 * 1024
          ) {
            this.$message.warning(
              '上传图片大小不能超过' + this.picMaxSize + 'M'
            );
            reject();
            return;
          }
        }

        if (this.proportion.length > 0) {
          // 是否校验比例
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const img = new Image();
            img.src = reader.result;
            img.onload = () => {
              // 不需要限制像素,比例一致即可
              if (!this.isScalePx) {
                if (
                  img.width / img.height !=
                  this.proportion[0] / this.proportion[1]
                ) {
                  this.$message.warning(
                    `请上传宽高比为${this.proportion[0]}*${this.proportion[1]}的图片`
                  );
                  reject();
                  return;
                } else {
                  resolve();
                }
              }
              // 限制px
              if (this.isScalePx) {
                if (
                  !(
                    img.width == this.proportion[0] &&
                    img.height == this.proportion[1]
                  )
                ) {
                  this.$message.warning(
                    `请上传宽为${this.proportion[0]}px,高为${this.proportion[1]}px的图`
                  );
                  reject();
                  return;
                } else {
                  resolve();
                }
              }
            };
          };
        } else {
          resolve();
        }
      });
    },
    //详情页点击查看大图
    showImgFunc(file, noImg, checkIfFileTypeValid) {
      if (checkIfFileTypeValid) {
        let temp = this.matchType(file);
        if (temp) {
          noImg = temp !== 'image' && temp !== 'video';
        } else {
          this.$message({ type: 'warning', message: '文件/图片格式异常!' });
          return;
        }
      }
      this.imgUrl = this.$options.filters.imgbaseurl(file);
      if (noImg) {
        this.showPreviewdoc = true;
      } else {
        this.showPreviewimg = true;
      }
    },
    //关闭预览弹窗
    previewCloseFunc() {
      this.showPreviewimg = this.showPreviewdoc = false;
    },
    //删除图片
    delImage(index) {
      let flag = -1;
      this.sourceFileArray.filter((item, indexTwo) => {
        if (item.index === index) {
          flag = indexTwo;
        }
      });
      flag != -1 ? this.sourceFileArray.splice(flag, 1) : '';

      this.fileArray.splice(index, 1);
      this.fileList.splice(index, 1);

      this.$emit(
        'fileChangeEvent',
        JSON.parse(JSON.stringify(this.fileArray)),
        this.flagType
      );
    },
    //上传成功回调
    uploadSuccess(res, file) {
      this.isDisabled = false;
      this.currentStatus = '';
      if (res.code == 200) {
        this.imgCheckType(this.host + res.data?.link, false);
        // if (this.currentImgType == 'application') {
        //   this.imgCheckType(this.host + res.data.link, false);
        // }
        // 限制上传上限
        if (this.fileArray.length < this.maxNumber) {
          this.fileArray.push(this.host + res.data?.link);
          this.$emit(
            'fileChangeEvent',
            JSON.parse(JSON.stringify(this.fileArray)),
            this.flagType
          );
        }
      } else {
        this.$message({ type: 'warning', message: res.msg });
      }
    },
    //判断具体是什么文件
    imgCheckType(file) {
      //判断文件类型
      let temp = file.split('.').length;
      let flag = file.split('.')[temp - 1];
      if (flag == 'pdf') {
        this.sourceFileArray.push({
          index: file,
          type: flag,
        });
      } else if (flag == 'doc' || flag == 'docx') {
        this.sourceFileArray.push({
          index: file,
          type: flag,
        });
      } else if (flag == 'xls' || flag == 'xlsx') {
        this.sourceFileArray.push({
          index: file,
          type: flag,
        });
      } else if (flag == 'zip' || flag == 'rar' || flag == '7z') {
        this.sourceFileArray.push({
          index: file,
          type: flag,
        });
      }
    },
    getImgUrl(file) {
      return this.fileImgMap[
        this.sourceFileArray[
          this.sourceFileArray.reduce((box, item, indexTwo) => {
            item.index === file ? box.push(indexTwo) : '';
            return box;
          }, [])[0]
        ].type
      ];
    },
  },
  watch: {
    propFileArray: {
      handler(newValue) {
        this.fileArray = JSON.parse(JSON.stringify(newValue));
        console.log(this.fileArray, '------fileArray');
        this.fileArray.forEach((item, index) => {
          let length = item?.split('.')?.length;
          if (
            !this.imgType.some(
              (itemTwo) => itemTwo === item?.split('.')?.[length - 1]
            )
          ) {
            this.imgCheckType(item);
          }
        });
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.hide {
  position: absolute;
  z-index: -100;
}
/* 上传失败样式 */
.failure {
  border-color: #d22316 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.uploadContainer .failure > span {
  color: #d22316 !important;
  font-size: 14px !important;
  line-height: 22px;
}
/* 上传中样式 */
.conduct {
  background: rgba(17, 17, 17, 0.5);
  text-align: center;
  line-height: 100px;
}
.uploadContainer .conduct > span {
  color: white !important;
  font-size: 14px !important;
}
.img-show-content {
  display: flex;
}
.uploadContainer {
  position: relative;
  display: flex;
  flex-direction: column;
}
.el-upload__tip {
  line-height: 12px;
  height: 12px;
  color: #999999;
  margin-top: 4px;
  margin-bottom: 6px;
}
/* 上传盒子样式 */
.uploadBox {
  width: 100px;
  height: 100px;
  border: 1px solid #cccccc;
  /* border-radius: 4px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #808080;
}
.uploadBox > i {
  font-size: 28px;
  line-height: 28px;
}

.add-icon {
  margin-bottom: 10px;
}
.uploadBox > span {
  font-size: 14px !important;
}
/* 设置附件图片样式 */
.uploadImg {
  display: flex;
}
.uploadImg > div {
  width: 100px;
  height: 100px;
  margin-right: 6px;
  /* border-radius: 4px; */
  position: relative;
  border: 1px solid #cccccc;
}
.uploadImg > div > div {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.uploadImg > div > img {
  width: 100%;
  height: 100%;
  /* border-radius: 4px; */
  object-fit: contain;
}
.uploadImg .del-icon-box > i {
  color: white;
  font-size: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  z-index: 2;
}
.view-del-icon-box {
  display: flex;
  justify-content: center;
  color: white;
  font-size: 24px;

  .view-icon {
    font-size: 18px;
    display: none;
    z-index: 2;
    line-height: 98px;
    margin-right: 15px;
    cursor: default;
  }
  .del-icon {
    display: none;
    z-index: 2;
    line-height: 98px;
  }
}
.uploadImg > .noInfo:hover {
  border: 0;
}
.uploadImg > div:hover i,
.uploadImg > div:hover .mask {
  display: block;
}
.uploadImg .mask {
  background: rgba(17, 17, 17, 0.5);
  position: absolute;
  z-index: 1;
  width: 100px;
  height: 100px;
  top: 0;
  /* border-radius: 4px; */
  display: none;
}
</style>
