// 对于页面筛选条件的缓存
const state = {
  pageFiler: {}, //页面筛选条件存储
};
const getters = {};
const mutations = {
  // 设置页面筛选条件
  setPageFilterCriteria(state, info) {
    //TODO 这里会清除长时间没访问的pageid
    if (!info?.pageId) return;
    state.pageFiler[info.pageId] = info;
  },
  // 更新访问pageid 的开始时间
  upPageFilterStartTime(state, id) {
    if (state?.pageFiler?.[id]) {
      state.pageFiler[id].starttime = Date.now();
    }
  },
};
const actions = {};
export default {
  state,
  mutations,
  actions,
  getters,
};
