import Vue from 'vue';
import App from './App.vue';
import router from './router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './styles/element-variables.scss';
import './styles/element-ui.scss';
import store from '@/store/index.js';
import './styles/global.css';
import './utils/fonts/iconfont.css';

// import Video from 'video.js';
// import 'video.js/dist/video-js.css';
// Vue.prototype.$video = Video;
// 重新定义message
import { Message } from 'element-ui';
let messageInstance = null;
const resetMessage = (options) => {
  if (messageInstance) {
    messageInstance.close();
  }
  messageInstance = Message(options);
};
['error', 'success', 'info', 'warning'].forEach((type) => {
  resetMessage[type] = (options) => {
    if (typeof options === 'string') {
      options = {
        message: options,
      };
    }
    options.type = type;
    return resetMessage(options);
  };
});
Vue.prototype.$message = resetMessage;
// 检测版本
import axios from 'axios';
let currentVersion = localStorage.getItem('version');

// 获取当前版本号
// TODO 这里有个缺陷，就是每次发版要手动修改版本号
// 这一个方法只是为了暂时解决一直停留在老页面造成缓存错误问题的方案

// const getCurrentVersion = () => {
//   return axios.get('/version.json').then((response) => {
//     return response.data.version;
//   });
// };

// 检查是否有新版本
// const checkForUpdate = () => {
//   // 有个缺陷，就是每隔一个小时检测一次，会造成多个弹框出现的情况
//   getCurrentVersion().then((current) => {
//     // 通过异步请求获取最新版本号
//     const latestVersion = current;
//     // 比较当前版本号和最新版本号
//     if (latestVersion !== currentVersion) {
//       // 弹出确认框
//       if (confirm('有新版本可用，是否刷新页面？')) {
//         // 刷新页面
//         location.reload();
//         localStorage.setItem('version', current);
//       }
//     }
//   });
// };

// 在Vue实例创建之前检查是否有新版本
// setInterval(() => {
//   checkForUpdate();
// }, 600000);
//全局混入 mixin
import Mixins from './utils/mixin/mixin.js';
Vue.mixin(Mixins);

Vue.use(ElementUI);

// 横向滚动条
import { Plugin } from './utils/directive';
Vue.use(Plugin);

// 表头吸顶
import elTableSticky from './utils/el_table_sticky';
Vue.use(elTableSticky);
import './styles/el-table-sticky.scss';

/**
 * NIM 云信实例对象
 */
Vue.prototype.$nim = null; // 初始化为 null，用户登录后再初始化

/**
 * 监听用户动态操作的 Web Worker
 */
Vue.prototype.$dynamicDetector = null;

import { NewToast } from '@/components/XmToast/js/index.js';
Vue.use(NewToast);

/**
 * 无限滚动懒加载插件
 */
import infiniteScroll from 'vue-infinite-scroll';
Vue.use(infiniteScroll);

/**
 * 注册全局指令
 */
import '@/utils/custom_directives';

// 混入全局组件
import { initGlobalComponents } from './utils/install.js';
initGlobalComponents(); // 注册全局组件

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
