/**
 * 聊天模块
 * 消息对象类之二
 *
 * @author hukeyi
 * @description 系统消息类 SysMsg
 *  - TimeSysMsg
 *  - RecallSysMsg
 */

import { isDayXBefore, caculateChatTimeAgo } from '@/utils/time.js';

// 系统消息
export class SysMsg {
  constructor(msg, type, data) {
    this.type = type;
    this.data = data;

    this.time = msg.time;
  }
}
// 时间戳消息
export class TimeSysMsg extends SysMsg {
  constructor(msg, type, data) {
    super(msg, type, data);

    this.idClient = msg.idClient;
    this.needUpdate = true;
    this.update();
  }
  update() {
    if (this.needUpdate) {
      this.data = caculateChatTimeAgo(this.time);
      this.needUpdate = isDayXBefore(0, this.time);
    }
  }
}
// 撤回提示消息
export class RecallSysMsg extends SysMsg {
  constructor(msg, type, data, isFromMe) {
    super(msg, type, data);

    let originalMsg = msg.originalMsg;
    if (msg.type === 'tip') {
      // 通过 tip 消息发送的撤回通知
      originalMsg = JSON.parse(msg.custom || '{}')?.originalMsg;
    }
    this.idClient = msg.idClient;
    this.idServer = msg.idServer;
    this.recalledIdClient = originalMsg?.idClient;
    this.recalledIdServer = originalMsg?.idServer;
    this.originalMsg = originalMsg;
    this.sessionId = msg.sessionId;
    this.isFromMe = isFromMe;
    this.data = isFromMe
      ? '你撤回了一条消息'
      : `${msg.fromNick || originalMsg.fromNick} 撤回了一条消息`;
    if (originalMsg?.time) {
      this.time = originalMsg.time; // 撤回提示消息的时间改为被撤回消息的时间
    }
  }
}
